import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import FormikControl from '../../components/formikControl/FormikControl';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import { useAxiosGetMultiple } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import { checkIsoAndReturnYMDFormat } from '../../helpers/dateFunctions';
import { Col, Row } from 'react-bootstrap';
import { useLabelProvider } from '../../context/LabelProvider';
import { useAuthenticationState } from '../../context/Auth.context';

function MarkAsSafeForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
    updateValues,
}) {
    const { setLabels } = useLabelProvider();
    const { user } = useAuthenticationState();

    useEffect(() => {
        setLabels('MarkAsSafe');
    }, []);

    const { response, loading } = useAxiosGetMultiple({
        cityChapter: endpoints.cityChapters.get,
        batches: endpoints.batch.get,
    });
    const { cityChapter = {}, batches = [] } = response || {};
    const cityChapters = cityChapter.result || []
    const submitHandler = (values, { setSubmitting }) => {
        const body = { ...values };

        for (const key in body) {
            if (body[key] === 0) {
                body[key] = '0';
            }
        }

        if (!Array.isArray(body.BATCH_Array)) {
            body.BATCH_Array = [body.BATCH_Array];
        }

        axios
            .post(endpoint, body)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            })
            .finally(() => setSubmitting(false));
    };

    if (updateValues) {
        updateValues.Alumni_MarkAsSafe_BROADCAST_TYPE =
            updateValues.Alumni_MarkAsSafe_BROADCAST_TYPE_ID;
        updateValues.Alumni_MarkAsSafe_EventTYPE =
            updateValues.Alumni_MarkAsSafe_EventTYPE_ID;
        updateValues.Alumni_MarkAsSafe_STATUS =
            updateValues.Alumni_MarkAsSafe_STATUS_ID;

        updateValues.Alumni_MarkAsSafe_EventStartDate =
            checkIsoAndReturnYMDFormat(
                updateValues.Alumni_MarkAsSafe_EventStartDate
            );
        updateValues.Alumni_MarkAsSafe_EventEndDate =
            checkIsoAndReturnYMDFormat(
                updateValues.Alumni_MarkAsSafe_EventEndDate
            );

        if (updateValues.Alumni_MarkAsSafe_CITYCHAPTER_CODE === 'N/A') {
            updateValues.Alumni_MarkAsSafe_CITYCHAPTER_CODE = '';
        }
    }

    // const initialFormValues = updateValues || {
    //     Alumni_MarkAsSafe_EventName: '',
    //     Alumni_MarkAsSafe_EventTYPE: '',
    //     Alumni_MarkAsSafe_STATUS: '',
    //     Alumni_MarkAsSafe_BROADCAST_TYPE: '',
    //     Alumni_MarkAsSafe_BATCH_CODE: [],
    //     Alumni_MarkAsSafe_CITYCHAPTER_CODE: '',
    //     Alumni_MarkAsSafe_EventStartDate: '',
    //     Alumni_MarkAsSafe_EventEndDate: '',
    //     BATCH_Array: [],
    // };
    const initialFormValues = updateValues
        ? {
            ...updateValues,
            BATCH_Array: Array.isArray(updateValues.BATCH_Array)
                ? updateValues.BATCH_Array
                : [updateValues.BATCH_CODE],
        }
        : {
            Alumni_MarkAsSafe_EventName: '',
            Alumni_MarkAsSafe_EventTYPE: '',
            Alumni_MarkAsSafe_STATUS: '',
            Alumni_MarkAsSafe_BROADCAST_TYPE: '',
            Alumni_MarkAsSafe_BATCH_CODE: [],
            Alumni_MarkAsSafe_CITYCHAPTER_CODE: '',
            Alumni_MarkAsSafe_EventStartDate: '',
            Alumni_MarkAsSafe_EventEndDate: '',
            BATCH_Array: [],
        };
    const eventType = {
        FLOOD: 1,
        EPIDEMIC: 2,
        'POLITICAL UNREST': 3,
        OTHERS: 4,
    };
    const eventStatus = {
        CANCELLED: 0,
        ACTIVE: 1,
        EXPIRED: 2,
        RESPONDED: 3,
        CLOSED: 4,
    };
    const preSelectedOption = {};
    if (updateValues) {
        preSelectedOption.cityChapter = cityChapters?.find(
            (cityChapter) =>
                Number(cityChapter.CityChapter_ID) ===
                Number(initialFormValues.Alumni_MarkAsSafe_CITYCHAPTER_CODE)
        );
        preSelectedOption.batch = batches?.find(
            (batch) => batch.BATCH_NAME === updateValues.BATCH_NAME
        );
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Alumni_MarkAsSafe_EventName: Yup.string().required(),
                Alumni_MarkAsSafe_EventTYPE: Yup.string().required(),
                Alumni_MarkAsSafe_STATUS: Yup.string().required(),
                Alumni_MarkAsSafe_BROADCAST_TYPE: Yup.string().required(),
                Alumni_MarkAsSafe_EventStartDate: Yup.date()
                    .max(new Date(), "Can't be beyond today")
                    .required(),
                Alumni_MarkAsSafe_EventEndDate: Yup.date()
                    .min(
                        Yup.ref('Alumni_MarkAsSafe_EventStartDate'),
                        'End Date must be later than Start Date'
                    )
                    .max(new Date(), "Can't be beyond today")
                    .nullable(),
                BATCH_Array: Yup.array().when('Alumni_MarkAsSafe_BROADCAST_TYPE', {
                    is: '2',
                    then: Yup.array().min(1, 'At least one batch needed').required('At least one batch required')
                    .of(
                        Yup.string()
                            .nullable()
                            .test(
                                'is-not-null',
                                'At least one batch needed',
                                value => value !== null
                            )
                    ),
                    otherwise: Yup.array()
                }),
                Alumni_MarkAsSafe_CITYCHAPTER_CODE: Yup.string().when('Alumni_MarkAsSafe_BROADCAST_TYPE', {
                    is: '1',
                    then: Yup.string().required('City chapter required'),
                    otherwise: Yup.string().nullable()
                }),

            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    <Row>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                required
                                name='Alumni_MarkAsSafe_EventName'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='select'
                                required
                                name='Alumni_MarkAsSafe_EventTYPE'
                            >
                                <option value=''>Select Event Type</option>
                                {Object.keys(eventType).map((type) => (
                                    <option key={type} value={eventType[type]}>
                                        {type}
                                    </option>
                                ))}
                            </FormikControl>
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='select'
                                required
                                name='Alumni_MarkAsSafe_STATUS'
                            >
                                <option>Select Event Status</option>
                                {Object.keys(eventStatus).map((type) => (
                                    <option
                                        key={type}
                                        value={eventStatus[type]}
                                    >
                                        {type}
                                    </option>
                                ))}
                            </FormikControl>
                        </Col>
                        {user.Role !== 'DELEGATE' ? (
                            <Col md='4'>
                                <FormikControl
                                    control='check'
                                    type='radio'
                                    required
                                    options={[
                                        { key: 'All', value: '0' },
                                        { key: 'Batch', value: '2' },
                                        { key: 'City Chapter', value: '1' },
                                    ]}
                                    name='Alumni_MarkAsSafe_BROADCAST_TYPE'
                                    onChange={(e) => {
                                        setFieldValue(
                                            'Alumni_MarkAsSafe_BROADCAST_TYPE',
                                            e.target.value
                                        );
                                        setFieldValue('BATCH_Array', [null]);
                                        setFieldValue('Alumni_MarkAsSafe_CITYCHAPTER_CODE','');
                                    }}
                                />
                            </Col>
                        ) : (
                            <Col md='4'>
                                <FormikControl
                                    control='check'
                                    type='radio'
                                    required
                                    options={[
                                        { key: 'All', value: '0' },
                                        { key: 'City Chapter', value: '1' },
                                    ]}
                                    name='Alumni_MarkAsSafe_BROADCAST_TYPE'
                                    onChange={(e) => {
                                        setFieldValue(
                                            'Alumni_MarkAsSafe_BROADCAST_TYPE',
                                            e.target.value
                                        );
                                        setFieldValue('BATCH_Array', [null]);
                                        setFieldValue('Alumni_MarkAsSafe_CITYCHAPTER_CODE','');
                                    }}
                                />
                            </Col>
                        )}

                        {Number(values.Alumni_MarkAsSafe_BROADCAST_TYPE) ===
                            1 && (
                                <Col sm='6'>
                                    <FormikControl
                                        control='select-advanced'
                                        required
                                        options={cityChapters}
                                        defaultValue={preSelectedOption.cityChapter}
                                        optionValue='CityChapter_ID'
                                        optionLabel='CityChapter_Name'
                                        name='Alumni_MarkAsSafe_CITYCHAPTER_CODE'
                                        label='City Chapter'
                                    />
                                </Col>
                            )}


                        {Number(values.Alumni_MarkAsSafe_BROADCAST_TYPE) ===
                            2 &&
                            user.Role !== 'DELEGATE' && (
                                <Col sm='6'>
                                    <FormikControl
                                        control='select-advanced'
                                        required
                                        options={batches}
                                        defaultValue={preSelectedOption?.batch}
                                        optionValue='BATCH_CODE'
                                        optionLabel='BATCH_NAME'
                                        name='BATCH_Array'
                                        label='Batch'
                                        isMulti
                                    />
                                </Col>
                            )}


                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='date'
                                required
                                name='Alumni_MarkAsSafe_EventStartDate'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='date'
                                name='Alumni_MarkAsSafe_EventEndDate'
                            />
                        </Col>
                    </Row>
                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(MarkAsSafeForm);
