import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';

// Register components from chart.js
ChartJS.register(Title, Tooltip, Legend, ArcElement);

const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const CityChapter = ({ cchAlumniCount }) => {
    const chartData = {
        labels: cchAlumniCount.map((d) => d.CityChapter_Name),
        datasets: [
            {
                data: cchAlumniCount.map((d) => d.COUNT_ACTIVE_ALUMNI),
                backgroundColor: cchAlumniCount.map(() => getRandomColor()),
            },
        ],
    };

    return (
        <div style={{ width: '50%' }}>
            <Pie
                data={chartData}
                options={{
                    plugins: {
                        title: {
                            display: true,
                            // text: 'City Chapter Alumni Count',
                            font: {
                                size: 20,
                            },
                        },
                        legend: {
                            display: true,
                            position: 'bottom',
                        },
                    },
                }}
            />
        </div>
    );
};

export default CityChapter;
