import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import FormikControl from '../../components/formikControl/FormikControl';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import { useAuthenticationState } from '../../context/Auth.context';
import { isValidPhoneNumber } from 'react-phone-number-input';

function AnnouncementForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
    updateValues,
}) {
    const { user } = useAuthenticationState();
    const batchCode = user.DELEGATE_BATCH_CODE;
    const { response, loading } = useAxiosGet(endpoints.batch.get);

    let batches = [];
    if (response) {
        const filteredItems = response.filter(
            (item) => item.BATCH_CODE === batchCode
        );
        const courseId =
            filteredItems.length > 0 ? filteredItems[0].COURSE_ID : null;

        batches = response.filter((item) => item.COURSE_ID === courseId);
    }

    const submitHandler = (values, { setSubmitting }) => {
        values.BATCH_Array = values.BATCH_Array.map((item) => {
            return {
                Alumni_AnnouncmentReq_BATCH_CODE: item.BATCH_CODE,
            };
        });
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            })
            .finally((res) => setSubmitting(false));
    };

    const types = [
        { label: 'Blood requirement', value: '1' },
        { label: 'Death or Bereavement', value: '2' },
        { label: 'Social cause funding', value: '3' },
        { label: 'Others', value: '6' },
    ];

    const priorityData = [
        { label: 'Urgent', value: '1' },
        { label: 'Immediate', value: '2' },
        { label: 'Not Urgent', value: '3' },
    ];

    const broadcastData = [
        { label: 'All', value: '1' },
        { label: 'Batch', value: '2' },
    ];

    const initialFormValues = updateValues || {
        Alumni_AnnouncmentReq_TYPE: '',
        Alumni_AnnouncmentReq_PRIORITY: '',
        Alumni_AnnouncmentReq_ExpiryDate: '',
        Alumni_AnnouncmentReq_Location: '',
        Alumni_AnnouncmentReq_TITLE: '',
        Alumni_AnnouncmentReq_DESC: '',
        Alumni_AnnouncmentReq_AlternatePhoneNo: '',
        Alumni_AnnouncmentReq_AlternateEmail: '',
        Alumni_AnnouncmentReq_STATUS: '1',
        Alumni_AnnouncmentReq_RESPONSE_COMMENT: null,
        Alumni_AnnouncmentReq_BROADCAST_TYPE: '',
        BATCH_Array: [],
        Alumni_AnnouncmentReq_CITYCHAPTER_CODE: null,
    };

    const preSelectedOptions = {
        defaultType: types.find(
            (type) =>
                Number(type.value) ===
                Number(initialFormValues?.Alumni_AnnouncmentReq_TYPE_ID)
        ),
        defaultPriority: priorityData.find(
            (priority) =>
                Number(priority.value) ===
                Number(initialFormValues?.Alumni_AnnouncmentReq_PRIORITY_ID)
        ),
        defaultBroadcast: broadcastData.find(
            (data) =>
                Number(data.value) ===
                Number(
                    initialFormValues?.Alumni_AnnouncmentReq_BROADCAST_TYPE_ID
                )
        ),

        defaultBatch: batches.find(
            (batch) =>
                Number(initialFormValues?.Alumni_AnnouncmentReq_BATCH_CODE) ===
                Number(batch.BATCH_CODE)
        ),
    };
    if (loading) {
        return <Loader />;
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Alumni_AnnouncmentReq_TITLE: Yup.string()
                    .required()
                    .matches(/[^\d]/, 'Cannot be composed entirely of numbers'),
                Alumni_AnnouncmentReq_AlternateEmail: Yup.string()
                    .matches(
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                        'Please enter a valid email address'
                    )
                    .email()
                    .required(),
                Alumni_AnnouncmentReq_DESC: Yup.string().required(),
                Alumni_AnnouncmentReq_AlternatePhoneNo: Yup.string()
                    .required('Phone Number is required')
                    .nullable()
                    .test(
                        'PHONE_NUMBER',
                        'Enter a valid contact number',
                        (value) => {
                            return isValidPhoneNumber(value ?? '');
                        }
                    ),
                Alumni_AnnouncmentReq_Location: Yup.string()
                    .required('Location is required')
                    .matches(
                        /^[a-zA-Z\s,.'-]{2,}$/,
                        'Please enter a valid location'
                    ),
                Alumni_AnnouncmentReq_ExpiryDate: Yup.date()
                    .min(
                        new Date(new Date().setHours(0, 0, 0, 0)),
                        'Past date is not allowed'
                    )
                    .required(),
                Alumni_AnnouncmentReq_TYPE: Yup.string().required(),
                Alumni_AnnouncmentReq_PRIORITY: Yup.string().required(),
                Alumni_AnnouncmentReq_BROADCAST_TYPE: Yup.string().required(),
                BATCH_Array: Yup.array().when(
                    'Alumni_AnnouncmentReq_BROADCAST_TYPE',
                    {
                        is: '2',
                        then: () => Yup.array().min(1, 'Required'),
                        otherwise: () => Yup.array().nullable(),
                    }
                ),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    <Row>
                        <Col md='6'>
                            <FormikControl
                                required
                                label='Title'
                                name='Alumni_AnnouncmentReq_TITLE'
                            />
                        </Col>

                        <Col md='6'>
                            <FormikControl
                                required
                                label='Alternate Email'
                                name='Alumni_AnnouncmentReq_AlternateEmail'
                            />
                        </Col>
                        <FormikControl
                            required
                            control='textarea'
                            label='Description'
                            name='Alumni_AnnouncmentReq_DESC'
                        />
                        <Col md='6'>
                            <FormikControl
                                required
                                control='phone-number'
                                label='Phone'
                                name='Alumni_AnnouncmentReq_AlternatePhoneNo'
                            />
                        </Col>
                        <Col md='6'>
                            <FormikControl
                                required
                                label='Location'
                                name='Alumni_AnnouncmentReq_Location'
                            />
                        </Col>
                        <Col md='6'>
                            <FormikControl
                                control='input'
                                type='date'
                                required
                                name='Alumni_AnnouncmentReq_ExpiryDate'
                                label='Expiry Date'
                            />
                        </Col>
                        <Col md='6'>
                            <FormikControl
                                required
                                label='Type'
                                control='select-advanced'
                                options={types}
                                defaultvalue={preSelectedOptions.defaultType}
                                optionValue='value'
                                optionLabel='label'
                                name='Alumni_AnnouncmentReq_TYPE'
                            />
                        </Col>
                        <Col md='6'>
                            <FormikControl
                                required
                                label='Priority'
                                control='select-advanced'
                                options={priorityData}
                                defaultvalue={
                                    preSelectedOptions.defaultPriority
                                }
                                optionValue='value'
                                optionLabel='label'
                                name='Alumni_AnnouncmentReq_PRIORITY'
                            />
                        </Col>
                        <Col md='6'>
                            <FormikControl
                                required
                                label='Broadcast Type'
                                control='select-advanced'
                                options={broadcastData}
                                defaultvalue={
                                    preSelectedOptions.defaultBroadcast
                                }
                                onChange={(type) => {
                                    setFieldValue(
                                        'Alumni_AnnouncmentReq_BROADCAST_TYPE',
                                        type.value
                                    );
                                    if (type.value != 2)
                                        setFieldValue('BATCH_Array', []);
                                }}
                                optionValue='value'
                                optionLabel='label'
                                name='Alumni_AnnouncmentReq_BROADCAST_TYPE'
                            />
                        </Col>
                        {values.Alumni_AnnouncmentReq_BROADCAST_TYPE == 2 && (
                            <FormikControl
                                required
                                isMulti
                                label='Batch'
                                control='select-advanced'
                                options={batches}
                                optionValue='BATCH_CODE'
                                optionLabel='BATCH_NAME'
                                defaultvalue={preSelectedOptions.defaultBatch}
                                onChange={(batchObj) => {
                                    setFieldValue('BATCH_Array', batchObj);
                                }}
                                name='BATCH_Array'
                            />
                        )}
                    </Row>
                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(AnnouncementForm);
