import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import BatchHeadForm from './BatchHeadForm';
import { useAuthenticationState } from '../../context/Auth.context';

function BatchHeads() {
    const fetchUtils = useAxiosGet(endpoints.batchHeads.get);
    const { user } = useAuthenticationState();
    return (
        <>
            <CRUDTable
                endpoints={endpoints.batchHeads}
                Form={BatchHeadForm}
                {...viewProps.BatchHeads}
                {...fetchUtils}
                irremovable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}
                immutable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}
                insertable={user.Role === 'ADMIN' || user.Role === 'GCM'}
            />
        </>
    );
}

export default BatchHeads;
