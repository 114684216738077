import { ProgressBar } from 'react-bootstrap';

import BulkUploadFileIcon from '../../asset/icons/Upload.svg';
import { useEffect, useState } from 'react';
import tickIcon from '../../asset/icons/CheckboxBase.svg';

const Button = ({ click, placeHolder, style }) => {
    const buttonStyle = {
        width: '100%',
        textAlign: 'center',
        cursor: 'pointer',
        ...style,
    };

    return (
        <button style={buttonStyle} onClick={click}>
            {placeHolder}
        </button>
    );
};

const SuccessBody = ({
    closeModal,
    successResponse,
    setSelectedFile,
    selectedFile,
    uploadProgress,
    header,
    errorObjects,
    progress,
}) => {
    const [isErrors, setErrors] = useState(successResponse.isError);
    const ErrorComp = ({ id, title, description }) => {
        return (
            <div
                style={{
                    fontSize: '15px',
                    marginBottom: '20px',
                    borderBottom: '1px solid #00000017',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        fontSize: '14px',
                        color: 'red',
                        padding: '4px 6px',
                        margin: '8px',
                        background: 'rgba(231, 29, 19, 0.1)',
                        borderRadius: '5px',
                        width: '24%',
                        alignItems: 'center',
                    }}
                >
                    {id.toUpperCase()}
                </div>
                <div style={{ fontSize: '14px', width: '80%' }}>
                    {title}
                    <div
                        style={{
                            fontSize: '14px',
                            width: '100%',
                            color: '#FE453D',
                        }}
                    >
                        {description}
                    </div>
                </div>
            </div>
        );
    };
    const totalInsertedData = successResponse?.insertedData?.length;

    let totalDiscrepancy = totalInsertedData
        ? successResponse?.totalRows - totalInsertedData
        : successResponse?.totalRows;

    let totalRows = successResponse?.totalRows;

    return (
        <div style={{ fontSize: '16px' }}>
            <div
                style={{
                    fontSize: '18px',
                    fontWeight: '600',
                    paddingBottom: '10px',
                }}
            >
                {header}
            </div>

            <h2 style={{ fontSize: '13px', fontFamily: 'Inter' }}>
                File Uploaded Successfully
                {isErrors && ', Review Discrepancies'}
            </h2>
            <div
                style={{
                    border: '2px solid rgba(16, 123, 255, 1)',
                    padding: '5px',
                    borderRadius: '5px',
                    display: 'flex',
                }}
            >
                <div>
                    <img src={BulkUploadFileIcon} style={{ width: '100%' }} />
                </div>
                <div
                    style={{
                        width: '100%',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                    }}
                >
                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                        {selectedFile?.name}
                    </div>
                    <div>{byteConverter(selectedFile?.size).kb} </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ProgressBar
                            now={progress}
                            style={{
                                height: '10px',
                                width: '100%',
                                background: 'white',
                                backgroundColor: 'rgba(16, 123, 255, 1)',
                            }}
                        />
                        <span fontFamily="Inter" fontSize="14px">
                            {uploadProgress} 100%
                        </span>
                    </div>
                </div>
                <img
                    src={tickIcon}
                    style={{ paddingBottom: '12%', paddingRight: '2%' }}
                />
            </div>

            {isErrors && (
                <>
                    <hr />
                    <h1 style={{ fontSize: '15px', marginBottom: '10px' }}>
                        <b>
                            Sheet Contains Discrepancies{' '}
                            {totalDiscrepancy + '/' + totalRows}{' '}
                        </b>
                    </h1>
                    <h2
                        style={{
                            fontSize: '13px',
                            fontFamily: 'Inter',
                            marginBottom: '40px',
                        }}
                    >
                        Please review the following issues and re-upload
                        accordingly
                    </h2>
                    <div style={{ maxHeight: '150px', overflow: 'auto' }}>
                        {errorObjects?.map((errorObj) => {
                            return successResponse?.[errorObj?.errorType]?.map(
                                (values, index) => {
                                    const id =
                                        errorObj.errorArea +
                                        (values[errorObj?.errorNum] || ''); // Check if errorNum exists
                                    const title =
                                        errorObj?.errorField ||
                                        values[errorObj?.errorColumn] ||
                                        ''; // Check if errorField exists
                                    const description =
                                        errorObj?.errorType === 'invalidDate'
                                            ? values?.BATCH_START_DATE_ERROR +
                                              values?.BATCH_END_DATE_ERROR
                                            : (errorObj?.errorMsg || '') +
                                              (values[errorObj?.errorField] ||
                                                  values[
                                                      errorObj?.josonErrorMsg
                                                  ] ||
                                                  values.message ||
                                                  ''); // Check if errorMsg and PARTICIPANT_EMP_NO exist
                                    return (
                                        <ErrorComp
                                            id={id}
                                            key={index}
                                            title={title}
                                            description={description}
                                        />
                                    );
                                }
                            );
                        })}
                    </div>
                </>
            )}
            <Button
                placeHolder="Close"
                style={{
                    padding: '12px 0',
                    width: '100%',
                    border: ' 1px solid #107BFF',
                    color: '#107BFF',
                    fontSize: '16px',
                    fontWeight: '500',
                    marginTop: '10px',
                }}
                click={() => {
                    setSelectedFile(null);
                    closeModal();
                }}
            />
            <div
                style={{
                    color: '#667085',
                    fontSize: '11px',
                    lineHeight: '17.72px',
                    textAlign: 'justify',
                }}
            >
                Kindly review and rectify any errors that have arisen, and
                upload only the sections containing errors in the next upload.
            </div>
        </div>
    );
};

export default SuccessBody;

function byteConverter(bytes) {
    const kb = bytes / 1024;
    const mb = kb / 1024;
    return { kb: Math.round(kb) + 'kb', mb: mb.toFixed(2) + 'mb' };
}
