import axios from 'axios';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Loader from './Loader';
import ModalWrapper from './WithModal';
import { ReactComponent as Upload } from '../asset/icons/Upload.svg';
import withAlert from '../components/withAlert';
import InitialBody from './BulkUploadModals/UploadModal';
import NewItemConfirmModal from './BulkUploadModals/NewItemConfirmModal';
import SuccessBody from './BulkUploadModals/UploadSuccessModal';
import ValidateModal from './BulkUploadModals/ValidateDataModal';

function BulkUpload({
    filename,
    templateExtension = '.xlsx',
    afterUpload,
    errorObjects,
    onAfterAction = () => {},
    showAlert,
    urlObjects,
    isSingleApiForUpload = false,
}) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fileError, setFileError] = useState(null);
    const [modalState, setmodalState] = useState('initiated');
    const [newFields, setNewFields] = useState('');
    const [successResponse, setSuccessResponse] = useState();
    const [dataForValidation, setDataForValidation] = useState([]);
    const [progress, setProgress] = useState(0);
    const [bulkUploadId, setBulkUploadId] = useState();

    const {
        templateUrl,
        fileUpload,
        validateFile,
        initialBulkInsert,
        bulkUploadConfirm,
    } = urlObjects;

    const downloadTemplate = () => {
        setLoading(true);
        axios({
            url: templateUrl,
            method: 'GET',
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename + templateExtension);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                // toast.error(err.response.data.statusText);
                showAlert('error', err.response.data.statusText);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const upload = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('csv_file', selectedFile);
        if (
            selectedFile.type ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            axios
                .post(fileUpload, formData)
                .then((res) => {
                    setLoading(false);
                    if (isSingleApiForUpload) {
                        setSuccessResponse(res.data);
                        setmodalState('success');
                    } else {
                        setBulkUploadId(res.data.bulkUploadId);
                        setDataForValidation(res.data);
                        setmodalState('validate');
                    }
                    // showAlert('success', res.data.statusText);
                })
                .catch((error) => {
                    setLoading(false);
                    if (
                        (isSingleApiForUpload &&
                            error.response.status === 400 &&
                            error.response.data?.cityChapterErrorMessages
                                ?.length > 0) ||
                        error.response.data?.alumniErrorMessages?.length > 0
                    ) {
                        setSuccessResponse(error.response.data);
                        setmodalState('success');
                    } else {
                        showAlert(
                            'error',
                            error.response.data.statusText ||
                                'Something Went Wrong'
                        );
                    }
                });
        } else {
            setFileError(true);
            setLoading(false);
            return;
        }
    };

    const modalTitle =
        modalState === 'initiated' ? (
            <div style={{ width: '120%' }}>
                {' '}
                <div
                    style={{
                        color: '#0B0B0B',
                        fontWeight: 700,
                        fontSize: '18px',
                    }}
                >
                    Bulk Upload
                </div>
                <div
                    style={{
                        color: '#6D6D6D',
                        fontSize: '13px',
                        fontWeight: 400,
                        paddingTop: '10px',
                    }}
                >
                    Add your template here, and you can upload up to 1 document
                    max
                </div>
            </div>
        ) : modalState === 'success' ? (
            'Bulk Upload'
        ) : (
            ''
        );

    return (
        <>
            <ModalWrapper
                disableHeader={modalState !== 'initiated' && true}
                modalTitle={modalTitle}
                setFileError={setFileError}
                modalAttrs={{
                    size: 'xs',
                    className: 'p-0',
                    onExiting: () => {
                        setSelectedFile(null);
                        setFileError(false);
                        afterUpload();
                    },
                }}
                renderModalBody={(closeModal) => {
                    switch (modalState) {
                        case 'initiated':
                            return (
                                <InitialBody
                                    closeModal={() => {
                                        closeModal();
                                        afterUpload();
                                    }}
                                    setFileError={setFileError}
                                    setSelectedFile={setSelectedFile}
                                    upload={upload}
                                    downloadTemplate={downloadTemplate}
                                    fileError={fileError}
                                    selectedFile={selectedFile}
                                />
                            );
                        case 'validate':
                            return (
                                <ValidateModal
                                    closeModal={() => {
                                        closeModal();
                                        setmodalState('initiated');
                                        setSelectedFile(null);
                                    }}
                                    urlForValidate={validateFile}
                                    initialBulkInsert={initialBulkInsert}
                                    dataForValidation={dataForValidation}
                                    setSelectedFile={setSelectedFile}
                                    showAlert={showAlert}
                                    setmodalState={setmodalState}
                                    setLoading={setLoading}
                                    setNewFields={setNewFields}
                                    setSuccessResponse={setSuccessResponse}
                                    selectedFile={selectedFile}
                                    setBulkUploadId={setBulkUploadId}
                                    bulkUploadId={bulkUploadId}
                                />
                            );
                        case 'success':
                            return (
                                <SuccessBody
                                    header={modalTitle}
                                    closeModal={() => {
                                        closeModal();
                                        setmodalState('initiated');
                                        afterUpload();
                                    }}
                                    successResponse={successResponse}
                                    setSelectedFile={setSelectedFile}
                                    selectedFile={selectedFile}
                                    errorObjects={errorObjects}
                                    progress={progress}
                                />
                            );
                        default:
                            return (
                                <NewItemConfirmModal
                                    closeModal={() => {
                                        setSelectedFile(null);
                                        closeModal();
                                        setmodalState('initiated');
                                        afterUpload();
                                    }}
                                    setLoading={setLoading}
                                    setSelectedFile={setSelectedFile}
                                    setmodalState={setmodalState}
                                    newField={newFields}
                                    showAlert={showAlert}
                                    setProgress={setProgress}
                                    confirmURL={bulkUploadConfirm}
                                    bulkUploadId={bulkUploadId}
                                />
                            );
                    }
                }}
            >
                <Button
                    className="primaryBtn btnAnime ms-4 px-3 py-1"
                    title="Bulk upload"
                >
                    <Upload />
                </Button>
            </ModalWrapper>
            {loading && <Loader />}
        </>
    );
}

export default withAlert(BulkUpload);
