import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ReactComponent as Filter } from '../../asset/icons/Filter.svg';
// import { ReactComponent as CloseIcon } from '../../asset/icons/';
import Avatar from '../../asset/img/Avatar.png';
import styles from './Batch.css';

const bloodGroups = [
    'A +ve',
    'A -ve',
    'B +ve',
    'B -ve',
    'AB +ve',
    'AB -ve',
    'O +ve',
    'O -ve',
];
const types = ['student', 'faculty', 'alumni'];

const BloodDonor = ({ bloodDonor }) => {
    const [selectedBloodGroup, setSelectedBloodGroup] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [filterType, setFilterType] = useState(null);

    const handleBloodGroupChange = (event) => {
        setSelectedType(null);
        setSelectedBloodGroup(event.target.value);
    };

    const handleTypeChange = (event) => {
        setSelectedBloodGroup(null);
        setSelectedType(event.target.value);
    };

    const handleFilterButtonClick = () => {
        if (showFilterOptions) {
            setSelectedBloodGroup(null);
            setSelectedType(null);
            setFilterType(null);
        }
        setShowFilterOptions(!showFilterOptions);
    };
    // const filteredPeople = bloodDonor.filter((person) => {
    //     return (
    //         (!selectedBloodGroup ||
    //             person.bloodGroups === selectedBloodGroup) &&
    //         (!selectedType || person.type === selectedType)
    //     );
    // });
    const userTypes = {
        alumni: 1,
        student: 2,
        faculty: 3

    }

    const filteredPeople = bloodDonor.filter((person) => {
        return (
            selectedBloodGroup ?
                person.bloodGroups === selectedBloodGroup :
                selectedType ? person.user_type === userTypes[selectedType] : person
        );
    });
    const wrapperStyle = {
        borderRadius: '12px',
        overflow: 'hidden',
        border: '1px solid #ddd',
    };

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };

    const thTdStyle = {
        border: '1px solid #ddd',
        padding: '8px',
    };

    const thStyle = {
        ...thTdStyle,
        backgroundColor: '#f2f2f2',
    };

    const appStyle = {
        textAlign: 'center',
        marginTop: '20px',
    };

    const tbodyWrapperStyle = {
        maxHeight: '200px',
        overflowY: 'scroll',
        display: 'block',
        width: '100%',
    };

    const tableHeaderStyle = {
        display: 'table',
        width: '100%',
        tableLayout: 'fixed',
    };

    const tableBodyStyle = {
        display: 'table',
        width: '100%',
        tableLayout: 'fixed',
    };

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '20px',
                    position: 'relative',
                }}
            >
                <Button
                    onClick={handleFilterButtonClick}
                    style={{ color: 'white', border: 'none' }}
                >
                    Filter {showFilterOptions ? 'X' : <Filter />}
                </Button>
                {showFilterOptions && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '70%',
                            left: '0',
                            color: 'white',
                            zIndex: 10,
                            display: 'flex',
                            fontSize: '12px',
                            fontWeight: '400',
                        }}
                    >
                        <div
                            style={{
                                marginRight: '20px',
                            }}
                        >
                            <Form
                                style={{
                                    background: '#467AFF',
                                    padding: '10px',
                                    borderRadius: '12px',
                                }}
                            >
                                <Form.Check
                                    type='radio'
                                    label='Blood Group'
                                    checked={filterType === 'bloodGroup'}
                                    onChange={() => setFilterType('bloodGroup')}
                                />
                                <Form.Check
                                    type='radio'
                                    label='User Type'
                                    checked={filterType === 'type'}
                                    onChange={() => setFilterType('type')}
                                />
                            </Form>
                        </div>
                        {filterType && (
                            <div
                                style={{
                                    marginRight: '20px',
                                    background: '#467AFF',
                                    padding: '15px',
                                    borderRadius: '12px',
                                }}
                            >
                                {filterType === 'bloodGroup' && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {bloodGroups.map((group, index) => (
                                            <Form.Check
                                                key={index}
                                                type='radio'
                                                label={group}
                                                name='bloodGroup'
                                                value={group}
                                                checked={
                                                    selectedBloodGroup === group
                                                }
                                                onChange={
                                                    handleBloodGroupChange
                                                }
                                            />
                                        ))}
                                    </div>
                                )}
                                {filterType === 'type' && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginLeft: '3%',
                                        }}
                                    >
                                        {types.map((type, index) => (
                                            <Form.Check
                                                key={index}
                                                type='radio'
                                                label={
                                                    type
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                    type.slice(1)
                                                }
                                                name='type'
                                                value={type}
                                                checked={selectedType === type}
                                                onChange={handleTypeChange}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div
                style={{
                    borderRadius: '12px',
                    overflow: 'hidden',
                }}
            >
                {filteredPeople.length > 0 ? (
                    <div style={appStyle}>
                        <div style={wrapperStyle}>
                            <table style={tableStyle}>
                                <thead style={tableHeaderStyle}>
                                    <tr>
                                        <th
                                            style={{
                                                ...thStyle,
                                                color: '#467AFF',
                                            }}
                                        >
                                            Name
                                        </th>
                                        <th
                                            style={{
                                                ...thStyle,
                                                color: '#467AFF',
                                            }}
                                        >
                                            Blood Group
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                            <div style={tbodyWrapperStyle}>
                                <table style={tableStyle}>
                                    <tbody style={tableBodyStyle}>
                                        {filteredPeople?.map(
                                            (person, index) => (
                                                <tr key={index}>
                                                    <td style={thTdStyle}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    person.ALUMNI_IMAGE_URL ||
                                                                    Avatar
                                                                }
                                                                alt='Avatar'
                                                                className='rounded-circle shadow-sm'
                                                                style={{
                                                                    border: '3px solid white',
                                                                    width: '25px',
                                                                    height: '25px',
                                                                }}
                                                                width='30'
                                                            />
                                                            <div
                                                                style={{
                                                                    fontSize:
                                                                        '14px',
                                                                    textAlign:
                                                                        'start',
                                                                    paddingLeft:
                                                                        '2px',
                                                                }}
                                                            >
                                                                <span>
                                                                    {
                                                                        person.ALUMNI_Name
                                                                    }
                                                                </span>
                                                                <br />
                                                                {
                                                                    person.PHONE_NUMBER
                                                                }{' '}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td
                                                        style={{
                                                            padding: '10px',
                                                        }}
                                                    >
                                                        {person.bloodGroups}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                ) : (
                    'No matchings are found'
                )}
            </div>
        </div>
    );
};

export default BloodDonor;
