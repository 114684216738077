import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import FormikControl from '../../../components/formikControl/FormikControl';
import withAlert from '../../../components/withAlert';
import Loader from '../../../components/Loader';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
// import extractErrorFromRes from '../../helpers/extractErrorFromRes';
import CourseForm from '../../courses/CourseForm';
import { useAxiosGet } from '../../../hooks/axiosHooks';
import endpoints from '../../../endpoints';

function InstitutionForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
    updateValues,
}) {
    const submitHandler = (values, { setSubmitting }) => {
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            })
            .finally((res) => setSubmitting(false));
    };

    const initialFormValues = updateValues || {
        INSTITUTION_NAME: '',
        INSTITUTION_IS_ACTIVE: '',
        INSTITUTION_IS_APPROVED: 1,
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                INSTITUTION_NAME: Yup.string().required(),

                INSTITUTION_IS_ACTIVE: Yup.string().required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FormikControl
                        control='input'
                        required
                        label='Institution Name'
                        name='INSTITUTION_NAME'
                    />
                    <FormikControl
                        control='check'
                        type='radio'
                        options={[
                            { key: 'Active', value: '1' },
                            { key: 'Inactive', value: '2' },
                        ]}
                        required
                        label='Institution Active Status'
                        name='INSTITUTION_IS_ACTIVE'
                    />

                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(InstitutionForm);
