import { useEffect } from "react";
import { useAuthenticationState } from "../context/Auth.context";

const UseToggleRefetch=(reFetch)=>{
const{user}=useAuthenticationState();
useEffect(() => {     
    reFetch()
}, [user.INSTITUTION_ID]); 
}

export default UseToggleRefetch;