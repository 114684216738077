import axios from "axios";
import { Form, Formik } from "formik";
import FormikControl from "../../components/formikControl/FormikControl";
import ModalWrapper from "../../components/ModalWrapper";
import endpoints from "../../endpoints";
import * as Yup from "yup";
import FormSubmissionBtn from "../../components/FormSubmissionBtn";
import withAlert from "../../components/withAlert";
import Loader from "../../components/Loader";
import { useAxiosGet } from "../../hooks/axiosHooks";

function FirstTimeLogin({ showAlert }) {
    const { response: institutions } = useAxiosGet(
        endpoints.authentication.institutions
    );

    const institutionOptions = institutions?.map((institution) => ({
        value: institution.INSTITUTION_ID,
        label: institution.INSTITUTION_NAME,
    }));

    function firstTimeLogin(values, setSubmitting, closeModal) {
        axios
            .post(endpoints.authentication.activate, {
                Email: values.email,
                INSTITUTION_ID: values.INSTITUTION_ID || 1,
            })
            .then(() => {
                showAlert(
                    "success",
                    "Login instructions has been sent to your mail"
                );
                closeModal();
            })
            .catch((err) => {
                showAlert(
                    "error",
                    err.response.data?.statusText || "Something went wrong"
                );
            })
            .finally((res) => setSubmitting(false));
    }

    return (
        <>
            <ModalWrapper
                modalTitle={"New user"}
                modalAttrs={{ size: "sm" }}
                renderModalBody={(closeModal) => (
                    <Formik
                        onSubmit={(values, { setSubmitting }) => {
                            firstTimeLogin(values, setSubmitting, closeModal);
                        }}
                        initialValues={{
                            email: "",
                            INSTITUTION_ID: "",
                        }}
                        validationSchema={Yup.object({
                            email: Yup.string().email().required(),
                            INSTITUTION_ID: Yup.string().required(),
                        })}
                    >
                        {({ isSubmitting, setFieldValue }) => (
                            <Form>
                                <FormikControl
                                    label="Email"
                                    required
                                    name="email"
                                />
                                <FormikControl
                                    control="select-advanced"
                                    required
                                    options={institutionOptions}
                                    onChange={(value) => {
                                        setFieldValue(
                                            "INSTITUTION_ID",
                                            value.value
                                        );
                                    }}
                                    optionValue="value"
                                    optionLabel="label"
                                    label="Institution"
                                    name="INSTITUTION_ID"
                                />
                                <div
                                    style={{ fontSize: "12px" }}
                                    className="pt-1"
                                >
                                    Please provide the email registered in your
                                    organization
                                </div>
                                <FormSubmissionBtn onCancel={closeModal} />

                                {isSubmitting && <Loader />}
                            </Form>
                        )}
                    </Formik>
                )}
            >
                <span
                    className="px-2 py-1"
                    style={{ color: "#396FFA", fontWeight: "600" }}
                >
                    First time Login
                </span>
            </ModalWrapper>
        </>
    );
}

export default withAlert(FirstTimeLogin);
