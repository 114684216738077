import React, { useState } from 'react';
import './CustomToolTip.css';
import { ReactComponent as ToolTip } from '../../asset/icons/ToolTipIcon.svg';
import { ReactComponent as ToolTipDownArrow } from '../../asset/icons/TooltipDownIcon.svg';
import { ReactComponent as TooltipLinkIcon } from '../../asset/icons/TooltipLinkIcon.svg';

const CustomToolTip = () => {
    const [showRightDiv, setShowRightDiv] = useState(false);

    const handleClick = () => {
        setShowRightDiv(!showRightDiv);
    };

    return (
        <div>
            <div className="left-div btnAnime" onClick={handleClick}>
                <ToolTip />
            </div>
            {showRightDiv && (
                <>
                    <div className="right-div">
                        <div style={{ position: 'relative' }}>
                            <div style={{ color: 'white', fontWeight: 600 }}>
                                City Chapter Alumni Mapping
                            </div>
                            <div style={{ color: 'white', fontWeight: 400 }}>
                                Assign alumni to relevant city chapters based on
                                location.
                            </div>
                            <div className="line"></div>

                            <div className="to-map">
                                To Map
                                <TooltipLinkIcon className="link-icon" />
                                <div className="details">
                                    City Chapter Details / City Chapter Alumni
                                    Mapping / City Chapter Alumni List
                                </div>
                            </div>
                            <div className="down-arrow">
                                <ToolTipDownArrow />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default CustomToolTip;
