function StatusIndicator({ variant, status }) {
    return (
        <span className='d-inline-flex position-relative'>
            <span
                className={variant + ' d-inline-block position-absolute'}
                style={{
                    width: '5px',
                    height: '100%',
                }}
            />
            <small className='border rounded-end py-1 ps-3 pe-2'>
                {status}
            </small>
        </span>
    );
}

export default StatusIndicator;
