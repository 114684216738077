
const viewProps = Object.freeze({
    AlumniDetails: {
        name: 'Alumni Details',
        columnHeads: [
            'ALUMNI ID',
            'ALUMNI NAME',
            'USER TYPE',
            'BATCH',
            'COURSE',
            'YEAR OF GRADUATION',
            'ACTIVE STATUS',
            'EMAIL',
            'PHONE NUMBER',
            'DOB',
            'Current Location',
            'ABOUT',
            'EMPLOYED ?',
            'LINKEDIN LINK',
            'ORGANIZATION',
            'INDUSTRY',
            'DESIGNATION',
            'EXPERIENCE(months)',
            'PREVIOUS LATEST QUALIFICATION',
            'ENTREPRENEUR ?',
            'LOOKING FOR A CHANGE ?',
            'IS BLOOD DONOR',
            'BLOOD GROUP',
            'PRIVACY SETTINGS',
            // 'Reset Password',
        ],
        relevants: [
            'ALUMNI_BATCH_ADM_CODE',
            'ALUMNI_Name',
            'user_type',
            'BATCH_CODE',
            'courses',
            'YearOfGraduation',
            'IsActive',
            'PERSONAL_EMAILID',
            'PHONE_NUMBER',
            'DateOfBirth',
            'Alumni_Current_Location',
            'ALUMNI_ABOUTME',
            'IsEmployed',
            'LinkedInProfileLink',
            'Organization_Name',
            'Industry',
            'Designation',
            'YearsOfexperience',
            'PreviousLatestQualification',
            'IsEntrepreneur',
            'IsLookingForAChange',
            'isBloodDonor',
            'bloodGroup',
            'PrivacySettings',
            // 'ResetPassword',
        ],
    },
    StudentDetails: {
        name: 'Student Details',
        columnHeads: [
            'STUDENT ID',
            'STUDENT NAME',
            'USER TYPE',
            'BATCH',
            'COURSE',
            'YEAR OF GRADUATION',
            'ACTIVE STATUS',
            'EMAIL',
            'PHONE NUMBER',
            'DOB',
            'Current Location',
            'ABOUT',
            'EMPLOYED ?',
            'LINKEDIN LINK',
            'ORGANIZATION',
            'INDUSTRY',
            'DESIGNATION',
            'EXPERIENCE(months)',
            'PREVIOUS LATEST QUALIFICATION',
            'ENTREPRENEUR ?',
            'LOOKING FOR A CHANGE ?',
            'IS BLOOD DONOR',
            'BLOOD GROUP',
            'PRIVACY SETTINGS',
            // 'Reset Password',
        ],
        relevants: [
            'ALUMNI_BATCH_ADM_CODE',
            'ALUMNI_Name',
            'user_type',
            'BATCH_NAME',
            'courses',
            'YearOfGraduation',
            'IsActive',
            'PERSONAL_EMAILID',
            'PHONE_NUMBER',
            'DateOfBirth',
            'Alumni_Current_Location',
            'ALUMNI_ABOUTME',
            'IsEmployed',
            'LinkedInProfileLink',
            'Organization_Name',
            'Industry',
            'Designation',
            'YearsOfexperience',
            'PreviousLatestQualification',
            'IsEntrepreneur',
            'IsLookingForAChange',
            'isBloodDonor',
            'bloodGroup',
            'PrivacySettings',
            // 'ResetPassword',
        ],
    },
    FacultyDetails: {
        name: 'Faculty Details',
        columnHeads: [
            'FACULTY ID',
            'FACULTY NAME',
            'USER TYPE',
            'ACTIVE STATUS',
            'EMAIL',
            'PHONE NUMBER',
            'DOB',
            'Current Location',
            'ABOUT',
            'EMPLOYED ?',
            'LINKEDIN LINK',
            'ORGANIZATION',
            'INDUSTRY',
            'DESIGNATION',
            'EXPERIENCE(months)',
            'PREVIOUS LATEST QUALIFICATION',
            'ENTREPRENEUR ?',
            'LOOKING FOR A CHANGE ?',
            'IS BLOOD DONOR',
            'BLOOD GROUP',
            'PRIVACY SETTINGS',
            // 'Reset Password',
        ],
        relevants: [
            'ALUMNI_BATCH_ADM_CODE',
            'ALUMNI_Name',
            'user_type',
            'IsActive',
            'PERSONAL_EMAILID',
            'PHONE_NUMBER',
            'DateOfBirth',
            'Alumni_Current_Location',
            'ALUMNI_ABOUTME',
            'IsEmployed',
            'LinkedInProfileLink',
            'Organization_Name',
            'Industry',
            'Designation',
            'YearsOfexperience',
            'PreviousLatestQualification',
            'IsEntrepreneur',
            'IsLookingForAChange',
            'isBloodDonor',
            'bloodGroup',
            'PrivacySettings',
            // 'ResetPassword',
        ],
    },
    InstituteMemberDetails: {
        name: 'Institute Member Details',
        columnHeads: [
            'INSTITUTE MEMBER ID',
            'INSTITUTE MEMBER NAME',
            'USER TYPE',
            'ACTIVE STATUS',
            'EMAIL',
            'PHONE NUMBER',
            'DOB',
            'Current Location',
            'ABOUT',
            // 'EMPLOYED ?',
            // 'LINKEDIN LINK',
            // 'ORGANIZATION',
            // 'INDUSTRY',
            // 'DESIGNATION',
            // 'EXPERIENCE(months)',
            // 'PREVIOUS LATEST QUALIFICATION',
            // 'ENTREPRENEUR ?',
            // 'LOOKING FOR A CHANGE ?',
            // 'PRIVACY SETTINGS',
            // 'Reset Password',
        ],
        relevants: [
            'ALUMNI_BATCH_ADM_CODE',
            'ALUMNI_Name',
            'Type',
            'IsActive',
            'PERSONAL_EMAILID',
            'PHONE_NUMBER',
            'DateOfBirth',
            'Alumni_Current_Location',
            'ALUMNI_ABOUTME',
            // 'IsEmployed',
            // 'LinkedInProfileLink',
            // 'Organization_Name',
            // 'Industry',
            // 'Designation',
            // 'YearsOfexperience',
            // 'PreviousLatestQualification',
            // 'IsEntrepreneur',
            // 'IsLookingForAChange',
            // 'PrivacySettings',
            // 'ResetPassword',
        ],
    },
    AlumniPersonalTag: {
        name: 'Alumni Personal Tag Mapping',
        relevants: [
            'ALUMNI_BATCH_ADM_CODE',
            'ALUMNI_Name',
            'BATCH_NAME',
            'MAPPING',
        ],
        columnHeads: ['ALUMNI ID', 'ALUMNI NAME', 'BATCH', 'PERSONAL TAGS'],
    },
    FacultyPersonalTag: {
        name: 'Faculty Personal Tag Mapping',
        relevants: [
            'ALUMNI_BATCH_ADM_CODE',
            'ALUMNI_Name',
            'MAPPING',
        ],
        columnHeads: ['Faculty ID', 'Faculty NAME', 'PERSONAL TAGS'],
    },
    StudentPersonalTag: {
        name: 'Student Personal Tag Mapping',
        relevants: [
            'ALUMNI_BATCH_ADM_CODE',
            'ALUMNI_Name',
            'BATCH_NAME',
            'MAPPING',
        ],
        columnHeads: ['Student ID', 'Student NAME', 'BATCH', 'PERSONAL TAGS'],
    },
    CityChapters: {
        // name: 'City Chapters',
        relevants: [
            'CityChapter_ID',
            'CityChapter_Name',
            'CITY',
            'STATE',
            'COUNTRY',
            'AlumniCityChapterMap',
        ],
        columnHeads: [
            'CITY CHAPTER ID',
            'CITY CHAPTER NAME',
            'CITY',
            'STATE',
            'COUNTRY',
            'Alumni in City Chapter',
        ],
    },
    CityChapterAlumniMap: {
        name: 'City Chapter User Mapping',
        relevants: ['ALUMNI_BATCH_ADM_CODE', 'ALUMNI_Name'],
        columnHeads: ['ID', 'NAME'],
    },
    EventCalendar: {
        name: 'Event Calendar',
        relevants: [
            'Event_CALENDAR_EVENT_NAME',
            'Event_CALENDAR_TYPE',
            'Event_Event_Type',
            'BATCH_NAME',
            'Created_Alumni_Name',
            'COURSE_NAME',
            'Event_Status_Approve',
            'Event_CALENDAR_EVENT_DESC',
            'Event_Location',
            'Event_CALENDAR_BROADCAST_TYPE',
            'RSVP',
            'Event_CALENDAR_EVENT_STATUS',
            'Event_CALENDAR_SCHEDULE_STARTDATE',
            'Event_CALENDAR_SCHEDULE_ENDDATE',
            'Event_CALENDAR_SCHEDULE_STARTTIME',
            'Event_CALENDAR_SCHEDULE_ENDTIME',
            'Event_CALENDAR_SCHEDULE_DURATION',
        ],
        columnHeads: [
            'EVENT NAME',
            'Event Calendar TYPE',
            'Event Type',
            'Batch',
            'Created By',
            'Course',
            'Approval Status',
            'Description',
            'Event Location',
            'Broadcast Type',
            'RSVP',
            'Event Status',
            'START DATE',
            'END DATE',
            'START TIME',
            'END TIME',
            'Duration(min)',
        ],
    },
    EventCalendarNew: {
        name: 'Event Calendar',
        relevants: [
            'Event_CALENDAR_EVENT_NAME',
            'Event_CALENDAR_BROADCAST_TYPE',
            'BATCH_NAME',
            'Event_CALENDAR_SCHEDULE_STARTDATE',
            'Event_CALENDAR_SCHEDULE_STARTTIME',
            'Event_Location',
            'RSVP',
        ],
        columnHeads: [
            'EVENT NAME',
            'Broadcast Type',
            'Batch',
            'START DATE',
            'START TIME',
            'Location',
            'RSVP',
        ],
    },
    EventCalendarCCH: {
        name: 'Event Calendar',
        relevants: [
            'Event_CALENDAR_EVENT_NAME',
            'Event_CALENDAR_BROADCAST_TYPE',
            'BATCH_NAME',
            'Event_CALENDAR_SCHEDULE_STARTDATE',
            'Event_CALENDAR_SCHEDULE_STARTTIME',
            'Event_Location',
            'RSVP',
        ],
        columnHeads: [
            'EVENT NAME',
            'Broadcast Type',
            'Batch',
            'START DATE',
            'START TIME',
            'Location',
            'RSVP',
        ],
    },
    DashboardEventCalendar: {
        relevants: [
            'Event_CALENDAR_EVENT_NAME',
            'BROADCAST_TYPE',
            'BATCH_NAME',
            'Event_CALENDAR_SCHEDULE_STARTDATE',
            'Event_CALENDAR_SCHEDULE_STARTTIME',
            'Event_Location',
            'RSVP',
        ],
        columnHeads: [
            'Event Name',
            'Broadcast Type',
            'Batch',
            'Date',
            'Time',
            'Location',
            'RSVP',
        ],
    },
    RSVP: {
        name: 'Users',
        relevants: [
            'RSVP_EVENT_ID',
            'ALUMNI_BATCH_ADM_CODE',
            'ALUMNI_Name',
            'RSVP_STATUS',
            'IsEmployed',
            'IsActive',
            'IsEntrepreneur',
        ],
        columnHeads: [
            'EVENT ID',
            'USER ID',
            'Name',
            'RSVP STATUS',
            'Is Employed ?',
            'USER Status',
            'Is an Entrepreneur ?',
        ],
    },
    Announcements: {
        name: 'Announcements',
        relevants: [
            'Alumni_AnnouncmentReq_AlumniID',
            'ALUMNI_Name',
            'Alumni_AnnouncmentReq_PRIORITY',
            'Alumni_AnnouncmentReq_TITLE',
            'Alumni_AnnouncmentReq_DESC',
            'Alumni_AnnouncmentReq_TYPE',
            'BATCH_NAME',
            'Alumni_AnnouncmentReq_RESPONSE_ID',
            'is_deleted_from_mobile',
            // 'Alumni_AnnouncmentReq_RESPONSE_COMMENT',
            'Alumni_AnnouncmentReq_STATUS',
            'Alumni_AnnouncmentReq_Location',
            'Alumni_AnnouncmentReq_ExpiryDate',
            'Alumni_AnnouncmentReq_AlternatePhoneNo',
            'Alumni_AnnouncmentReq_AlternateEmail',
            // "Approve",
            // "Reject",
        ],
        columnHeads: [
            'User ID',
            'Name',
            'PRIORITY',
            'TITLE',
            'DESC',
            'Announcement TYPE',
            'Batch',
            'APPROVAL STATUS',
            'DELETE STATUS',
            // 'RESPONSE COMMENT',
            'STATUS',
            'Announcement Location',
            'Expiry Date',
            'PHONE',
            'EMAIL',
            // "Approve",
            // "Reject",
        ],
    },
    AnnouncementsNew: {
        name: 'Announcements',
        relevants: [
            'Alumni_AnnouncmentReq_TITLE',
            'Alumni_AnnouncmentReq_BROADCAST_TYPE',
            'BATCH_NAME',
            'Alumni_AnnouncmentReq_ExpiryDate',
            'Alumni_AnnouncmentReq_Location',
            'Alumni_AnnouncmentReq_RESPONSE_ID',
        ],
        columnHeads: [
            'Announcement Title',
            'Broadcast Type',
            'Batch',
            'Date',
            'Location',
            'Status',
        ],
    },
    DashboardAnnouncements: {
        relevants: [
            'Alumni_AnnouncmentReq_TITLE',
            'Alumni_AnnouncmentReq_BROADCAST_TYPE_ID',
            'BATCH_NAME',
            'Alumni_AnnouncmentReq_ExpiryDate',
            'Alumni_Current_Location',
            // 'Alumni_AnnouncmentReq_TYPE',
            // 'BATCH_NAME',
            // 'Alumni_AnnouncmentReq_RESPONSE_ID',
            // // 'Alumni_AnnouncmentReq_RESPONSE_COMMENT',
            // 'Alumni_AnnouncmentReq_STATUS',
            // 'Alumni_AnnouncmentReq_Location',
            // 'Alumni_AnnouncmentReq_ExpiryDate',
            // 'Alumni_AnnouncmentReq_AlternatePhoneNo',
            // 'Alumni_AnnouncmentReq_AlternateEmail',
            // "Approve",
            // "Reject",
        ],
        columnHeads: [
            'Announcement Title',
            'Broadcast Type',
            'Batch',
            // 'RESPONSE COMMENT',
            'Date',
            'Location',
            'RSVP',
            // "Approve",
            // "Reject",
        ],
    },
    AlumniLookingForJob: {
        name: 'Member Looking For Job',
        relevants: [
            'ALUMNI_BATCH_ADM_CODE',
            'ALUMNI_Name',
            'Requirement_Priority',
            'Job_Requirement_Status_Approve',
            'is_deleted_from_mobile',
            'OpenToDomainChange',
            'OpenToRelocation',
            'DomainLookout',
            'LocationLookOut',
            'RelevantYearsOfExperience',
            'AssociatedSkillSet',
            'IsJobRequirementStatusActive',
            'JobRequirementDetails',
            'LinkedINProfile',
            'ReasonForChange',
            'OpenTohaveProfileSharedToExternalRecruiters',
        ],
        columnHeads: [
            'USER ID',
            'NAME',
            'REQUIREMENT PRIORITY',
            'Job Approval Status',
            'Delete Status',
            'OPEN TO DOMAIN CHANGE?',
            'OPEN TO Relocate?',
            'DOMAIN LOOKOUT',
            'LOCATIONLOOKOUT',
            'RELEVANT YEARS OF EXP',
            'SKILLS',
            'STATUS',
            'Job Requirement Details',
            'LinkedIn Profile',
            'Reason For Change',
            'Share Profile To External Recruiters?',
        ],
    },
    PostedJobs: {
        name: 'Posted Jobs',
        relevants: [
            'ALUMNI_Name',
            'ALUMNI_BATCH_ADM_CODE',
            // 'BATCH_NAME',
            'Alumni_JobListing_NAME',
            'Alumni_JobListing_TYPE',
            'Alumni_JobListing_STATUS',
            'Alumni_Joblisting_Approve_Status',
            'Alumni_joblisting_Location',
            'Alumni_Joblisting_Organization',
            // 'Designation',
            // 'Industry',
            'Alumni_Joblisting_Link',
            'LinkedInProfileLink',
            'PERSONAL_EMAILID',
            'PHONE_NUMBER',
            'Alumni_JobListing_ExpiryDATE',
            'Approve',
            'Reject',
        ],
        columnHeads: [
            'Posted By',
            'Posted USER ID',
            // 'Posted ALUMNI BATCH',
            'Job Title',
            'Job Type',
            'Job Status',
            'Approval Status',
            'Job Location',
            'Organization',
            // 'Designation',
            // 'Industry',
            'Job provider link',
            'Posted USER Linkedin',
            'Posted USER Email',
            'Posted USER Phone',
            'Expiry Date',
            'Approve',
            'Reject',
        ],
    },
    MarkAsSafeDash: {
        name: 'Mark as Safe Events',
        relevants: [
            'ALUMNI_Name',
            'BATCH_NAME',
            'PHONE_NUMBER',
            'Alumni_MarkAsSafe_EventName',
            'CityChapter_Name',
            'Alumni_MarkAsSafe_Response',
            'Alumni_MarkAsSafe_EventStartDate',
            'Alumni_MarkAsSafe_EventEndDate',
        ],
        columnHeads: [
            'User Name',
            'BATCH',
            'Contact',
            'Event Name',
            'City Chapter',
            'Response',
            'Started Date',
            'Ended Date',
        ],
    },
    MarkAsSafe: {
        name: 'Mark as Safe Events',
        relevants: [
            'Alumni_MarkAsSafe_EventName',
            'Alumni_MarkAsSafe_EventTYPE',
            'Alumni_MarkAsSafe_STATUS',
            'Response',
            'Alumni_MarkAsSafe_BROADCAST_TYPE',
            'BATCH_NAME',
            'CityChapter_Name',
            'Alumni_MarkAsSafe_EventStartDate',
            'Alumni_MarkAsSafe_EventEndDate',
        ],
        columnHeads: [
            'Event Name',
            'Event TYPE',
            'STATUS',
            'Response',
            'BROADCAST TYPE',
            'BATCH',
            'CITYCHAPTER',
            'Started Date',
            'Ended Date',
        ],
    },
    DashboardMarkAsSafe: {
        name: 'Mark as Safe Events',
        relevants: [
            'ALUMNI_Name',
            'BATCH_NAME',
            'PHONE_NUMBER',
            'Alumni_MarkAsSafe_EventTYPE',
            'CityChapter_Name',
            'Alumni_MarkAsSafe_Response',
            'Alumni_MarkAsSafe_EventStartDate',
            'Alumni_MarkAsSafe_EventEndDate',
        ],
        columnHeads: [
            'User Name',
            'Batch',
            'Contact',
            'Event Type',
            'City Chapter',
            'Response',
            'Started Date',
            'Ended Date',
        ],
    },
    SafetyResponse: {
        name: 'User Safety Response',
        relevants: [
            'Alumni_MarkAsSafe_AlumniID',
            'Alumni_MarkAsSafe_EventName',
            'ALUMNI_Name',
            'Alumni_MarkAsSafe_Response',
            'BATCH_NAME',
            'COURSE_NAME',
            'CityChapter_Name',
            'PHONE_NUMBER',
            'PERSONAL_EMAILID',
            'YearOfGraduation',
            'DateOfBirth',
        ],
        columnHeads: [
            'User ID',
            'Safety Event Name',
            'Name',
            'Response',
            'BATCH',
            'COURSE',
            'Event City Chapter',
            'PHONE',
            'EMAIL',
            'Year Of Graduation',
            'DOB',
        ],
    },
    MarkAsSafenewdb: {
        name: 'Mark as Safe Events',
        relevants: [
            'Alumni_MarkAsSafe_EventName',
            'BATCH_NAME',
            'Alumni_MarkAsSafe_EventTYPE',
            'Alumni_MarkAsSafe_BROADCAST_TYPE',
            'CityChapter_Name',
            'Response',
            'Alumni_MarkAsSafe_EventStartDate',
            'Alumni_MarkAsSafe_EventEndDate',
            // 'Alumni_MarkAsSafe_STATUS',
        ],
        columnHeads: [
            'User Name',
            'Batch',
            'Contact',
            'Event TYPE',
            'CITYCHAPTER',
            'Response',
            'Started Date',
            'Ended Date',
            // 'STATUS',
            // 'BROADCAST TYPE',
        ],
    },
    HelpRequests: {
        name: 'Help Requests',
        relevants: [
            'Alumni_HelpRequest_AlumniID',
            'Alumni_HelpRequest_AlumniNAME',
            'Alumni_HelpRequest_TITLE',
            'Alumni_HelpRequest_TYPE',
            'Alumni_HelpRequest_PRIORITY',
            'Alumni_HelpRequest_STATUS',
            'Alumni_HelpRequest_RESPONSE_ID',
            // 'Alumni_HelpRequest_RESPONSE_COMMENT',
            'Alumni_HelpRequest_BROADCAST_TYPE',
            'Alumni_HelpRequest_DESC',
            'Alumni_HelpRequest_Location',
            'Alumni_HelpRequest_ExpiryDate',
            'Approve',
            'Reject',
        ],
        columnHeads: [
            'User ID',
            'Name',
            'Request Title',
            'Request TYPE',
            'PRIORITY',
            'STATUS',
            'APPROVAL STATUS',
            // 'RESPONSE COMMENT',
            'BROADCAST TYPE',
            'Description',
            'Location',
            'Expiry Date',
            'Approve',
            'Reject',
        ],
    },
    DashboardHelpRequests: {
        relevants: [
            'Alumni_HelpRequest_AlumniNAME',
            'Alumni_HelpRequest_TITLE',
            'Alumni_HelpRequest_TYPE',
            'Alumni_HelpRequest_PRIORITY',
            'CREATEDDATE',
            'Alumni_HelpRequest_ExpiryDate',
            'Alumni_HelpRequest_Location',
            'View_Request',
            // 'Alumni_HelpRequest_RESPONSE_COMMENT',
        ],
        columnHeads: [
            'User Details',
            'Request Title',
            'Request Type ',
            'Priority',
            'Posted Date',
            'Expiry Date',
            // 'RESPONSE COMMENT',
            'Location',
            'View',
        ],
    },
    CovidVaccineStatus: {
        name: 'Covid Vaccine Status',
        relevants: [
            'Alumni_Vaccine_AlumniID',
            'ALUMNI_Name',
            'VaccineStatus',
            'Vaccine_Type',
            'DATEOFFIRSTDOSECOMPLETE',
            'DATEOFSECONDDOSECOMPLETE',
            'VACCINE_REQUIREMENT',
            'DOMICILE_LOCATION',
            'PERSONAL_EMAILID',
            'PHONE_NUMBER',
            'BATCH_NAME',
            'COURSE_NAME',
            'YearOfGraduation',
        ],
        columnHeads: [
            'Alumni ID',
            'ALUMNI Name',
            'Vaccine Status',
            'Vaccine Type',
            'FIRST DOSE COMPLETION DATE',
            'SECOND DOSE COMPLETION DATE',
            'VACCINE REQUIREMENT',
            'LOCATION',
            'EMAIL',
            'PHONE',
            'BATCH',
            'COURSE',
            'Year Of Graduation',
        ],
    },
    GoverningCouncil: {
        name: 'Governing Council',
        relevants: [
            'EC_Member_Alumni_ID',
            'EC_Member_Name',
            'EC_Member_Designation',
            'EC_Member_Elected_Date',
        ],
        columnHeads: [
            'ALUMNI ID',
            'MEMBER NAME',
            'MEMBER DESIGNATION ',
            'ELECTED DATE',
        ],
    },
    CityChapterHead: {
        name: 'City Chapter Head',
        relevants: [
            'CityChapter_Name',
            'CCH_Alumni_ID',
            'CCH_Alumni_Name',
            'CCH_Elected_Date',
            'DCCH_Alumni_ID',
            'DCCH_ALUMNI_Name',
            'DCCH_Elected_Date',
        ],
        columnHeads: [
            'CITY CHAPTER NAME',
            'CCH ALUMNI ID',
            'CCH ALUMNI NAME',
            'CCH ELECTED DATE',
            'DCCH ALUMNI ID',
            'DCCH ALUMNI NAME',
            'DCCH ELECTED DATE',
        ],
    },
    Courses: {
        name: 'Courses',
        relevants: ['COURSE_NAME', 'COURSE_STATUS'],
        columnHeads: ['COURSE NAME', 'COURSE STATUS'],
    },
    PersonalTags: {
        name: 'Personal Tags',
        relevants: ['Alumni_PersonalTag_ID', 'Alumni_Personal_Tag_Name'],
        columnHeads: ['Tag Id', 'Tag'],
    },
    TopicOfInterest: {
        name: 'Topic Of Interest',
        relevants: ['TopicofInterest', 'Approve_Status', 'Approve', 'Reject'],
        columnHeads: ['Topic', 'Response', 'Approve', 'Reject'],
    },
    Batch: {
        name: 'Batch',
        relevants: [
            'BATCH_CODE',
            'BATCH_NAME',
            'COURSE_NAME',
            'BATCH_START_DATE',
            'BATCH_END_DATE',
        ],
        columnHeads: [
            'Batch Code',
            'Batch Name',
            'Course',
            'Batch start Date',
            'Batch End Date',
        ],
    },
    BatchHeads: {
        name: 'Batch Representatives',
        relevants: ['BATCH_NAME', 'COURSE_NAME', 'ALUMNI_Name'],
        columnHeads: ['Batch', 'Course', 'Batch Representative'],
    },
    BenefitProviders: {
        name: 'Benefit Provider',
        relevants: [
            'Alumni_InsuranceProvider_NAME',
            'Alumni_InsuranceProvider_URL',
            'Alumni_InsuranceProvider_Status',
        ],
        columnHeads: ['Benefit Provider', 'Provider url', 'Status'],
    },
    InsuranceDetailsLife: {
        name: 'Insurance Details - Life',
        relevants: [
            'ALUMNI_Name',
            'Alumni_InsuranceProvider_NAME',
            'Alumni_LifeInsurance_AssuredSum',
            'Alumni_LifeInsurance_Premium',
            'Alumni_LifeInsurance_DateofInsurance',
            'Alumni_LifeInsurance_RenewalDate',
            'Alumni_LifeInsurance_STATUS',
            'Alumni_LifeInsurance_document_no',
        ],
        columnHeads: [
            'Name',
            'Insurance Provider',
            'Assured Sum',
            'Premium',
            'Date of Insurance',
            'Renewal Date',
            'STATUS',
            'Document no',
        ],
    },
    InsuranceDetailsHealth: {
        name: 'Insurance Details - Health',
        relevants: [
            'ALUMNI_Name',
            'Alumni_InsuranceProvider_NAME',
            'Alumni_MedicalInsurance_AssuredSum',
            'Alumni_MedicalInsurance_Premium',
            'Alumni_MedicalInsurance_DateofInsurance',
            'Alumni_MedicalInsurance_RenewalDate',
            'Alumni_MedicalInsurance_STATUS',
            'DepDocID',
        ],
        columnHeads: [
            'Name',
            'Insurance Provider',
            'Assured Sum',
            'Premium',
            'Date of Insurance',
            'Renewal Date',
            'STATUS',
            'Dependant Doc Id',
        ],
    },
    BenefitDetailsCard: {
        name: 'Card Details',
        relevants: [
            'Alumni_Name',
            'ALUMNI_BATCH_ADM_CODE',
            'card_Name',
            'Details',
        ],
        columnHeads: ['Name', 'Batch Code', 'Card Name', 'Card Detail'],
    },
    BenefitDetailsSubscription: {
        name: 'Subscription Details',
        relevants: [
            'Alumni_Name',
            'ALUMNI_BATCH_ADM_CODE',
            'subscription_name',
            'subscription_details',
            'url',
        ],
        columnHeads: [
            'Name',
            'Batch Code',
            'Subscription Name',
            'Subscription Detail',
            'Subscription Url',
        ],
    },
    StudentCouncil: {
        name: 'Student Council',
        relevants: [
            'student_council_member_id',
            'course_name',
            'alumni_name',
            'student_council_member_designation',
            'student_council_member_elected_date',
        ],
        columnHeads: [
            'STUDENT ID',
            'COURSE',
            'STUDENT NAME',
            'STUDENT DESIGNATION ',
            'ELECTED DATE',
        ],
    },
    ClassRep: {
        name: 'Class Representatives',
        relevants: ['batch_name', 'course_name', 'alumni_name'],
        columnHeads: ['Batch', 'Course', 'Class Representative'],
    },
    studentLookingForJob: {
        name: 'Student Looking For Job',
        relevants: [
            'ALUMNI_BATCH_ADM_CODE',
            'ALUMNI_Name',
            'Requirement_Priority',
            'Job_Requirement_Status_Approve',
            'is_deleted_from_mobile',
            'OpenToDomainChange',
            'OpenToRelocation',
            'DomainLookout',
            'LocationLookOut',
            'RelevantYearsOfExperience',
            'AssociatedSkillSet',
            'IsJobRequirementStatusActive',
            'JobRequirementDetails',
            'LinkedINProfile',
            'ReasonForChange',
            'OpenTohaveProfileSharedToExternalRecruiters',
        ],
        columnHeads: [
            'STUDENT ID',
            'STUDENT NAME',
            'REQUIREMENT PRIORITY',
            'Job Approval Status',
            'DELETE STATUS',
            'OPEN TO DOMAIN CHANGE?',
            'OPEN TO Relocate?',
            'DOMAIN LOOKOUT',
            'LOCATIONLOOKOUT',
            'RELEVANT YEARS OF EXP',
            'SKILLS',
            'STATUS',
            'Job Requirement Details',
            'LinkedIn Profile',
            'Reason For Change',
            'Share Profile To External Recruiters?',
        ],
    },
    // EventCalendarNew: {
    //     name: 'Event Calendar',
    //     relevants: [
    //         'Event_CALENDAR_EVENT_NAME',
    //         'Event_CALENDAR_BROADCAST_TYPE',
    //         'BATCH_NAME',
    //         'Event_CALENDAR_SCHEDULE_STARTDATE',
    //         'Event_CALENDAR_SCHEDULE_STARTTIME',
    //         'Event_Location',
    //         'RSVP',
    //     ],
    //     columnHeads: [
    //         'EVENT NAME',
    //         'Broadcast Type',
    //         'Batch',
    //         'START DATE',
    //         'START TIME',
    //         'Location',
    //         'RSVP',
    //     ],
    // },
    // AnnouncementsNew: {
    //     name: 'Announcements',
    //     relevants: [
    //         'Alumni_AnnouncmentReq_TITLE',
    //         'Alumni_AnnouncmentReq_BROADCAST_TYPE',
    //         'BATCH_NAME',
    //         'Alumni_AnnouncmentReq_ExpiryDate',
    //         'Alumni_AnnouncmentReq_Location',
    //         'Alumni_AnnouncmentReq_STATUS_ID',
    //     ],
    //     columnHeads: [
    //         'Announcement Title',
    //         'Broadcast Type',
    //         'Batch',
    //         'Date',
    //         'Location',
    //         'Status',
    //     ],
    // },
    // MarkAsSafeDash: {
    //     name: 'Mark as Safe Events',
    //     relevants: [
    //         'ALUMNI_Name',
    //         'BATCH_NAME',
    //         'PHONE_NUMBER',
    //         'Alumni_MarkAsSafe_EventTYPE',
    //         'CityChapter_Name',
    //         'Alumni_MarkAsSafe_Response',
    //         'Alumni_MarkAsSafe_EventStartDate',
    //         'Alumni_MarkAsSafe_EventEndDate',
    //     ],
    //     columnHeads: [
    //         'User Name',
    //         'BATCH',
    //         'Contact',
    //         'Event Name',
    //         'City Chapter',
    //         'Response',
    //         'Started Date',
    //         'Ended Date',
    //     ],
    // },
    DashboardStudentLookingForJob: {
        relevants: [
            'ALUMNI_Name',
            'DomainLookout',
            'AssociatedSkillSet',
            'Requirement_Priority',
            'Job_Requirement_Status_Approve',
            'LocationLookOut',
            'ViewRequests',
        ],
        columnHeads: [
            'User Details',
            'Job Title',
            'Skills',
            'Priority',
            'Job Approval Status',
            'Location',
            'View',
        ],
    },
    Promos: {
        name: 'Promos',
        relevants: [
            'Alumni_PromoListing_NAME',
            'Alumni_PromoListing_DESC',
            'Alumni_PromoCategory',
            'Alumni_PromoDiscountCode',
            'PERSONAL_EMAILID',
            'Alumni_Promolisting_Link',
        ],
        columnHeads: [
            'PROMO TITLE',
            'DESCRIPTION',
            'CATEGORY',
            // 'BROADCAST TYPE',
            'PROMO CODE',
            'EMAIL',
            'LINK',
        ],
    },
    HelpDash: {
        name: 'Help',
        relevants: [
            'Alumni_HelpRequest_AlumniNAME',
            'Alumni_HelpRequest_TITLE',
            'Alumni_HelpRequest_Type',
            'Alumni_HelpRequest_PRIORITY',
            'CREATEDDATE',
            'Alumni_HelpRequest_ExpiryDate',
            'Alumni_HelpRequest_Location',
            'View',
        ],
        columnHeads: [
            'User Details',
            'Request Title',
            'Request Type',
            'Priority',
            'Posted Date',
            'Expiry Date',
            'Location',
            'View',
        ],
    },
    JobDash: {
        name: 'Job',
        relevants: [
            'ALUMNI_Name',
            'DomainLookout',
            'AssociatedSkillSet',
            'Requirement_Priority',
            'DateCreated',
            'LocationLookOut',
            'View',
        ],
        columnHeads: [
            'User Details',
            'Job Title',
            'Skills',
            'Priority',
            'Posted Date',
            'Location',
            'View',
        ],
    },
    BloodDonor: {
        name: 'Blood Donor',
        relevants: [
            'ALUMNI_Name',
            'BATCH_NAME',
            'bloodGroups',
            'PHONE_NUMBER',
            'PERSONAL_EMAILID',
            'Alumni_Current_Location',
            // 'Donate date',
        ],
        columnHeads: [
            'USER DETAIL',
            'BATCH',
            'BLOOD GROUP',
            'CONTACT',
            'EMAIL',
            'LOCATION',
            // 'LAST DONATED',
        ],
    },
    BloodDonorDash: {
        // name: 'Blood Donor',
        relevants: ['ALUMNI_Name', 'bloodGroups'],
        columnHeads: ['User Name', 'Blood Group'],
    },
});

export default viewProps;
