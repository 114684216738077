import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import ClassRepresentativeForm from './ClassRepresentativeForm';
import objCloneKeyValue from '../../helpers/objCloneKeyValue';

function ClassRepresentative() {
    const fetchUtils = useAxiosGet(
        endpoints.classRep.get + '?page=1&limit=1000'
    );

    let classRepData = objCloneKeyValue(
        fetchUtils.response,
        'batch_code',
        'batchCode'
    );

    classRepData = objCloneKeyValue(
        classRepData,
        'class_rep_userid',
        'classRepUserId'
    );

    return (
        <>
            <CRUDTable
                endpoints={endpoints.classRep}
                Form={ClassRepresentativeForm}
                {...viewProps.ClassRep}
                {...fetchUtils}
                response={classRepData}
            />
        </>
    );
}

export default ClassRepresentative;
