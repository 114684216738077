import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import FormikControl from '../../components/formikControl/FormikControl';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';
import { useAxiosGet, useAxiosGetMultiple } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';

function StudentCouncilForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
    updateValues,
}) {
    const { response, loading } = useAxiosGetMultiple({
        student: endpoints.alumniDetail.getStudentOnly,
        course: endpoints.course.get,
    });
    const { student, course } = response;
    const formType = updateValues ? 'update' : 'add';
    const [courseFieldLoading, setCourseFieldLoading] = useState(false);

    const submitHandler = (values, { setSubmitting }) => {
        const body = { ...values };
        body.CityChapter_ID = '1';
        axios
            .post(endpoint, body)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            })
            .finally(() => setSubmitting(false));
    };

    const filterStudentsByCourse = (course_id) => {
        return student.filter((student) =>
            student.BATCH_CODE.some((batch) => batch.COURSE_ID === course_id)
        );
    };

    const initialFormValues = {
        studentCouncilMemberId: updateValues?.student_council_member_id || '',
        newCouncilMemberId: updateValues?.student_council_member_id || '',
        studentCouncilMemberDesignation:
            updateValues?.student_council_member_designation || '',
        studentCouncilMemberElectedDate:
            updateValues?.student_council_member_elected_date || '',
        course_id: updateValues?.course_id || '',
    };
    const preSelectedOption = {};

    if (updateValues) {
        preSelectedOption.student = student.find(
            (student) =>
                Number(student.ALUMNI_BATCH_ADM_CODE) ===
                Number(initialFormValues.studentCouncilMemberId)
        );

        preSelectedOption.course = course.find(
            (course) =>
                Number(course.COURSE_ID) === Number(updateValues?.course_id)
        );
    }
    if (loading) {
        return <Loader />;
    }
    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                course_id: Yup.string().required(),
                studentCouncilMemberId: Yup.string().required(),
                studentCouncilMemberDesignation: Yup.string().required(),
                studentCouncilMemberElectedDate: Yup.date()
                    .max(new Date(), 'Cannot be a future date')
                    .required(),
                newCouncilMemberId: Yup.string(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    {/* {!updateValues && ( */}
                    <>
                        <FormikControl
                            control='select-advanced'
                            required
                            label='Course'
                            options={course}
                            optionValue='COURSE_ID'
                            onChange={(course) => {
                                setFieldValue(
                                    'course_id',
                                    course.COURSE_ID,
                                    true
                                );
                                setFieldValue(
                                    'studentCouncilMemberId',
                                    '',
                                    true
                                );
                                setFieldValue('newCouncilMemberId', '');
                                setCourseFieldLoading(true);
                                setTimeout(() => {
                                    setCourseFieldLoading(false);
                                }, 500);
                            }}
                            optionLabel='COURSE_NAME'
                            name='course_id'
                            defaultValue={preSelectedOption.course}
                        />
                        {courseFieldLoading ? (
                            <Loader />
                        ) : (
                            <FormikControl
                                control='select-advanced'
                                required
                                name='studentCouncilMemberId'
                                label='Member'
                                disabled={!values.course_id || formType !== 'add'}
                                onChange={(selectedMember) => {
                                    if (updateValues) {
                                        setFieldValue(
                                            'newCouncilMemberId',
                                            selectedMember.ALUMNI_BATCH_ADM_CODE
                                        );
                                    } else {
                                        setFieldValue(
                                            'studentCouncilMemberId',
                                            selectedMember.ALUMNI_BATCH_ADM_CODE
                                        );
                                    }
                                }}
                                options={filterStudentsByCourse(
                                    values.course_id
                                )}
                                optionValue='ALUMNI_BATCH_ADM_CODE'
                                defaultValue={preSelectedOption.student}
                                optionLabel='ALUMNI_Name'
                            />
                        )}
                    </>
                    {/* )} */}

                    <FormikControl
                        control='input'
                        required
                        name='studentCouncilMemberDesignation'
                        label='Member Designation'
                    />
                    <FormikControl
                        control='input'
                        type='date'
                        required
                        name='studentCouncilMemberElectedDate'
                        label='Member Elected Date'
                    />

                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(StudentCouncilForm);
