import React from 'react';
import Table from '../../components/basicTable/TableFomat';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { Link } from 'react-router-dom';
import { useAuthenticationState } from '../../context/Auth.context';
import ModalWrapper from '../../components/ModalWrapper';
import PostWithWarning from '../../components/PostWithWarning';
import { ReactComponent as Approve } from '../../asset/icons/Approve.svg';
import { ReactComponent as Reject } from '../../asset/icons/Block.svg';
import StatusIndicator from '../../components/StatusIndicator';
import withAlert from '../../components/withAlert';

function EventCalendar({ showAlert }) {
    const { user } = useAuthenticationState();

    let getApi;
    let endpoint;

    getApi = endpoints.eventCalendar.get;
    endpoint = endpoints.eventCalendar;

    const fetchUtils = useAxiosGet(getApi);
    const cellModifier = {
        Event_CALENDAR_BROADCAST_TYPE: ({ value }) => {
            switch (Number(value)) {
                case 1:
                    return 'ALL';
                case 2:
                    return 'BATCH';
                case 3:
                    return 'COURSE';

                default:
                    return value;
            }
        },

        Event_CALENDAR_SCHEDULE_STARTDATE: ({ value }) => {
            return new Date(value).toDateString();
        },

        Event_CALENDAR_SCHEDULE_STARTTIME: ({ value }) => {
            return new Date(value).toLocaleTimeString('en', {
                timeStyle: 'short',
            });
        },

        RSVP: ({ row }) => {
            return (
                <Link to='/rsvp' state={row.original}>
                    User Responses
                </Link>
            );
        },
    };

    return (
        <>
            <Table
                endpoints={endpoint}
                // name={viewProps.EventCalendarNew.name}
                {...viewProps.EventCalendarNew}
                cellModifier={cellModifier}
                {...fetchUtils}
            />
        </>
    );
}

export default withAlert(EventCalendar);
