import React from 'react';
import { Col, Row } from 'react-bootstrap';
import endpoints from '../../endpoints';
import { useAxiosGetMultiple } from '../../hooks/axiosHooks';
import Chip from '../dashboard/Chip';
import ClassRepBloodDonor from './ClassRepBloodDonor';
import Loader from '../../components/Loader';
import EventAnnouncement from './EventAnnouncement';
import HelpJobRequest from './HelpJobRequest';
import MarkAsSafe from './MarkAsSafe';
import { useAuthenticationState } from '../../context/Auth.context';
import UseToggleRefetch from '../../helpers/toggleRefetch';

function Dashboard() {
    const { user } = useAuthenticationState();
    const { response, loading, reFetch } = useAxiosGetMultiple({
        unsafeResponse: endpoints.studentCouncilOverview.unsafeResponse,
        helpRequestsToApprove:
            endpoints.studentCouncilOverview.helpRequestsToApprove,
        jobrequestsToApprove:
            endpoints.studentCouncilOverview.jobrequestsToApprove,
        totalStudentCount: endpoints.studentCouncilOverview.totalStudentCount,
        inActiveStudentCount:
            endpoints.studentCouncilOverview.inActiveStudentCount,
        batchCount: endpoints.studentCouncilOverview.batchCount,
        // basics: endpoints.statistics.studentCouncilOverView,
        bloodDonor: endpoints.statistics.bloodDonor,
        classRep: endpoints.statistics.classRepList + '?page=1&limit=1000',
        markAsSafe:
            endpoints.statistics.studentCouncilMarkAsSafe +
            '?page=1&limit=1000',
        alumniJobIndustry: endpoints.statistics.alumniJobIndustry,
        alumniJobOrg: endpoints.statistics.alumniJobOrg,
        entreByIndustry: endpoints.statistics.entreByIndustry,
        alumniPerYear: endpoints.statistics.alumniPerYear,
        cityChapterInclusion: endpoints.statistics.cityChapterInclusion,
        events:
            endpoints.statistics.studentCouncilEvents + '?page=1&limit=1000',
        announcements:
            endpoints.statistics.studentCouncilAnnouncements +
            '?page=1&limit=1000',
        helpRequests:
            endpoints.statistics.studentCouncilHelpRequests +
            '?page=1&limit=1000',
        jobRequests:
            endpoints.statistics.studentCouncilJobRequests +
            '?page=1&limit=1000',
    });
    UseToggleRefetch(reFetch);
    const {
        basics,
        classRep,
        markAsSafe,
        // cityChapterInclusion,
        alumniJobIndustry,
        alumniJobOrg,
        events,
        announcements,
        helpRequests,
        jobRequests,
        bloodDonor,
        unsafeResponse,
        helpRequestsToApprove,
        jobrequestsToApprove,
        totalStudentCount,
        inActiveStudentCount,
        batchCount,
    } = response;
    // const classRepFilter = classRep.filter((data) => {
    //     return data.batch_code === user.DELEGATE_BATCH_CODE;
    // });
    console.log(bloodDonor, 'donor sc');
    if (loading) {
        return <Loader />;
    }
    return (
        <>
            <div
                style={{
                    fontSize: '20px',
                    color: '#000',
                    marginBottom: '25px',
                }}
            >
                Course Overview
            </div>
            <Row>
                <Col md='8'>
                    <Row>
                        <Col md='4'>
                            <Chip bg='#FAEDC6' title='Unsafe Response 🚨'>
                                {unsafeResponse?.unsafeResponse ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip
                                bg='#DADDFC'
                                title='Help Requests to Approve 🚨'
                            >
                                {helpRequestsToApprove?.helpRequestsToApprove ??
                                    0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#CEE5D0' title='Job Requests to Approve'>
                                {jobrequestsToApprove?.jobRequestsToApprove ??
                                    0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#FAEDC6' title='Total Students in Course'>
                                {totalStudentCount?.totalStudentsCount ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#DADDFC' title='Inactive Students'>
                                {inActiveStudentCount?.inActiveStudentsCount ??
                                    0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#CEE5D0' title='Batch Count'>
                                {batchCount?.batchCount ?? 0}
                            </Chip>
                        </Col>
                    </Row>
                </Col>

                <Col md='4'>
                    <ClassRepBloodDonor
                        memberList={classRep}
                        bloodDonor={bloodDonor.alumniDetails}
                    />
                </Col>

                <Col md='14' className='gy-5'>
                    <MarkAsSafe markAsSafe={markAsSafe} />
                </Col>
                <Col md='14' className='gy-5'>
                    <EventAnnouncement
                        data={{
                            events: events,
                            announcements: announcements,
                        }}
                    />
                </Col>

                <Col md='14' className='gy-5'>
                    <HelpJobRequest
                        data={{
                            helpRequests: helpRequests,
                            jobRequests: jobRequests,
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}

export default Dashboard;
