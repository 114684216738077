import React from 'react';
import Table from '../../components/basicTable/TableFomat';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { Link } from 'react-router-dom';
import { useAuthenticationState } from '../../context/Auth.context';

function MarkAsSafeTable() {
    const fetchUtils = useAxiosGet(endpoints.statistics.studentCouncilMarkAsSafe);

    const cellModifier = {
        Alumni_MarkAsSafe_EventStartDate: ({ value }) =>
            new Date(value).toDateString(),
        Alumni_MarkAsSafe_EventEndDate: ({ value }) => {
            if (value) {
                return new Date(value).toDateString();
            } else {
                return value;
            }
        },
        Alumni_MarkAsSafe_Response: ({ value }) => {
            if (value === 'AT RISK') {
                return <span style={{ color: 'red' }}>{value}</span>;
            }
            else if(value === 'SAFE') {
                return <span style={{ color:'green'}}>{value}</span>
            }
            else {
                return <span style={{color:'red'}}>{value}</span>
            }
        },
    };
    return (
        <>
            <Table
                endpoints={endpoints.statistics.studentCouncilMarkAsSafe}
                {...viewProps.DashboardMarkAsSafe}
                cellModifier={cellModifier}
                // addBtnLabel='Add Event'
                formSize='md'
                {...fetchUtils}
            />
        </>
    );
}

export default MarkAsSafeTable;
