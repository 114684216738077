import React from 'react';
import { Col, Row } from 'react-bootstrap';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { useLocation } from 'react-router-dom';
import Chip from './Chip';

function RSVP() {
    const { state } = useLocation();
    const fetchUtils = useAxiosGet(
        endpoints.rsvp.get + state.Event_CALENDAR_ID
    );

    const cellModifier = {
        IsEmployed: ({ value }) => {
            const types = {
                0: 'No',
                1: 'Yes',
            };
            return types[value];
        },
        IsActive: ({ value }) => {
            const types = {
                0: 'No',
                1: 'Yes',
            };
            return types[value];
        },
        IsEntrepreneur: ({ value }) => {
            const types = {
                0: 'No',
                1: 'Yes',
            };
            return types[value];
        },
        IsLookingForAChange: ({ value }) => {
            const types = {
                0: 'No',
                1: 'Yes',
            };
            return types[value];
        },
        RSVP_STATUS: ({ value }) => {
            const types = {
                3: 'Not Interested',
                1: 'Interested',
                2: 'Maybe',
            };
            return types[value];
        },
    };

    const { response } = fetchUtils;
    let not_intrested_count = 0;
    let intrested_count = 0;
    let may_be_count = 0;

    response.forEach((item) => {
        switch (item.RSVP_STATUS) {
            case 0:
                not_intrested_count++;
                break;
            case 1:
                intrested_count++;
                break;
            case 2:
                may_be_count++;
                break;
            default:
                not_intrested_count++;
                break;
        }
    });

    const data = {
        items: response,
        not_intrested_count: not_intrested_count,
        intrested_count: intrested_count,
        may_be_count: may_be_count,
        total_responses: response.length,
    };

    return (
        <>
            <div
                style={{
                    fontWeight: 500,
                    fontSize: '16px',
                    paddingBottom: '10px',
                }}
            >
                {state.Event_CALENDAR_EVENT_NAME}
            </div>
            <div
                style={{
                    color: '#6C757D',
                    fontSize: '12px',
                    fontWeight: 500,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                RSVP :
                <div
                    style={{
                        color: '#6C757D',
                        fontSize: '12px',
                        fontWeight: 500,
                        paddingLeft: '4px',
                    }}
                >
                    Overall {data.total_responses} User's response
                </div>
            </div>

            <div style={{ paddingTop: '1%', width: '58%', display: 'flex' }}>
                <div style={{ width: '30%' }}>
                    <Chip bg='#F9FFF4' title='Interested'>
                        {data?.intrested_count ?? 0}
                    </Chip>
                </div>
                <div style={{ width: '30%' }}>
                    <Chip bg='#FFF4F4' title='Not Interested'>
                        {data?.not_intrested_count ?? 0}
                    </Chip>
                </div>
                <div style={{ width: '30%' }}>
                    <Chip bg='#FFFDF4' title='Maybe'>
                        {data?.may_be_count ?? 0}
                    </Chip>
                </div>
            </div>
            <CRUDTable
                {...viewProps.RSVP}
                cellModifier={cellModifier}
                insertable={false}
                immutable
                irremovable
                {...fetchUtils}
            />
        </>
    );
}

export default RSVP;
