import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AlumniJobDomain from '../dashboard/AlumniJobDomain';
import endpoints from '../../endpoints';
import Chip from '../dashboard/Chip';
import ECMembers from '../dashboard/ECMembers';
import EntreInd from '../dashboard/EntreInd';
import AlumniGraduated from '../dashboard/AlumniGraduated';
import CityChapterInclusion from '../dashboard/CityChapterInclusion';
import Loader from '../../components/Loader';
import Entrepreneurs from './EntrepreneurBloodDonor';
import MarkAsSafe from './MarkAsSafe';
import EventAnnouncement from './EventAnnouncement';
import HelpJobRequest from './HelpJobRequest';
import { useAxiosGetMultiple } from '../../hooks/axiosHooks';
import UseToggleRefetch from '../../helpers/toggleRefetch';

function Dashboard() {
    const { response, loading, reFetch } = useAxiosGetMultiple({
        totalStudent: endpoints.classRepDash.totalStudent,
        inactiveStudent: endpoints.classRepDash.inactiveStudent,
        studentEnterpreneur: endpoints.classRepDash.studentEnterpreneur,
        totalHelpRequest: endpoints.classRepDash.totalHelpRequest,
        unsafeResponse: endpoints.classRepDash.unsafeResponse,
        batchRepTotalJobRequests: endpoints.batchRep.batchRepTotalJobRequests,
    });
    UseToggleRefetch(reFetch);
    const {
        unsafeMark,
        totalStudent,
        inactiveStudent,
        studentEnterpreneur,
        totalHelpRequest,
        unsafeResponse,
        batchRepTotalJobRequests,
    } = response;
    // const cityChapterInclusion = response.cityChapterInclusion.filter(
    //     (data) => !!data.COUNT_ACTIVE_ALUMNI
    // );

    if (loading) {
        return <Loader />;
    }
    return (
        <>
            <div
                style={{
                    fontSize: '20px',
                    color: '#000',
                    marginBottom: '25px',
                }}
            >
                Class Overview
            </div>
            <Row>
                <Col md='8'>
                    <Row>
                        <Col md='4'>
                            <Chip bg='#FF9A9A' title='Unsafe Response 🚨'>
                                {unsafeResponse.unsafeResponse ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#CEE5D0' title='Total Help Requests 🚨'>
                                {totalHelpRequest.totalHelpRequests ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#C6FAD8' title='Total Job Requests'>
                                {batchRepTotalJobRequests.totalJobRequestsCount ??
                                    0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#FAEDC6' title='Total Students in Batch'>
                                {totalStudent.totalStudentsCount ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip
                                bg='#FAEDC6'
                                title='Inactive Students in Batch'
                            >
                                {inactiveStudent.inActiveStudentsCount ?? 0}
                            </Chip>
                        </Col>
                        <Col md='4'>
                            <Chip bg='#EBF4F6' title='Student Entrepreneurs'>
                                {studentEnterpreneur.entrepreneurCount ?? 0}
                            </Chip>
                        </Col>
                    </Row>
                </Col>

                <Col md='4'>
                    <Entrepreneurs />
                </Col>
                <Col md='14' className='gy-5'>
                    <MarkAsSafe />
                </Col>

                <Col md='14' className='gy-5'>
                    <EventAnnouncement />
                </Col>
                <Col md='14' className='gy-5'>
                    <HelpJobRequest />
                </Col>
            </Row>
        </>
    );
}

export default Dashboard;
