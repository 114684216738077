import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import ModalWrapper from '../../components/ModalWrapper';
import PostWithWarning from '../../components/PostWithWarning';
import { ReactComponent as Approve } from '../../asset/icons/Approve.svg';
import { ReactComponent as Reject } from '../../asset/icons/Block.svg';
import { useAuthenticationState } from '../../context/Auth.context';
import StatusIndicator from '../../components/StatusIndicator';
import withAlert from '../../components/withAlert';

function AlumniLookingForJob({ showAlert }) {
    const { user } = useAuthenticationState();

    let getApi = endpoints.alumniLookingForJob.get;

    if (
        [
            'DELEGATE',
            'DELEGATE + CCH',
            'STUDENTCOUNCIL',
            'CLASSREPRESENTATIVE',
            'CLASSREP + STUDENT COUNCIL',
        ].includes(user.Role)
    ) {
        getApi =
            endpoints.alumniLookingForJob.delegate + user.DELEGATE_BATCH_CODE;
    }

    const fetchUtils = useAxiosGet(getApi);

    const cellModifier = {
        Approve: ({ row }) => (
            <ModalWrapper
                modalTitle={'Approve Job Request ?'}
                modalAttrs={{ size: 'sm' }}
                disabled={row.original.Job_Requirement_Status_Approve !== 3 || row.original?.is_deleted_from_mobile}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                'success',
                                'The job request has been approved'
                            );
                        }}
                        onError={(err) => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                'error',
                                `${err.response.data.statusText} You can't approve this job requirement.` || 'The job requirement has been removed'
                            );
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.alumniLookingForJob.respond}
                        configBody={{
                            Job_Requirement_Status_Approve: '1',
                            ALUMNI_JOB_CODE: row.original.ALUMNI_JOB_CODE,
                            CREATEDBY: row.original.CreatedBy,
                            NOTIFICATION_CONTENT:
                                'Job request has been approved',
                            JobRequirementDetails:
                                row.original.JobRequirementDetails,
                            DomainLookout: row.original.DomainLookout,
                        }}
                        successVarient='success'
                        dangerVarient='danger'
                    />
                )}
            >
                <span
                    type='button'
                    className='ps-3'
                >
                    <Approve />
                </span>
            </ModalWrapper>
        ),
        Reject: ({ row }) => (
            <ModalWrapper
                modalTitle={'Block Job Request?'}
                modalAttrs={{ size: 'sm' }}
                disabled={row.original.Job_Requirement_Status_Approve !== 3 || row.original?.is_deleted_from_mobile}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                'success',
                                'The job request has been rejected'
                            );
                        }}
                        onError={(err) => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                'error',
                                `${err.response.data.statusText} You can't reject this job requirement.` || 'The job requirement has been removed'
                            );
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.alumniLookingForJob.respond}
                        configBody={{
                            Job_Requirement_Status_Approve: '2',
                            ALUMNI_JOB_CODE: row.original.ALUMNI_JOB_CODE,
                            CREATEDBY: row.original.CreatedBy,
                            NOTIFICATION_CONTENT:
                                'Job request has been rejected',
                            JobRequirementDetails:
                                row.original.JobRequirementDetails,
                            DomainLookout: row.original.DomainLookout,
                        }}
                    // successVarient='success'
                    // dangerVarient='danger'
                    />
                )}
            >
                <span
                    type='button'
                    className='ps-3'
                >
                    <Reject />
                </span>
            </ModalWrapper>
        ),
        OpenToDomainChange: ({ value }) => {
            if (Number(value) === 2) {
                return 'No';
            }
            if (Number(value) === 1) {
                return 'Yes';
            }
            return value;
        },
        Job_Requirement_Status_Approve: ({ value }) => {
            const status = {
                1: { tag: 'Approved', variant: 'bg-success' },
                2: { tag: 'Rejected', variant: 'bg-danger' },
                3: { tag: 'Not Responded', variant: 'bg-warning' },
            };
            return (
                <StatusIndicator
                    variant={status[value]?.variant || 'bg-warning'}
                    status={status[value]?.tag || 'Not Responded'}
                />
            );
        },
        OpenToRelocation: ({ value }) => {
            if (Number(value) === 2) {
                return 'No';
            }
            if (Number(value) === 1) {
                return 'Yes';
            }
            return value;
        },
        OpenTohaveProfileSharedToExternalRecruiters: ({ value }) => {
            if (Number(value) === 2) {
                return 'No';
            }
            if (Number(value) === 1) {
                return 'Yes';
            }
            return value;
        },
        IsJobRequirementStatusActive: ({ row }) => {
            const value=row?.original?.is_deleted_from_mobile&&2||row?.original?.Job_Requirement_Status_Approve;
            const status = {
                2: { tag: `Inactive`, variant: 'bg-danger' },
                1: { tag: 'Active', variant: 'bg-success' },
            };
            return (
                <StatusIndicator
                    variant={status[value]?.variant || 'bg-success'}
                    status={status[value]?.tag || 'Active'}
                />
            );
            
        },
        ContactPreference: ({ value }) => {
            if (Number(value) === 2) {
                return 'No';
            }
            if (Number(value) === 1) {
                return 'Yes';
            }
            return value;
        },
        is_deleted_from_mobile: ({ value, row }) => {
            const status = {
                1: { tag: `Removed by ${row.original?.ALUMNI_Name}`, variant: 'bg-secondary' },
                0: { tag: 'Not Deleted', variant: 'bg-info' },
            };
            return (
                <StatusIndicator
                    variant={status[value]?.variant || 'bg-warning'}
                    status={status[value]?.tag || 'Not Responded'}
                />
            );
        },
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.alumniLookingForJob}
                name={viewProps.AlumniLookingForJob.name}
                relevants={[
                    ...(viewProps.AlumniLookingForJob.relevants || []),
                    ...(![
                        'STUDENTCOUNCIL',
                        'CLASSREPRESENTATIVE',
                        'CLASSREP + STUDENT COUNCIL',
                    ].includes(user.Role)
                        ? ['Approve', 'Reject']
                        : []),
                ]}
                columnHeads={[
                    ...(viewProps.AlumniLookingForJob.columnHeads || []),
                    ...(![
                        'STUDENTCOUNCIL',
                        'CLASSREPRESENTATIVE',
                        'CLASSREP + STUDENT COUNCIL',
                    ].includes(user.Role)
                        ? ['Approve', 'Reject']
                        : []),
                ]}
                cellModifier={cellModifier}
                // irremovable
                insertable={false}
                immutable
                irremovable={[
                    'STUDENTCOUNCIL',
                    'CLASSREPRESENTATIVE',
                    'CLASSREP + STUDENT COUNCIL',
                ].includes(user.Role)}
                {...fetchUtils}
            />
        </>
    );
}

export default withAlert(AlumniLookingForJob);
