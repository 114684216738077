import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import GoverninCouncilForm from './GoverninCouncilForm';
import { useAuthenticationState } from '../../context/Auth.context';

function GoverningCouncil() {
    const { user } = useAuthenticationState();

    const fetchUtils = useAxiosGet(endpoints.governingCouncil.get);

    const cellModifier = {
        EC_Member_Elected_Date: ({ value }) => new Date(value).toDateString(),
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.governingCouncil}
                {...viewProps.GoverningCouncil}
                cellModifier={cellModifier}
                Form={GoverninCouncilForm}
                {...fetchUtils}
                addBtnLabel='Add Member'
                irremovable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}
                immutable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}
                insertable={user.Role === 'ADMIN' || user.Role === 'GCM'}
                // immutable={!['DELEGATE + CCH', 'DELEGATE'].includes(user.Role)}
                // insertable={!['DELEGATE + CCH', 'DELEGATE'].includes(user.Role)}
            />
        </>
    );
}

export default GoverningCouncil;
