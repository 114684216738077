import { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';

function NewMenu({ routes }) {
    const [activeRoute, setActiveRoute] = useState('');
    const { pathname } = useLocation();

    useEffect(() => {
        const currentRoute = routes.find(
            (route) => pathname === '/' + route.path
        );
        if (currentRoute) {
            setActiveRoute(currentRoute.path);
        } else {
            setActiveRoute(pathname);
        }
    }, [pathname, routes]);

    const links = routes
        .filter((route) => !route.hiddenFromMenu)
        .map(({ path, name, text }) => (
            <NavLink
                key={path}
                to={'/' + path}
                className={({ isActive }) =>
                    `${
                        isActive ? 'bg-primary text-white' : 'text-dark'
                    } d-block my-1 p-2 rounded-smooth`
                }
            >
                <div className='d-flex align-items-center'>
                    <div className='ps-2'>{text || name}</div>
                </div>
            </NavLink>
        ));

    return (
        <Accordion flush>
            {links.map((link, i) => (
                <Accordion.Item
                    eventKey={i}
                    key={i}
                    className='bg-transparent border-0'
                >
                    {link}
                </Accordion.Item>
            ))}
        </Accordion>
    );
}

export default NewMenu;
