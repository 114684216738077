import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import endpoints from '../../endpoints';
import axios from 'axios';
import FormikControl from '../../components/formikControl/FormikControl';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';
import { Collapse } from 'react-bootstrap';

function BatchHeadForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
    updateValues,
}) {
    const [batches, setBatches] = useState([]);
    const [alumni, setBatchAlumni] = useState([]);
    const [loading, setLoading] = useState(!updateValues);
    const [loadingAlumni, setLoadingAlumni] = useState(false);

    const getBatchAlumni = (batchCode) => {
        setLoadingAlumni(true);

        return axios.get(endpoints.alumniDetail.getBatchAlumni + batchCode);
    };

    useEffect(() => {
        if (!updateValues) {
            setLoading(true);

            axios
                .get(endpoints.batch.get)
                .then((res) => {
                    setBatches(res.data.responseData);
                })
                .catch((err) =>
                    showAlert(
                        'error',
                        err.response.data?.statusText || 'Something went wrong'
                    )
                )
                .finally(() => setLoading(false));
        } else {
            getBatchAlumni(updateValues.BATCH_CODE)
                .then((res) => {
                    setBatchAlumni(res.data.responseData);
                })
                .catch((err) =>
                    showAlert(
                        'error',
                        err.response.data?.statusText || 'Something went wrong'
                    )
                )
                .finally(() => {
                    setLoadingAlumni(false);
                });
        }
    }, []);

    const submitHandler = (values, { setSubmitting }) => {
        const body = { ...values };
        axios
            .post(endpoint, body)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            })
            .finally((res) => setSubmitting(false));
    };

    const initialFormValues = updateValues || {
        Delegate_UserId: '',
        BATCH_CODE: '',
    };

    if (loading) {
        return <Loader />;
    }

    const preSelectedOption = {};

    if (updateValues) {
        updateValues.OldDelegateUserId = updateValues.Delegate_UserId;

        preSelectedOption.alumni = alumni.find(
            (alumni) =>
                Number(alumni.ALUMNI_BATCH_ADM_CODE) ===
                Number(initialFormValues.Delegate_UserId)
        );
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                BATCH_CODE: Yup.string().required(),
                Delegate_UserId: Yup.string().required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    {!updateValues && (
                        <FormikControl
                            control='select-advanced'
                            required
                            options={batches}
                            optionValue='BATCH_CODE'
                            getOptionLabel={(label) =>
                                label.BATCH_NAME + ' ' + label.COURSE_NAME
                            }
                            onChange={(selected) => {
                                setFieldValue(
                                    'BATCH_CODE',
                                    selected.BATCH_CODE
                                );
                                setFieldValue('Delegate_UserId', '');

                                setBatchAlumni([]);

                                if (selected.BATCH_CODE) {
                                    getBatchAlumni(selected.BATCH_CODE)
                                        .then((res) => {
                                            setBatchAlumni(
                                                res.data.responseData
                                            );
                                        })
                                        .catch((err) => {
                                            showAlert(
                                                'error',
                                                err.response.data?.statusText ||
                                                    'Something went wrong'
                                            );
                                        })
                                        .finally(() => {
                                            setLoadingAlumni(false);
                                        });
                                }
                            }}
                            optionLabel='BATCH_NAME'
                            label='BATCH'
                            name='BATCH_CODE'
                        />
                    )}
                    {values.BATCH_CODE && !loadingAlumni && (
                        <FormikControl
                            control='select-advanced'
                            required
                            name='Delegate_UserId'
                            label='ALUMNI'
                            options={alumni}
                            optionValue='ALUMNI_BATCH_ADM_CODE'
                            defaultValue={preSelectedOption.alumni}
                            optionLabel='ALUMNI_Name'
                            disabled={loadingAlumni}
                        />
                    )}
                    <Collapse in={loadingAlumni}>
                        <div
                            className='text-danger bg-white p-1 mt-2 text-center rounded'
                            style={{ fontSize: '12px' }}
                        >
                            Loading batch alumni
                        </div>
                    </Collapse>
                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(BatchHeadForm);
