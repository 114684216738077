import React from 'react';
import { Button } from 'react-bootstrap';

function FormSubmissionBtn({ onCancel }) {
    return (
        <>
            <div className='text-center py-4'>
                <Button
                    className='me-4 px-4 py-1 border'
                    variant='light'
                    onClick={onCancel}
                >
                    <span className='small'>Cancel</span>
                </Button>
                <Button className='px-4 py-1 text-white' type='submit'>
                    <span className='small'>Submit</span>
                </Button>
            </div>
        </>
    );
}

export default FormSubmissionBtn;
