import React from 'react';
import { Card } from 'react-bootstrap';

function Chip({ bg, title, children }) {
    return (
        <Card bg='transparent' className='text-center border-0 mb-3'>
            <div className='small h6 text-dark'>{title}</div>
            <div
                className='p-4 fs-3 my-3 card-shadow'
                style={{
                    background: bg,
                    borderRadius: ' 20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '30px',
                    marginLeft: '30px',
                    color: '#272727E5',
                }}
            >
                {children}
            </div>
        </Card>
    );
}

export default Chip;
