import React from 'react';
import Table from '../../components/basicTable/TableFomat';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { Link } from 'react-router-dom';
import { useAuthenticationState } from '../../context/Auth.context';
import ModalWrapper from '../../components/ModalWrapper';
import PostWithWarning from '../../components/PostWithWarning';
import { ReactComponent as Approve } from '../../asset/icons/Approve.svg';
import { ReactComponent as Reject } from '../../asset/icons/Block.svg';
import StatusIndicator from '../../components/StatusIndicator';
import withAlert from '../../components/withAlert';

function EventCalendar({ showAlert }) {
    const { user } = useAuthenticationState();

    const fetchUtils = useAxiosGet(endpoints.eventCalendar.get);

    console.log(fetchUtils, 'event');

    const cellModifier = {
        Event_CALENDAR_TYPE: ({ value }) => {
            switch (Number(value)) {
                case 1:
                    return 'Institution Initiated';
                case 2:
                    return 'IIM ALUMNI Initiated';
                case 3:
                    return 'Individual Initiated';

                default:
                    return value;
            }
        },
        Event_Event_Type: ({ value }) => {
            switch (Number(value)) {
                case 1:
                    return 'Ground Event';
                case 2:
                    return 'Webinar';
                case 3:
                    return 'Seminar';
                case 4:
                    return 'Virtual Event';
                case 5:
                    return 'Club Event';
                case 6:
                    return 'Org Event';
                case 7:
                    return 'Meetup';
                case 8:
                    return 'Charity';
                case 9:
                    return 'Alumni Event';
                case 10:
                    return 'IIMK';

                default:
                    return value;
            }
        },
        Event_CALENDAR_EVENT_STATUS: ({ value }) => {
            if (Number(value) === 2) {
                return 'Inactive';
            } else if (Number(value) === 1) {
                return 'Active';
            }
            return 'Inactive';
        },
        Approve: ({ row }) => (
            <ModalWrapper
                modalTitle={'Approve Event ?'}
                modalAttrs={{ size: 'sm' }}
                disabled={row.original.Event_Status_Approve !== 3}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert('success', 'The event has been approved');
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.eventCalendar.respond}
                        configBody={{
                            Event_Status_Approve: '1',
                            Event_CALENDAR_ID: row.original.Event_CALENDAR_ID,
                            CREATEDBY: row.original.CREATEDBY,
                            NOTIFICATION_CONTENT: 'Event Approved',
                            Event_CALENDAR_EVENT_NAME:
                                row.original.Event_CALENDAR_EVENT_NAME,
                        }}
                        successVarient='success'
                        dangerVarient='danger'
                    />
                )}
            >
                <span type='button' className='ps-3'>
                    <Approve />
                </span>
            </ModalWrapper>
        ),
        Reject: ({ row }) => (
            <ModalWrapper
                modalTitle={'Block Event ?'}
                modalAttrs={{ size: 'sm' }}
                disabled={row.original.Event_Status_Approve !== 3}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert('success', 'The event has been rejected');
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.eventCalendar.respond}
                        configBody={{
                            Event_Status_Approve: '2',
                            CREATEDBY: row.original.CREATEDBY,
                            Event_CALENDAR_ID: row.original.Event_CALENDAR_ID,
                            NOTIFICATION_CONTENT: 'Event Rejected',
                            Event_CALENDAR_EVENT_NAME:
                                row.original.Event_CALENDAR_EVENT_NAME,
                        }}
                        // successVarient='success'
                        // dangerVarient='danger'
                    />
                )}
            >
                <span type='button' className='ps-3'>
                    <Reject />
                </span>
            </ModalWrapper>
        ),
        Event_Status_Approve: ({ value }) => {
            const status = {
                1: { tag: 'Approved', variant: 'bg-success' },
                2: { tag: 'Rejected', variant: 'bg-danger' },
                3: { tag: 'Not Responded', variant: 'bg-warning' },
            };
            return (
                <StatusIndicator
                    variant={status[value]?.variant || 'bg-warning'}
                    status={status[value]?.tag || 'Not Responded'}
                />
            );
        },
        Event_CALENDAR_SCHEDULE_STARTDATE: ({ value }) => {
            return new Date(value).toDateString();
        },
        Event_CALENDAR_SCHEDULE_ENDDATE: ({ value }) => {
            return new Date(value).toDateString();
        },
        Event_CALENDAR_SCHEDULE_STARTTIME: ({ value }) => {
            return new Date(value).toLocaleTimeString('en', {
                timeStyle: 'short',
            });
        },
        Event_CALENDAR_SCHEDULE_ENDTIME: ({ value }) => {
            return new Date(value).toLocaleTimeString('en', {
                timeStyle: 'short',
            });
        },
        RSVP: ({ row }) => {
            return (
                <Link to='/rsvp' state={row.original}>
                    User Responses
                </Link>
            );
        },
    };

    return (
        <>
            <Table
                // endpoints={endpoint}
                name={viewProps.EventCalendar.name}
                relevants={[
                    ...(viewProps.EventCalendar.relevants || []),
                    ...(![
                        'STUDENTCOUNCIL',
                        'CLASSREPRESENTATIVE',
                        'CLASSREPRESENTATIVE+STUDENTCOUNCIL',
                    ].includes(user.Role)
                        ? ['Approve', 'Reject']
                        : []),
                ]}
                columnHeads={viewProps.EventCalendar.columnHeads}
                cellModifier={cellModifier}
                {...fetchUtils}
            />
        </>
    );
}

export default withAlert(EventCalendar);
