import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { Link } from 'react-router-dom';
import MarkAsSafeForm from './MarkAsSafeForm';

function MarkAsSafe() {
    const fetchUtils = useAxiosGet(endpoints.scmarkAsSafe.get);
    const cellModifier = {
        Response: ({ row }) => (
            <Link
                to={'/safety-response'}
                state={row.original.Alumni_MarkAsSafe_EventID}
            >
                User Responses
            </Link>
        ),
        Alumni_MarkAsSafe_EventStartDate: ({ value }) =>
            new Date(value).toDateString(),
        Alumni_MarkAsSafe_EventEndDate: ({ value }) => {
            if (value) {
                return new Date(value).toDateString();
            } else {
                return value;
            }
        },
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.scmarkAsSafe}
                Form={MarkAsSafeForm}
                {...viewProps.MarkAsSafe}
                cellModifier={cellModifier}
                addBtnLabel='Add Event'
                formSize='md'
                {...fetchUtils}
                immutable
            />
        </>
    );
}

export default MarkAsSafe;
