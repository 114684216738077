import React from 'react';
import { Card } from 'react-bootstrap';

function Chip({ bg, title, children }) {
    return (
        <Card
            bg='transparent'
            className='text-center border-0 mb-3'
        >
            <div
                className='small h6 text-dark'
                style={{ textAlign: 'left', fontSize: '14px' }}
            >
                {title}
            </div>
            <div
                className=' fs-3 my-3 card-shadow'
                style={{
                    background: bg,
                    borderRadius: ' 20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '30px',
                    marginLeft: '0',
                    color: '#272727E5',
                    padding: '12%',
                    border: '1px solid #E3E3E3',
                }}
            >
                {children}
            </div>
        </Card>
    );
}

export default Chip;
