import React from 'react';
import Table from '../../components/basicTable/TableFomat';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';

function BasicTable({ fetchUtils, cellModifier, name }) {
    return (
        <Table
            {...name}
            cellModifier={cellModifier}
            formSize='md'
            {...fetchUtils}
        />
    );
}

export default BasicTable;
