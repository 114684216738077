import React from 'react';
import { ReactComponent as Download } from '../asset/icons/Download.svg';
import { Button } from 'react-bootstrap';
import ReactExport from 'react-export-excel-xlsx-fix';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DownloadExcelWithWantedColumns = (props) => {
    const {
        downloadFileName,
        relevants,
        unwantedRelevant = [],
        columnHeads = [],
        response,
        keyInArrayTypeColumn = '',
        isAlumniTable = false,
    } = props;

    const ensureString = (value) => {
        if (value === null || value === undefined) {
            return '';
        }
        if (Array.isArray(value)) {
            return value
                .map((item) => ensureString(item[keyInArrayTypeColumn]))
                .join(', ');
        }
        return String(value);
    };

    const ensureStringAlumni = (row, relevant) => {
        const value = row[relevant];
        if (value === null) {
            return '';
        }
        if (relevant === 'courses' || value === undefined) {
            return row?.BATCH_CODE?.map((item) =>
                ensureString(item?.COURSE_NAME)
            ).join(', ');
        }
        if (Array.isArray(value)) {
            return value
                .map((item) => ensureString(item[keyInArrayTypeColumn]))
                .join(', ');
        }
        return String(value);
    };

    return (
        <ExcelFile
            element={
                <Button className="primaryBtn btnAnime ms-4 px-3 py-1">
                    <Download />
                </Button>
            }
            filename={downloadFileName}
        >
            <ExcelSheet
                data={response}
                name={downloadFileName.length > 30 ? 'Data' : downloadFileName}
            >
                {relevants
                    .filter((item) => !unwantedRelevant.includes(item))
                    .map((relevant, index) => (
                        <ExcelColumn
                            key={index}
                            label={
                                columnHeads?.[index] || 'Missing column head'
                            }
                            // value={relevant}
                            value={(row) =>
                                !isAlumniTable
                                    ? ensureString(row[relevant])
                                    : ensureStringAlumni(row, relevant)
                            }
                        />
                    ))}
            </ExcelSheet>
        </ExcelFile>
    );
};

export default DownloadExcelWithWantedColumns;
