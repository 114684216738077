import React from 'react';
import Avatar from '../../asset/img/Avatar.png';

const Entrepreneur = ({ entreprenerList }) => {
    const wrapperStyle = {
        borderRadius: '12px',
        overflow: 'hidden',
        border: '1px solid #ddd',
    };

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };

    const thTdStyle = {
        border: '1px solid #ddd',
        padding: '8px',
    };

    const thStyle = {
        ...thTdStyle,
        backgroundColor: '#f2f2f2',
    };

    const appStyle = {
        textAlign: 'center',
        marginTop: '20px',
    };

    const tbodyWrapperStyle = {
        maxHeight: '200px',
        overflowY: 'scroll',
        display: 'block',
        width: '100%',
    };

    const tableHeaderStyle = {
        display: 'table',
        width: '100%',
        tableLayout: 'fixed',
    };

    const tableBodyStyle = {
        display: 'table',
        width: '100%',
        tableLayout: 'fixed',
    };

    return (
        <div style={appStyle}>
            <div style={wrapperStyle}>
                <table style={tableStyle}>
                    <thead style={tableHeaderStyle}>
                        <tr>
                            <th style={{ ...thStyle, color: '#467AFF' }}>
                                Name
                            </th>
                            <th style={{ ...thStyle, color: '#467AFF' }}>
                                Contact Number
                            </th>
                        </tr>
                    </thead>
                </table>
                <div style={tbodyWrapperStyle}>
                    <table style={tableStyle}>
                        <tbody style={tableBodyStyle}>
                            {entreprenerList?.map((contact, index) => (
                                <tr key={index}>
                                    <td style={thTdStyle}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                            }}
                                        >
                                            <img
                                                src={
                                                    contact.ALUMNI_IMAGE_URL ||
                                                    Avatar
                                                }
                                                alt='Avatar'
                                                className='rounded-circle shadow-sm'
                                                style={{
                                                    border: '3px solid white',
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                                width='30'
                                            />
                                            <span
                                                style={{
                                                    fontSize: '14px',
                                                    paddingLeft: '2%',
                                                }}
                                            >
                                                {contact.ALUMNI_Name}
                                            </span>
                                        </div>
                                    </td>
                                    <td style={thTdStyle}>
                                        {contact.PHONE_NUMBER}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Entrepreneur;
