import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { useLocation } from 'react-router-dom';

function SafetyResponse() {
    const fetchUtils = useAxiosGet(endpoints.safetyResponse.get);
    const { state } = useLocation();
    let response = [];
    if (state) {
        response = fetchUtils.response.filter(
            (res) => Number(res.Alumni_MarkAsSafe_EventID) === Number(state)
        );
    } else {
        response = fetchUtils.response;
    }
    const options = { month: 'long', day: 'numeric' };

    const cellModifier = {
        DateOfBirth: ({ value }) => {
            if (!value) return '';
            const [month, day] = value.split('-').map(Number);
            const date = new Date();
            date.setMonth(month - 1);
            date.setDate(day);
            return date.toLocaleDateString('en-US', options);
        },
        // DateOfBirth: ({ value }) => new Date(value).toDateString(),
    };
    return (
        <>
            <CRUDTable
                {...viewProps.SafetyResponse}
                cellModifier={cellModifier}
                insertable={false}
                immutable
                irremovable
                {...fetchUtils}
                response={response}
            />
        </>
    );
}

export default SafetyResponse;
