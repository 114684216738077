import axios from 'axios';
import { Form, Formik } from 'formik';
import FormikControl from '../../components/formikControl/FormikControl';
import ModalWrapper from '../../components/ModalWrapper';
import endpoints from '../../endpoints';
import * as Yup from 'yup';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';

function ForgotPassword({ showAlert }) {
    function forgotPassword(values, setSubmitting, closeModal) {
        axios
            .post(endpoints.authentication.forgotPassword, {
                Email: values.email,
            })
            .then(() => {
                showAlert(
                    'success',
                    'Login instructions has been sent to your mail'
                );
                closeModal();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            })
            .finally((res) => setSubmitting(false));
    }

    return (
        <>
            <ModalWrapper
                modalTitle={'Forgot password'}
                modalAttrs={{ size: 'sm' }}
                renderModalBody={(closeModal) => (
                    <Formik
                        onSubmit={(values, { setSubmitting }) => {
                            forgotPassword(values, setSubmitting, closeModal);
                        }}
                        initialValues={{ email: '' }}
                        validationSchema={Yup.object({
                            email: Yup.string().email().required(),
                        })}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <FormikControl
                                    label='Email'
                                    required
                                    name='email'
                                />
                                <FormSubmissionBtn onCancel={closeModal} />
                                {isSubmitting && <Loader />}
                            </Form>
                        )}
                    </Formik>
                )}
            >
                <div className='small mt-2'>
                    <span
                        type='button'
                        className='text-decoration-none'
                        style={{ color: '#396FFA', fontWeight: '500' }}
                    >
                        Forgot Password?
                    </span>
                </div>
            </ModalWrapper>
        </>
    );
}

export default withAlert(ForgotPassword);
