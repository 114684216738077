import React, { useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { ReactComponent as EyeOpened } from '../asset/icons/EyeOpened.svg';
import { ReactComponent as EyeClosed } from '../asset/icons/EyeClosed.svg';

function PasswordInput({ ...rest }) {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <InputGroup>
            <FormControl
                {...rest}
                type={showPassword ? 'text' : 'password'}
                style={{
                    borderRight: 'none',
                    background: '#F2F2F2',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                }}
            />
            <InputGroup.Text
                onClick={() => {
                    setShowPassword((prev) => !prev);
                }}
                style={{
                    background: '#F2F2F2',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                }}
                type='button'
            >
                {showPassword ? <EyeOpened /> : <EyeClosed />}
            </InputGroup.Text>
        </InputGroup>
    );
}

export default PasswordInput;
