import React, { useState } from 'react';

function FilterButtonGroup({ onFilterChange }) {
    const [selectedFilter, setSelectedFilter] = useState('All');

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        onFilterChange(filter);
    };

    return (
        <div
            className='d-flex justify-content-between'
            // style={{ width: '35%' }}
        >
            {/* <button
                type='button'
                className={`btn ${
                    selectedFilter === 'All'
                        ? 'btn-primary'
                        : 'btn-outline-primary'
                }`}
                onClick={() => handleFilterChange('All')}
            >
                All
            </button> */}
            <button
                type='button'
                className={`btn ${
                    selectedFilter === 'Unsafe'
                        ? 'btn-primary'
                        : 'btn-outline-primary'
                }`}
                onClick={() => handleFilterChange('Unsafe')}
            >
                Unsafe 🚨
            </button>
            <button
                type='button'
                className={`btn ${
                    selectedFilter === 'NoResponse'
                        ? 'btn-primary'
                        : 'btn-outline-primary'
                }`}
                onClick={() => handleFilterChange('NoResponse')}
            >
                Not Response 🚫
            </button>
            <button
                type='button'
                className={`btn ${
                    selectedFilter === 'Safe'
                        ? 'btn-primary'
                        : 'btn-outline-primary'
                }`}
                onClick={() => handleFilterChange('Safe')}
            >
                Safe 🟢
            </button>
        </div>
    );
}

export default FilterButtonGroup;
