import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const data = [
    { batch: 'A', industry: 'Engineering', count: 100 },
    { batch: 'A', industry: 'Finance', count: 50 },
    { batch: 'A', industry: 'Architecture', count: 200 },
    { batch: 'B', industry: 'Engineering', count: 150 },
    { batch: 'B', industry: 'Finance', count: 100 },
    { batch: 'B', industry: 'Architecture', count: 200 },
    { batch: 'C', industry: 'Engineering', count: 100 },
    { batch: 'C', industry: 'Finance', count: 200 },
    { batch: 'C', industry: 'Architecture', count: 200 },
    { batch: 'D', industry: 'Engineering', count: 150 },
    { batch: 'D', industry: 'Finance', count: 150 },
    { batch: 'D', industry: 'Architecture', count: 200 },
    { batch: 'E', industry: 'Engineering', count: 100 },
    { batch: 'E', industry: 'Finance', count: 50 },
    { batch: 'E', industry: 'Architecture', count: 250 },
];

const industries = ['Engineering', 'Finance', 'Architecture'];
const colors = {
    Engineering: 'orange',
    Finance: 'grey',
    Architecture: 'green',
};

const batches = ['A', 'B', 'C', 'D', 'E'];

const getBatchData = (batch) => {
    return industries.map((industry) => {
        const entry = data.find(
            (item) => item.batch === batch && item.industry === industry
        );
        return entry ? entry.count : 0;
    });
};

const chartData = {
    labels: batches,
    datasets: industries.map((industry, index) => ({
        label: industry,
        data: batches.map((batch) => getBatchData(batch)[index]),
        backgroundColor: colors[industry],
    })),
};

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Number of Persons per Batch and Industry',
        },
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};

const BarChart = () => {
    return <Bar data={chartData} options={options} />;
};

export default BarChart;
