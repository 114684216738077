import React from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { ReactComponent as Event } from '../../asset/icons/EventLogo.svg';
import BasicTable from './BasicTable';
import { useAxiosGet, useAxiosGetMultiple } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { useAuthenticationState } from '../../context/Auth.context';
import { Link } from 'react-router-dom';

function EventAnnouncement() {
    const { user } = useAuthenticationState();
    const getApi = endpoints.eventCalendar.get;
    // const fetchUtils = useAxiosGet(getApi);
    const getApiAnnounce = endpoints.announcements.get;
    // const fetchUtilsAnnounce = useAxiosGet(getApiAnnounce);

    const { response, loading, reFetch } = useAxiosGetMultiple(
        {
            fetchUtils: getApi,
            fetchUtilsAnnounce: getApiAnnounce
        })
    const fetchUtils = { response: response.fetchUtils, loading: loading, reFetch: reFetch }
    const fetchUtilsAnnounce = { response: response.fetchUtilsAnnounce, loading: loading, reFetch: reFetch }
    const cellModifierEvent = {
        Event_CALENDAR_SCHEDULE_STARTDATE: ({ value }) =>
            new Date(value).toDateString(),
        Event_CALENDAR_SCHEDULE_STARTTIME: ({ value }) =>
            new Date(value).toLocaleTimeString('en', { timeStyle: 'short' }),
        RSVP: ({ row }) => (
            <Link to='/rsvp' state={row.original}>
                <span style={{ textDecoration: 'underline', color: '#0D8D1A' }}>
                    View RSVP Details
                </span>
            </Link>
        ),
        Event_CALENDAR_BROADCAST_TYPE: ({ value }) => {
            const obj = {
              1: "ALL",
              2: "Batch",
              3: "Course",
            };
            return obj[Number(value)] || "No Broadcast Type";
          },
          // switch (Number(value)) {
          //     case 1:
          //         return 'ALL';
          //     case 2:
          //         return 'Batch';
          //     case 3:
          //         return 'Course';
          //     default:
          //         return value;
          // }
        };

    const cellModifierAnnounce = {
        BATCH_NAME: ({ value }) => (value === 'N/A' ? 'ALL' : value),
        Alumni_AnnouncmentReq_BROADCAST_TYPE_ID: ({ value }) => {
            switch (Number(value)) {
                case 1:
                    return 'ALL';
                case 2:
                    return 'Batch';
                case 3:
                    return 'Course';
                default:
                    return value;
            }
        },
        Alumni_AnnouncmentReq_RESPONSE_ID: ({ value }) => {
            const status = {
                1: { tag: 'Approved', color: 'green' },
                2: { tag: 'Rejected', color: 'red' },
                3: { tag: 'Not Responded', color: 'orange' },
            };
            return (
                <span style={{ color: status[Number(value)]?.color || 'orange' }}>
                    {status[Number(value)]?.tag || 'Not Responded'}
                </span>
            );
        },
        Alumni_AnnouncmentReq_ExpiryDate: ({ value }) =>
            new Date(value).toLocaleDateString(),
    };

    if (fetchUtils.isLoading || fetchUtilsAnnounce.isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Tab.Container defaultActiveKey='Event'>
            <Row className='px-2'>
                <Col className='d-flex'>
                    <Event />
                    <Col className='px-2'>
                        <Tab.Content>
                            <Tab.Pane eventKey='Event' title='Event'>
                                <div className='small h6 text-dark'>
                                    Event Details
                                </div>
                                <div
                                    className='text-muted'
                                    style={{ fontSize: '.75em' }}
                                >
                                    Based on Batch | Total{' '}
                                    {fetchUtils.response?.length || 0} Events
                                </div>
                            </Tab.Pane>
                            <Tab.Pane
                                eventKey='Announcements'
                                title='Announcements'
                            >
                                <div className='small h6 text-dark'>
                                    Announcements Details
                                </div>
                                <div
                                    className='text-muted'
                                    style={{ fontSize: '.75em' }}
                                >
                                    Based on Batch | Total{' '}
                                    {fetchUtilsAnnounce.response?.length || 0}{' '}
                                    Announcements
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Col>
                <Col md='5'>
                    <Nav variant='pills'>
                        <Nav.Item className='ms-auto'>
                            <Nav.Link
                                type='button'
                                className='p-3 py-1'
                                eventKey='Event'
                            >
                                <small
                                    style={{
                                        fontSize: '11px',
                                        lineHeight: '27px',
                                    }}
                                >
                                    Events ({fetchUtils.response?.length || 0})
                                </small>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                type='button'
                                className='p-3 py-1'
                                eventKey='Announcements'
                            >
                                <small
                                    style={{
                                        fontSize: '11px',
                                        lineHeight: '27px',
                                    }}
                                >
                                    Announcements (
                                    {fetchUtilsAnnounce.response?.length || 0})
                                </small>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            <Tab.Content className='rounded-smoother mt-3 card-shadow'>
                <Tab.Pane eventKey='Event'>
                    <BasicTable
                        fetchUtils={fetchUtils}
                        cellModifier={cellModifierEvent}
                        name={viewProps.EventCalendarCCH}
                    />
                </Tab.Pane>
                <Tab.Pane eventKey='Announcements'>
                    <BasicTable
                        fetchUtils={fetchUtilsAnnounce}
                        cellModifier={cellModifierAnnounce}
                        name={viewProps.AnnouncementsNew}
                    />
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    );
}

export default EventAnnouncement;
