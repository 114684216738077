import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuthenticationState } from '../../context/Auth.context';
import Select from 'react-select';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#e8f0fe',
        marginTop: '5px',
        borderRadius: '8px',
        fontSize: '1rem',
        paddingTop: '2px',
        paddingBottom: '2px',
        border: 'none',
        borderColor: state.isFocused ? 'none' : 'none',
        boxShadow: state.isFocused ? 'none' : 'none', 
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#000' : '#333',
        padding: '10px',
    }),
};


function Toggle(props) {
    console.log(props.institutionID);
    const [userCredential, setUserCredential] = useState({
        INSTITUTION_ID: props.institutionID, 
    });
    const [error, setError] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);

    const { response: institutionsList, loading } = useAxiosGet(
        endpoints.authentication.institutionsList
    );

    const institutionOptions = institutionsList.map((institution) => ({
        value: institution.INSTITUTION_ID,
        label: institution.INSTITUTION_NAME,
    }));

    const { toggle } = useAuthenticationState();
    const navigate = useNavigate();


    const onLogin = () => {
        setLoggingIn(true);

        toggle(userCredential)
            .then((user) => {
              
                if (user.Role?.toLowerCase() === 'superadmin') {
                    setError(true);
                }

                switch (user.Role?.toLowerCase()) {
                    case 'admin':
                    case 'gcm':
                        navigate('/');
                        break;
                    case 'cch':
                        navigate('/cityChapterdashboard');
                        break;
                    case 'delegate + cch':
                        navigate('/cityChapterdashboard');
                        break;
                    case 'institutemember':
                        navigate('/institute-member-dashboard');
                        break;
                    case 'classrepresentative':
                        navigate('/classrepdashboard');
                        break;
                    case 'classrep + student council':
                        navigate('/student-council-dashboard');
                        break;
                    case 'studentcouncil':
                        navigate('/student-council-dashboard');
                        break;
                    case 'delegate':
                        navigate('/batchRep-dashboard');
                        break;
                    default:
                        break;
                }
            })
            .catch(() => {
                setLoggingIn(false);
                setError(true);
            });
    };

    const onChangeHandler = (selected) => {
        setUserCredential({
            ...userCredential,
            INSTITUTION_ID: selected ? selected.value : props.institutionID, 
        });
        
    };

    useEffect(() => {
        if (userCredential.INSTITUTION_ID && institutionsList.length > 0) {
            onLogin();
        }
    }, [userCredential.INSTITUTION_ID, institutionsList]); 

    return (
        <div style={{ width: '15%',paddingLeft:'10px' }}>
            <Form onSubmit={(e) => e.preventDefault()} className="px-2">
                <Select
                    isSearchable
                    // isClearable
                    options={institutionOptions}
                    placeholder={loading ? <span>Loading institutions...</span> : 'Select institution'}
                    onChange={onChangeHandler}
                    styles={customStyles}
                    isLoading={loading}
                    value={institutionOptions.find(
                        (option) => option.value === userCredential.INSTITUTION_ID
                    ) || null} 
                    required
                    
                />
            </Form>
        </div>
    );
}

export default Toggle;
