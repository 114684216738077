import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import CourseForm from './CourseForm';

function Courses() {
    const fetchUtils = useAxiosGet(endpoints.course.get);

    const cellModifier = {
        COURSE_STATUS: ({ value }) => {
            if (Number(value) === 2) {
                return 'Inactive';
            } else if (Number(value) === 1) {
                return 'Active';
            }
            return 'Inactive';
        },
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.course}
                {...viewProps.Courses}
                cellModifier={cellModifier}
                Form={CourseForm}
                {...fetchUtils}
                // irremovable
            />
        </>
    );
}

export default Courses;
