import React from "react";
import CRUDTable from "../../components/table/CRUDTable";
import { useAxiosGet } from "../../hooks/axiosHooks";
import endpoints from "../../endpoints";
import viewProps from "../viewProps";
import CityChapterHeadForm from "./CityChapterHeadForm";

function CityChapterHead() {
    const fetchUtils = useAxiosGet(endpoints.cityChapterHead.get);

    const cellModifier = {
        CCH_Elected_Date:({value}) => new Date(value).toDateString(),
        DCCH_Elected_Date:({value}) => new Date(value).toDateString(),
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.cityChapterHead}
                {...viewProps.CityChapterHead}
                Form = {CityChapterHeadForm}
                cellModifier={cellModifier}
                {...fetchUtils}
            />
        </>
    );
}

export default CityChapterHead;
