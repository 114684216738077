import React from 'react';
import { ReactComponent as Edit } from '../../asset/icons/Edit.svg';
import DeleteWithWarning from '../DeleteWithWarning';
import ModalWrapper from '../ModalWrapper';
import { ReactComponent as SortUp } from '../../asset/icons/SortUp.svg';
import { ReactComponent as SortDown } from '../../asset/icons/SortDown.svg';
import { ReactComponent as Sort } from '../../asset/icons/Sort.svg';

function Table(props) {
    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
        props;

    return (
        <div className='table-responsive'>
            <div
                className='table'
                {...getTableProps()}
                id='table-to-xls'
                style={{ width: '100%' }}
            >
                <div className='thead'>
                    {headerGroups.map((headerGroup) => (
                        <div
                            className='tr'
                            {...headerGroup.getHeaderGroupProps()}
                            style={{
                                border: '1px solid #EFEEFE',
                                background: '#EFEEFE',
                                color: '#467AFF',
                                fontSize: '14px',
                                fontWeight: '600',
                            }}
                        >
                            {headerGroup.headers.map((column) => (
                                <div
                                    className='th'
                                    {...column.getHeaderProps()}
                                    style={{
                                        border: '2px solid #EFEEFE',
                                        textAlign: 'center',
                                    }}
                                >
                                    {column.render('Header')}

                                    {/* <span
                                        className=' px-2'
                                        {...column.getSortByToggleProps()}
                                    >
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <SortUp />
                                            ) : (
                                                <SortDown />
                                            )
                                        ) : (
                                            <Sort />
                                        )}
                                    </span> */}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>

                <div className='tbody pt-2' {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <div
                                className='tr'
                                {...row.getRowProps()}
                                style={{
                                    border: '1px solid #EFEEFE',
                                    textAlign: 'center',
                                }}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <div
                                            className='td'
                                            {...cell.getCellProps()}
                                            style={{
                                                border: '1px solid #EFEEFE',
                                            }}
                                        >
                                            {cell.render('Cell')}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Table;
