import React  from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function CityChapterInclusion({ data }) {

    const data1 = [...data]

    data1.forEach((item) => {
        item.CityChapter_Name = item?.CityChapter_Name?.replace(
            'CITY CHAPTER',
            ''
        );
    });
    
    const graphData = {
        labels: data1.map((chap) => chap.CityChapter_Name),
        datasets: [
            {
                label: 'Linked with city chapter',
                data: data1.map((chap) => chap.LINKED_ALUMNI_BY_CITY),
                backgroundColor: '#0096ff',
                borderWidth: 0,
            },
            {
                label: 'Not linked with city chapter',
                data: data1.map(
                    (chap) =>
                        Number(chap?.COUNT_ACTIVE_ALUMNI) -
                        Number(chap?.LINKED_ALUMNI_BY_CITY)
                ),
                backgroundColor: '#efeefe',
                borderWidth: 0,
            },
        ],
    };
    const options = {
        scales: {
            x: {
                grid: {
                    drawOnChartArea: false,
                    borderColor: '#bbb',
                    drawTicks: false,
                },
                stacked: true,
                ticks: {
                    padding: 10,
                },
            },
            y: {
                grid: {
                    borderColor: '#bbb',
                    color: '#dee2e6',
                },
                ticks: {
                    padding: 10,
                    beginAtZero: true,
                },
                stacked: true,
            },
        },
    };
    
    return (
        <>
            <div className='small h6 text-dark'>
                Inclusion of Alumni in City Chapter
            </div>

            <div className='text-muted' style={{ fontSize: '.75em' }}>
                Location Based
            </div>
            <div className='p-3 bg-white rounded-smoother mt-3 card-shadow'>
                <Bar
                    data={graphData}
                    height={180}
                    options={options}
                />
            </div>
        </>
    );
}

export default CityChapterInclusion;
