import React from 'react';

const ClassRep = ({ classRepList }) => {
    const wrapperStyle = {
        borderRadius: '12px',
        overflow: 'hidden',
        border: '1px solid #ddd',
    };

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };

    const thTdStyle = {
        border: '1px solid #ddd',
        padding: '8px',
    };

    const thStyle = {
        ...thTdStyle,
        backgroundColor: '#f2f2f2',
    };

    const appStyle = {
        textAlign: 'center',
        marginTop: '20px',
    };

    const tbodyWrapperStyle = {
        maxHeight: '200px',
        overflowY: 'scroll',
        display: 'block',
        width: '100%',
    };

    const tableHeaderStyle = {
        display: 'table',
        width: '100%',
        tableLayout: 'fixed',
    };

    const tableBodyStyle = {
        display: 'table',
        width: '100%',
        tableLayout: 'fixed',
    };

    return (
        <div style={appStyle}>
            <div style={wrapperStyle}>
                <table style={tableStyle}>
                    <thead style={tableHeaderStyle}>
                        <tr>
                            <th style={{ ...thStyle, color: '#467AFF' }}>
                                User Name
                            </th>
                            <th style={{ ...thStyle, color: '#467AFF' }}>
                                Batch Details
                            </th>
                        </tr>
                    </thead>
                </table>
                <div style={tbodyWrapperStyle}>
                    <table style={tableStyle}>
                        <tbody style={tableBodyStyle}>
                            {classRepList.map((contact, index) => (
                                <tr key={index}>
                                    <td style={thTdStyle}>
                                        {contact.alumniName}
                                    </td>
                                    <td style={thTdStyle}>
                                        {contact.batchName}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ClassRep;
