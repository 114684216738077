import axios from 'axios';
import { useState } from 'react';
import { ReactComponent as HistoryIcon } from '../../asset/icons/history.svg';
import { ReactComponent as UpAccordian } from '../../asset/icons/expand_circle_up.svg';
import { ReactComponent as DownAccordian } from '../../asset/icons/expand_circle_down.svg';
import BulkUploadFileIcon from '../../asset/icons/BulkUploadicon.svg';
import tickIcon from '../../asset/icons/CheckboxBase.svg';
import { useAuthenticationState } from '../../context/Auth.context';
import './customButton.css';

const buttonCommonStyle = {
    padding: '12px 0',
    width: '100%',
    fontSize: '16px',
    fontWeight: '600',
    borderRadius: '8px',
    marginBottom: '10px',
};

const CustomButton = ({
    click,
    placeHolder,
    style,
    bulkUploadId,
    hoverEffect = false,
    ...rest
}) => {
    const buttonStyle = {
        width: '100%',
        textAlign: 'center',
        ...style,
        visibility: rest.isShow ? 'visible' : 'hidden',
    };
    return (
        <button
            className={hoverEffect ? 'primaryBtn btnAnime custom-button' : ''}
            disabled={rest.disabled}
            style={buttonStyle}
            onClick={click}
            title={rest.disabled ? `The bulk upload #${bulkUploadId} was not done by you.` : ''}
        >
            {placeHolder}
        </button>
    );
};

const StatusCells = ({ item }) => {
    const statusStyles = {
        padding: '5px',
        borderRadius: '20px',
        color: '#FFFFFF',
        fontWeight: 600,
        fontSize: '11px',
        width: '60px',
        textAlign: 'center',
    };

    const statusMap = {
        init: { ...statusStyles, background: '#FFA823', text: 'Initialized' },
        validation: {
            ...statusStyles,
            background: '#FFA823',
            text: 'Initialized',
        },
        failed: { ...statusStyles, background: '#FF523A', text: 'Failed' },
        success: {
            ...statusStyles,
            background: '#D4F8D3',
            color: '#222222',
            text: 'Success',
        },
    };

    const status = statusMap[item] || {
        ...statusStyles,
        background: '#FF523A',
        text: 'Unknown Status',
    };

    return <div style={status}>{status.text}</div>;
};


const convertToIST12HourFormat = (dateStr) => {
    try {
        const parts = dateStr?.split('T');
        if (parts.length !== 2) {
            throw new Error('Invalid date format');
        }
        const datePart = parts[0];
        const timePart = parts[1].substring(0, 8);

        const date = new Date(datePart);
        const formattedDate = date.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        });

        const hourMinute = timePart?.split(':');
        const hour = parseInt(hourMinute[0], 10);
        const minute = parseInt(hourMinute[1], 10);
        const period = hour < 12 ? 'AM' : 'PM';
        const formattedTime = `${hour % 12 || 12}:${minute} ${period}`;
        return `${formattedDate} at ${formattedTime}`;
    }
    catch (error) {
        console.error('Error converting date:', error.message);
        return 'Invalid date';
    }
};

const TimeDisplay = ({ timestamp }) => {
    const formattedTime = convertToIST12HourFormat(timestamp);
    return <div>{formattedTime}</div>;
};

const ValidateModal = (props) => {
    const {
        closeModal,
        dataForValidation = [],
        setSelectedFile,
        urlForValidate,
        setmodalState,
        showAlert,
        setLoading,
        initialBulkInsert,
        setSuccessResponse,
        setNewFields,
        selectedFile,
        setBulkUploadId,
        bulkUploadId,
    } = props;

    const { user } = useAuthenticationState();
    const [progress, setProgress] = useState(0);
    const [isClicked, setIsClicked] = useState(false);

    const validate = async (bulkUploadId) => {
        try {
            const config = {
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setProgress(progress);
                },
            };

            const validateData = await axios.post(
                urlForValidate,
                { bulkUploadId },
                config
            );
            setLoading(true);
            const initialBulkInsertResponse = await axios.post(
                initialBulkInsert,
                { bulkUploadId: validateData.data.bulkUploadId }
            );
            setLoading(true);
            setSuccessResponse(initialBulkInsertResponse.data);
            setmodalState('success');
        } catch (error) {
            if (error.config.url === urlForValidate) {
                handleValidationError(error, 'first', bulkUploadId);
            } else {
                handleValidationError(error, 'second', bulkUploadId);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleValidationError = (error, stage, bulkUploadId) => {
        const errorMessage =
            error.response?.data?.statusText ||
            'An error occurred during validation';
        if (stage === 'second' && error.response?.data?.Flag === 1) {
            setNewFields(error.response.data.responseData);
            setSuccessResponse(error.response.data);
            setBulkUploadId(bulkUploadId);
            setmodalState('dependent');
        } else if (stage === 'second' && error.response?.status !== 500) {
            showAlert('error', errorMessage);
            setSuccessResponse(error.response.data);
            setmodalState('success');
        } else {
            setmodalState('validate');
            showAlert('error', errorMessage);
        }
    };

    const { bulkUploadHistory } = dataForValidation;

    return (
        <div>
            <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                <div
                    style={{
                        color: '#101828',
                        fontWeight: 600,
                        fontSize: '18px',
                    }}
                >
                    Bulk Upload
                </div>
                <div style={{ color: '#667085', fontSize: '13px' }}>
                    File Uploaded Successfully.
                </div>
            </div>
            <div
                style={{
                    border: '2px solid rgba(16, 123, 255, 1)',
                    padding: '5px',
                    borderRadius: '5px',
                    display: 'flex',
                }}
            >
                <div>
                    <img alt='Bulk upload icon' src={BulkUploadFileIcon} style={{ width: '100%' }} />
                </div>
                <div
                    style={{
                        width: '100%',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                    }}
                >
                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                        {selectedFile.name}
                    </div>
                    <div>{byteConverter(selectedFile.size).kb}</div>
                </div>
                <img
                    alt='Tick icon'
                    src={tickIcon}
                    style={{ paddingBottom: '12%', paddingRight: '2%' }}
                />
            </div>
            {bulkUploadHistory.length > 0 && (
                <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            marginTop: '5px',
                            width: '40%',
                            alignItems: 'center',
                        }}
                    >
                        <HistoryIcon />
                        <span
                            style={{
                                color: '#595959',
                                fontWeight: 500,
                                fontSize: '12px',
                            }}
                        >
                            bulk upload history
                        </span>
                        {isClicked ? (
                            <UpAccordian
                                style={{ cursor: 'pointer' }}
                                onClick={() => setIsClicked(!isClicked)}
                            />
                        ) : (
                            <DownAccordian
                                style={{ cursor: 'pointer' }}
                                onClick={() => setIsClicked(!isClicked)}
                            />
                        )}
                    </div>
                    {isClicked && (
                        <div
                            style={{
                                maxHeight: '200px',
                                overflow: 'auto',
                                padding: '5px',
                            }}
                        >
                            {bulkUploadHistory.map((item) => (
                                <div
                                    key={item.bulkUploadId}
                                    style={{
                                        display: 'flex',
                                        borderBottom: '1px solid #00000017',
                                        padding: '15px',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            marginRight: '10px',
                                            fontWeight: 700,
                                        }}
                                    >
                                        #{item.bulkUploadId}
                                    </div>
                                    <div
                                        style={{
                                            width: '60%',
                                            overflow: 'auto',
                                            marginRight: '20px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: '#342929',
                                                fontSize: '12px',
                                                fontWeight: 700,
                                            }}
                                        >
                                            {item.bulkUploadFileName}
                                        </div>
                                        <div
                                            style={{
                                                color: '#A0A9B4',
                                                fontSize: '10px',
                                            }}
                                        ><TimeDisplay timestamp={item.createdDate} />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            width: '45%',
                                        }}
                                    >
                                        <StatusCells
                                            item={item.bulkUploadStatus}
                                        />

                                        <CustomButton
                                            placeHolder='Validate'
                                            disabled={
                                                user.ALUMNI_BATCH_ADM_CODE !== item.createdBy
                                            }
                                            isShow={
                                                item.bulkUploadStatus !==
                                                'success' &&
                                                item.bulkUploadStatus !==
                                                'failed'
                                            }
                                            bulkUploadId={item.bulkUploadId}
                                            style={{
                                                padding: '4px',
                                                width: '50%',
                                                border: '1px solid #107BFF',
                                                color: '#EFEFEF',
                                                fontSize: '11px',
                                                fontWeight: '600',
                                                background: '#107BFF',
                                                borderRadius: '11px',
                                                boxShadow:
                                                    '5px 5px 5px rgba(0, 0, 0, 0.2)',
                                                marginLeft: '5px',
                                            }}
                                            hoverEffect={true}
                                            click={async () =>
                                                await validate(
                                                    item.bulkUploadId
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}

            <CustomButton
                placeHolder='Validate & View Result'
                isShow={true}
                style={{
                    ...buttonCommonStyle,
                    border: '1px solid #107BFF',
                    color: '#EFEFEF',
                    background: '#107BFF',
                    marginTop: '10px'
                }}
                click={async () => await validate(bulkUploadId)}
            />

            <CustomButton
                placeHolder='Cancel'
                style={{
                    ...buttonCommonStyle,
                    border: '1px solid #D0D5DD',
                    color: '#344054',
                    background: '#FFFFFF',
                }}
                isShow={true}
                click={() => {
                    setSelectedFile(null);
                    closeModal();
                }}
            />
        </div>
    );
};

export default ValidateModal;

function byteConverter(bytes) {
    const kb = bytes / 1024;
    const mb = kb / 1024;
    return { kb: Math.round(kb) + 'kb', mb: mb.toFixed(2) + 'mb' };
}
