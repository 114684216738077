import React from 'react';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import Avatar from '../../asset/img/Avatar.png';

function BloodDonorTable({ selectedFilter }) {
    const getApi = endpoints.statistics.bloodDonor;
    const fetchUtils = useAxiosGet(getApi);

    const filteredResponse = fetchUtils?.response?.alumniDetails?.filter(
        (item) => {
            switch (selectedFilter) {
                case 'Student':
                    return Number(item.user_type) === 2;
                case 'Faculty':
                    return Number(item.user_type) === 3;
                case 'Alumini':
                    return Number(item.user_type) === 1;
                default:
                    return true;
            }   
        }
    );

    return (
        <>
            {filteredResponse?.length > 0 ? (
                <div>
                <table
                    className='table'
                    style={{
                        borderRadius: '12px 12px 0 0',
                        overflow: 'hidden',
                        width: '100%',
                    }}
                >
                    <thead style={{ borderRadius: '12px', border: 'none' }}>
                        <tr style={{ borderRadius: '12px' }}>
                            <th style={{ borderBottom: 'none' }}>Name</th>
                            <th style={{ borderBottom: 'none' }}>
                                Blood Group
                            </th>
                        </tr>
                    </thead>
                    </table>
                    <div
                    style={{
                        maxHeight: '300px',
                        overflowY: 'auto',
                        borderRadius: '0 0 12px 12px',
                    }}
                >
                    <table
                    className='table'
                    style={{
                        borderRadius: '0 0 12px 12px',
                        overflow: 'hidden',
                        width: '100%',
                    }}
                >
                    <tbody
                        style={{
                            overflowY: 'scroll',
                            borderBottom: 'none',
                            border: 'none',
                        }}
                    >
                        {filteredResponse?.map((person, index) => (
                            <tr key={index} style={{ borderRadius: '12px' }}>
                                <td
                                    style={{
                                        width: '50%',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <img
                                            src={
                                                person.ALUMNI_IMAGE_URL ||
                                                Avatar
                                            }
                                            alt='Avatar'
                                            className='rounded-circle shadow-sm'
                                            style={{
                                                border: '3px solid white',
                                                width: '25px',
                                                height: '25px',
                                            }}
                                            width='30'
                                        />
                                        <div
                                            style={{
                                                fontSize: '14px',
                                                textAlign: 'start',
                                                paddingLeft: '2px',
                                            }}
                                        >
                                            <span>{person.ALUMNI_Name}</span>
                                            <br />
                                            {person.PHONE_NUMBER}{' '}
                                        </div>
                                    </div>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                    {person.bloodGroups}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
                </div>
            ) : (
                'No Data found'
            )}
        </>
    );
}

export default BloodDonorTable;
