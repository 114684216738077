import React, { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function PaginatedLineChart(props) {
    const { data, dataPerPage = 20, ...rest } = props;

    const [page, setPage] = useState(1);
    const [paginated, setPaginated] = useState({});
    const [endIndex, setEndIndex] = useState('');

    useEffect(() => {
        const startIndex = page * dataPerPage - dataPerPage;
        const lastIndex = startIndex + dataPerPage;

        setEndIndex(lastIndex);

        const tempLabel = [...data.labels];
        const tempData = [...data.datasets[0].data];

        const labelsPerPage =
            tempLabel.length > 0 ? tempLabel.slice(startIndex, lastIndex) : [];
        const valuesPerPage =
            tempData.length > 0 ? tempData.slice(startIndex, lastIndex) : [];

        const temp = {
            ...data,
            labels: labelsPerPage,
            datasets: [
                {
                    borderColor: '#5e72e4',
                    backgroundColor: '#5e72e4',
                    data: valuesPerPage,
                },
            ],
        };

        setPaginated(temp);
    }, [page]);

    const options = {
        plugins: {
            legend: { display: false },
            tooltip: {
                enabled: true,
                backgroundColor: 'rgba(99,99,99,.6)',
            },
        },
        scales: {
            x: {
                grid: {
                    drawOnChartArea: false,
                    borderColor: '#999',
                    drawTicks: false,
                },
                ticks: {
                    padding: 15,
                    font: {
                        size: '10px',
                        family: 'Inter',
                    },
                },
            },
            y: {
                grid: {
                    borderColor: '#999',
                    borderDash: [8],
                    color: '#dee2e6',
                    drawTicks: false,
                },
                beginAtZero: true,
                ticks: {
                    beginAtZero: true,
                    padding: 15,
                    stepSize: 2,
                    font: {
                        size: '10px',
                    },
                },
            },
        },
        elements: {
            point: {
                radius: 3,
                pointStyle: 'circle',
                hoverBorderWidth: 3,
                hoverRadius: 4,
                hoverBorderColor: 'rgba(153, 102, 255, 0.6)',
            },
            line: {
                tension: 0.4,
                borderWidth: 2,
                backgroundColor: 'transparent',
            },
        },
    };

    if (!paginated.labels) {
        return '';
    }
    return (
        <>
            <div>
                <Line data={paginated} options={options} {...rest} />
            </div>
            <div className='d-flex justify-content-center'>
                <Pagination size = 'sm'>
                    <Pagination.Prev
                        disabled={page === 1}
                        onClick={(e) => {
                            setPage(page - 1);
                            e.preventDefault();
                        }}
                    />

                    <Pagination.Item>{page}</Pagination.Item>

                    <Pagination.Next
                        disabled={data.labels[endIndex] + 1 ? false : true}
                        onClick={(e) => {
                            setPage(page + 1);
                            e.preventDefault();
                        }}
                    />
                </Pagination>
            </div>
        </>
    );
}

export default PaginatedLineChart;
