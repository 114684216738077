import React, { useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import MarkAsSafeTable from './MarkAsSafeTable';
import { ReactComponent as Safe } from '../../asset/icons/MarkSafe.svg';
import { ReactComponent as Filter } from '../../asset/icons/Filter.svg';
import { ReactComponent as Close } from '../../asset/icons/Close.svg';
import FilterButtonGroup from './FilterButtonGroup';

function MarkAsSafe({ data }) {
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [filterVisible, setFilterVisible] = useState(false);

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
    };

    const toggleFilterVisibility = () => {
        if (filterVisible) {
            setSelectedFilter('All');
        }
        setFilterVisible(!filterVisible);
    };
    const filterButtonStyle = {
        backgroundColor: '#467AFF',
        color: 'white',
    };

    return (
        <Tab.Container defaultActiveKey='SafeEvent'>
            <Row className='px-2'>
                <Col className='d-flex'>
                    <Safe />
                    <Col className='px-2'>
                        <div className='small h6 text-dark'>
                            Mark As Safe Events
                        </div>
                        <div
                            className='text-muted'
                            style={{ fontSize: '.75em' }}
                        >
                            Based on Batch
                        </div>
                    </Col>
                </Col>

                <Col
                    md='5'
                    style={{
                        display: 'flex',
                        width: '60%',
                        justifyContent: 'flex-end',
                    }}
                >
                    <div>
                        {filterVisible && (
                            <FilterButtonGroup
                                onFilterChange={handleFilterChange}
                            />
                        )}
                    </div>
                    <Nav variant='pills'>
                        <Nav.Item className='ms-auto'>
                            <Nav.Link
                                type='button'
                                className='px-4 py-2'
                                style={filterButtonStyle}
                                onClick={toggleFilterVisibility}
                            >
                                <small
                                    style={{
                                        fontSize: '11px',
                                        lineHeight: '27px',
                                    }}
                                >
                                    Filter &nbsp;
                                    {filterVisible ? <Close /> : <Filter />}
                                </small>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>

            <Tab.Content className='rounded-smoother mt-3 card-shadow'>
                <Tab.Pane eventKey='SafeEvent' title='SafeEvent'>
                    <MarkAsSafeTable selectedFilter={selectedFilter} />
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    );
}

export default MarkAsSafe;
