import React from 'react';
import { Link } from 'react-router-dom';

function TermsAndPolicy() {
    return (
        <p
            className='small mt-2'
            style={{
                color: 'rgba(40, 40, 40, 0.86)',
            }}
        >
            By continuing, I agree to Koble’s{' '}
            <Link to='/terms' style={{ fontWeight: '600', color: '#396FFA' }}>
                Terms & Conditions
            </Link>{' '}
            and{' '}
            <Link
                to='/privacy-policy'
                style={{ fontWeight: '600', color: '#396FFA' }}
            >
                Privacy Policy
            </Link>
        </p>
    );
}

export default TermsAndPolicy;
