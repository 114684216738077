import React from 'react';
import Table from '../../components/basicTable/TableFomat';

function BasicTable({ fetchUtils, cellModifier, name }) {
    return (
        <div style={{ width: '100%' }}>
            <Table {...name} cellModifier={cellModifier} {...fetchUtils} />
        </div>
    );
}

export default BasicTable;
