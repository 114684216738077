export default function objCloneKeyValue(objArr, org, clone) {
    const temp = objArr.map((obj) => ({ ...obj }));

    temp.forEach((obj) => {
        if (typeof clone === typeof '') {
            obj[clone] = obj[org];
        }
        if (Array.isArray(clone)) {
            clone.forEach((cl, i) => {
                obj[cl] = obj[org[i]];
            });
        }
    });
    return temp;
}
