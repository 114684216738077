import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import TopicOfInterestForm from './TopicOfInterestForm';
import { ReactComponent as Approve } from '../../asset/icons/Approve.svg';
import { ReactComponent as Reject } from '../../asset/icons/Block.svg';
import axios from 'axios';
import withAlert from '../../components/withAlert';
import ModalWrapper from '../../components/ModalWrapper';
import PostWithWarning from '../../components/PostWithWarning';
import StatusIndicator from '../../components/StatusIndicator';

function TopicOfInterest({ showAlert }) {
    const fetchUtils = useAxiosGet(endpoints.topicOfInterest.get);

    const approve = (data) => {
        axios
            .post(endpoints.topicOfInterest.approve, {
                TopicOfInterest_ID: data.TopicOfInterest_ID,
                TopicofInterest: data.TopicofInterest,
                CREATEDBY: String(data.CREATEDBY), //type conversion for case of 0
            })
            .then((_) => {
                showAlert('success', 'Topic Approved');
                fetchUtils.reFetch();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            });
    };
    const reject = (data) => {
        axios
            .post(endpoints.topicOfInterest.reject, {
                TopicOfInterest_ID: data.TopicOfInterest_ID,
                TopicofInterest: data.TopicofInterest,
                CREATEDBY: String(data.CREATEDBY), //type conversion for case of 0
            })
            .then((_) => {
                showAlert('success', 'Topic Rejected');
                fetchUtils.reFetch();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            });
    };

    const cellModifier = {
        Approve_Status: ({ value }) => {
            const status = {
                1: { tag: 'Approved', variant: 'bg-success' },
                2: { tag: 'Rejected', variant: 'bg-danger' },
                3: { tag: 'Not Responded', variant: 'bg-warning' },
            };
            return (
                <StatusIndicator
                    variant={status[value]?.variant}
                    status={status[value]?.tag}
                />
            );
        },
        Approve: ({ row }) => (
            <ModalWrapper
                modalTitle={'Approve Topic ?'}
                modalAttrs={{ size: 'sm' }}
                disabled={row.original.Approve_Status !== 3}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert('success', 'The topic has been approved');
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.topicOfInterest.approve}
                        configBody={{
                            TopicOfInterest_ID: row.original.TopicOfInterest_ID,
                            TopicofInterest: row.original.TopicofInterest,
                            CREATEDBY: String(row.original.CREATEDBY), //type conversion for case of 0
                        }}
                        successVarient='success'
                        dangerVarient='danger'
                    />
                )}
            >
                <span
                    type='button'
                    className='ps-3'
                >
                    <Approve />
                </span>
            </ModalWrapper>
        ),
        Reject: ({ row }) => (
            <ModalWrapper
                modalTitle={'Reject Topic?'}
                modalAttrs={{ size: 'sm' }}
                disabled={row.original.Approve_Status !== 3}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert('success', 'The topic has been rejected');
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.topicOfInterest.reject}
                        configBody={{
                            TopicOfInterest_ID: row.original.TopicOfInterest_ID,
                            TopicofInterest: row.original.TopicofInterest,
                            CREATEDBY: String(row.original.CREATEDBY), //type conversion for case of 0
                        }}
                        // successVarient='success'
                        // dangerVarient='danger'
                    />
                )}
            >
                <span
                    type='button'
                    className='ps-3'
                >
                    <Reject />
                </span>
            </ModalWrapper>
        ),
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.topicOfInterest}
                {...viewProps.TopicOfInterest}
                Form={TopicOfInterestForm}
                cellModifier={cellModifier}
                {...fetchUtils}
                addBtnLabel='Add Topic'
            />
        </>
    );
}

export default withAlert(TopicOfInterest);
