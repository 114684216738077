import React from 'react';
import { ReactComponent as Calender } from '../../asset/icons/calendar-check.svg';

function RequestContent({ data, isJobRequest = false }) {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };
    console.log(data, 'modal data');
    const requestTypeMap = {
        1: 'MEDICAL RELATED',
        2: 'CALAMITY RELATED',
        3: 'QUERIES',
        4: 'PERSONAL',
        5: 'LEGAL ADVICE',
        6: 'EMPLOYMENT RELATED',
    };

    const requestPriorityMap = {
        1: 'URGENT',
        2: 'IMMEDIATE',
        3: 'NOT URGENT',
    };

    const broadCastTypeMap = {
        1: 'ALL',
        2: 'BATCH',
    };
    return (
        <div style={{ paddingTop: '2%', paddingBottom: '2%' }}>
            <div style={{ fontSize: '19px', fontWeight: '700' }}>
                {isJobRequest ? data.Designation : ''}
            </div>
            <div
                style={{
                    fontSize: '11px',
                    fontWeight: '500',
                    color: 'rgba(108, 108, 108, 1)',
                    marginTop: '1%',
                }}
            >
                {isJobRequest
                    ? data.JobRequirementDetails
                    : data.Alumni_HelpRequest_DESC}
            </div>
            <div style={{ marginTop: '2%' }}>
                <span
                    style={{
                        fontSize: '11px',
                        fontWeight: '700',
                        padding: '2%',
                        borderRadius: '40px',
                        background: 'rgba(255, 225, 215, 1)',
                    }}
                >
                    {isJobRequest
                        ? data.Requirement_Priority
                        : requestPriorityMap[data.Alumni_HelpRequest_PRIORITY]}
                </span>
                <span
                    style={{
                        fontSize: '11px',
                        fontWeight: '700',
                        padding: '2%',
                        borderRadius: '40px',
                        background: 'rgba(236, 236, 236, 1)',
                        marginLeft: '1%',
                    }}
                >
                    {isJobRequest
                        ? data.DomainLookout
                        : requestTypeMap[data.Alumni_HelpRequest_TYPE]}
                </span>
            </div>
            <div
                style={{
                    marginTop: '2%',
                    borderBottom: '1px solid rgba(19, 24, 38, 0.05)',
                    padding: '2%',
                }}
            >
                <Calender />
                &nbsp; from&nbsp;
                {isJobRequest
                    ? formatDate(data.CREATEDDATE)
                    : formatDate(data.DateCreated)}{' '}
                to{' '}
                {isJobRequest
                    ? ''
                    : formatDate(data.Alumni_HelpRequest_ExpiryDate)}
            </div>
            <div>
                <span
                    style={{
                        fontSize: '12px',
                        fontWeight: '500',
                        color: '#656D76',
                    }}
                >
                    Alumni Name
                </span>{' '}
                &nbsp;
                <span
                    style={{
                        fontSize: '12px',
                        fontWeight: '700',
                        color: '#0075E0',
                    }}
                >
                    {isJobRequest
                        ? data.ALUMNI_Name
                        : data.Alumni_HelpRequest_AlumniNAME}
                </span>
                <br />
                <span
                    style={{
                        fontSize: '12px',
                        fontWeight: '500',
                        color: '#656D76',
                    }}
                >
                    {' '}
                    Request Type
                </span>{' '}
                &nbsp;
                <span
                    style={{
                        fontSize: '12px',
                        fontWeight: '700',
                        color: '#0075E0',
                    }}
                >
                    {isJobRequest
                        ? data.Requirement_Priority
                        : requestTypeMap[data.Alumni_HelpRequest_TYPE]}
                </span>
                <br />
                <span
                    style={{
                        fontSize: '12px',
                        fontWeight: '500',
                        color: '#656D76',
                    }}
                >
                    Location
                </span>{' '}
                &nbsp;
                <span
                    style={{
                        fontSize: '12px',
                        fontWeight: '700',
                        color: '#0075E0',
                    }}
                >
                    {isJobRequest
                        ? data.LocationLookOut
                        : data.Alumni_HelpRequest_Location}
                </span>
                <br />
                <span
                    style={{
                        fontSize: '12px',
                        fontWeight: '500',
                        color: '#656D76',
                    }}
                >
                    Broadcast Type
                </span>{' '}
                &nbsp;
                <span
                    style={{
                        fontSize: '12px',
                        fontWeight: '700',
                        color: '#0075E0',
                    }}
                >
                    {isJobRequest
                        ? ''
                        : broadCastTypeMap[
                              data.Alumni_HelpRequest_BROADCAST_TYPE
                          ]}
                </span>
            </div>
        </div>
    );
}

export default RequestContent;
