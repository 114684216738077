const today = new Date().getFullYear();

const errorObjects = [
    {
        errorType: 'alumniErrorMessages',
        errorArea: 'row:',
        errorNum: 'row',
        errorField: 'ALUMNI_ID',
        // errorMsg: 'The ALUMNI_ID does not exist in the system',
    },
    {
        errorType: 'cityChapterErrorMessages',
        errorArea: 'row:',
        errorNum: 'row',
        errorField: 'CITY_CHAPTER_ID',
        // errorMsg: 'The CITYCHAPTER_ID does not exist in the system',
    },
];

export default errorObjects;
