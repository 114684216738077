import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../components/Loader';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';
import withAlert from '../../components/withAlert';
import { Col, Row } from 'react-bootstrap';
import { useAxiosGetMultiple } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import { checkIsoAndReturnYMDFormat } from '../../helpers/dateFunctions';
import { useLabelProvider } from '../../context/LabelProvider';

import {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
} from 'react-phone-number-input';

function AlumniForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const { setLabels } = useLabelProvider();

    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedDay, setSelectedDay] = useState('');
    const [days, setDays] = useState([]);
    const months = [
        { name: 'January', value: 1, days: 31 },
        { name: 'February', value: 2, days: 29 },
        { name: 'March', value: 3, days: 31 },
        { name: 'April', value: 4, days: 30 },
        { name: 'May', value: 5, days: 31 },
        { name: 'June', value: 6, days: 30 },
        { name: 'July', value: 7, days: 31 },
        { name: 'August', value: 8, days: 31 },
        { name: 'September', value: 9, days: 30 },
        { name: 'October', value: 10, days: 31 },
        { name: 'November', value: 11, days: 30 },
        { name: 'December', value: 12, days: 31 },
    ];

    useEffect(() => {
        setLabels('AlumniDetails');
    }, []);

    const { response, loading } = useAxiosGetMultiple({
        batches: endpoints.batch.get,
        industries: endpoints.industry.get,
        organizations: endpoints.organization.get,
    });

    const formType = updateValues ? 'update' : 'add';
    const { batches, industries, organizations } = response;
    const currentDate = new Date();

    const filteredBatches = batches.filter((item) =>
        item.BATCH_END_DATE
            ? new Date(item.BATCH_END_DATE) <= currentDate
            : item.BATCH_END_DATE
    );

    // Update on DOB
    useEffect(() => {
        if (updateValues && updateValues?.DateOfBirth) {
            const [month, day] = updateValues?.DateOfBirth?.split('-');
            const selectedMonthObj = months?.find(
                (m) => m.value === parseInt(month, 10)
            );
            setSelectedMonth(month);
            setSelectedDay(day);
            setDays(daysInMonths(selectedMonthObj?.days));
        }
    }, [updateValues]);
    //  End

    const submitHandler = (values, { setSubmitting: setLoading }) => {
        setLoading(true);

        const body = { ...values };

        if (formType === 'update') {
            [
                'ALUMNI_BATCH_ADM_CODE',
                'IsEntrepreneur',
                'IsEmployed',
                'IsLookingForAChange',
            ].forEach((prop) => {
                body[prop] = String(body[prop]);
            });
        }
        if (!body.YearsOfexperience) {
            body.YearsOfexperience = null;
        }
        if (!body.YearOfGraduation) {
            body.YearOfGraduation = null;
        }
        if (formType === 'update') {
            if (typeof body.BATCH_CODE[0] === 'object') {
                body.BATCH_CODE = body.BATCH_CODE.map(
                    (item) => item.BATCH_CODE
                );
            }
        }
        body.user_type = body?.user_Type;

        axios
            .post(endpoint, body)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert('error', extractErrorFromRes(err));
            })
            .finally((res) => setLoading(false));
    };
    const initialFormValues = updateValues || {
        // ALUMNI_BATCH_ADM_CODE: '',
        ALUMNI_Name: '',
        Alumni_Current_Location: '',
        ALUMNI_ABOUTME: '',
        BATCH_CODE: '',
        // COURSE_ID: '',
        DateOfBirth: '',
        user_Type: '1',
        Designation: '',
        Organization_Name: '',
        Industry: '',
        IsActive: '2',
        IsEmployed: '',
        IsEntrepreneur: '',
        IsLookingForAChange: '',
        isBloodDonor: '',
        bloodGroup: '0',
        LinkedInProfileLink: '',
        PERSONAL_EMAILID: '',
        PHONE_NUMBER: '',
        PreviousLatestQualification: '',
        PrivacySettings: '2',
        YearOfGraduation: null,
        YearsOfexperience: null,
        SUSPEND_STATUS: '1',
    };
    const preSelectedOption = {};

    if (updateValues) {
        updateValues.DateOfBirth = checkIsoAndReturnYMDFormat(
            updateValues.DateOfBirth
        );

        preSelectedOption.batch = filteredBatches.find(
            (batch) =>
                Number(batch.BATCH_CODE) ===
                Number(initialFormValues.BATCH_CODE)
        );
        preSelectedOption.industry = industries.find(
            (industry) =>
                industry?.Industry?.trim()?.toLowerCase() ===
                initialFormValues?.Industry?.trim()?.toLowerCase()
        );
        preSelectedOption.organization = organizations.find(
            (organization) =>
                organization?.Organization_Name?.trim()?.toLowerCase() ===
                initialFormValues?.Organization_Name?.trim()?.toLowerCase()
        );
    }
    if (loading) {
        return <Loader />;
    }

    function daysInMonths(end) {
        const objectArray = [];
        for (let i = 1; i <= end; i++) {
            objectArray.push({ name: String(i), value: String(i) });
        }
        return objectArray;
    }
    const BLOOD_GROUPS = [
        { label: 'A +ve', value: '1' },
        { label: 'A-ve', value: '2' },
        { label: 'B+ve', value: '3' },
        { label: 'B-ve', value: '4' },
        { label: 'AB+ve', value: '5' },
        { label: 'AB-ve', value: '6' },
        { label: 'O+ve', value: '7' },
        { label: 'O-ve', value: '8' },
    ];
    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                ALUMNI_Name: Yup.string().required(),
                Alumni_Current_Location: Yup.string().required(),
                ALUMNI_ABOUTME: Yup.string().nullable(),
                BATCH_CODE: Yup.array().min(1, 'Required'),
                user_Type: Yup.string().required(),
                DateOfBirth: Yup.string(),
                // DateOfBirth: Yup.date()
                //     .max(
                //         new Date(
                //             new Date().setYear(new Date().getFullYear() - 18)
                //         ),
                //         'Must be 18 years old'
                //     )
                //     .required(),
                Designation: Yup.string().when('IsEmployed', {
                    is: '1',
                    then: () => Yup.string().required('Required'),
                    otherwise: () => Yup.string().nullable(),
                }),
                IsEmployed: Yup.string().required(),
                Organization_Name: Yup.string().when('IsEmployed', {
                    is: '1',
                    then: () => Yup.string().required('Required'),
                    otherwise: () => Yup.string().nullable(),
                }),
                Industry: Yup.string().when('IsEmployed', {
                    is: '1',
                    then: () => Yup.string().required('Required'),
                    otherwise: () => Yup.string().nullable(),
                }),
                IsEntrepreneur: Yup.string().required(),
                IsLookingForAChange: Yup.string().required(),
                isBloodDonor: Yup.string().required(),
                bloodGroup: Yup.string().when('isBloodDonor', {
                    is: '1',
                    then: () => Yup.string().required('Required'),
                    otherwise: () => Yup.string().nullable(),
                }),
                LinkedInProfileLink: Yup.string().when('IsLookingForAChange', {
                    is: '1',
                    then: () =>
                        Yup.string()
                            .url()
                            .required(
                                'Required because Alumni is looking for change'
                            ),
                    otherwise: () => Yup.string().url().nullable(),
                }),
                PERSONAL_EMAILID: Yup.string()
                    .matches(
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                        'Please enter a valid email address'
                    )
                    .email()
                    .required(),
                PHONE_NUMBER: Yup.string()
                    .required('Contact Number is required')
                    .nullable()
                    .test(
                        'PHONE_NUMBER',
                        'Enter a valid contact number',
                        (value) => {
                            return isValidPhoneNumber(value ?? '');
                        }
                    ),

                PreviousLatestQualification: Yup.string().nullable(),
                PrivacySettings: Yup.string().required(),
                YearOfGraduation: Yup.number()
                    .min(1900, 'Invalid value')
                    .max(new Date().getFullYear() + 2, 'Invalid value')
                    .required(),
                YearsOfexperience: Yup.number()
                    .min(0, 'Invalid value')
                    .nullable(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    <Row>
                        <Col md='4'>
                            <FormikControl
                                control='input'
                                required
                                name='ALUMNI_Name'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='select'
                                required
                                name='user_Type'
                                disabled
                                title={
                                    formType !== 'add'
                                        ? "User type can't be updated"
                                        : ''
                                }
                            >
                                <option value=''>Select type</option>
                                <option value='1'>Alumni</option>
                                <option value='2'>Student</option>
                                <option value='3'>Faculty</option>
                            </FormikControl>
                        </Col>
                        {/* <Col md='4'>
                            <FormikControl
                                control='input'
                                type='date'
                                required
                                name='DateOfBirth'
                            />
                        </Col> */}
                        <div
                            style={{
                                width: '30%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    fontSize: '.7rem',
                                    fontWeight: '500',
                                    color: '#333',
                                }}
                            >
                                DATE OF BIRTH
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Col md='5' w='50'>
                                    <FormikControl
                                        control='select'
                                        name='Month'
                                        onChange={(e) => {
                                            const selectedMonth = months.find(
                                                (month) =>
                                                    month.value ==
                                                    e.target.value
                                            );
                                            setFieldValue(
                                                'Month',
                                                selectedMonth.name
                                            );
                                            setSelectedMonth(
                                                selectedMonth.value
                                            );
                                            setDays(
                                                daysInMonths(selectedMonth.days)
                                            );
                                            setSelectedDay('');
                                            setFieldValue('Day', '');
                                        }}
                                        value={selectedMonth}
                                    >
                                        <option value=''>Select Month</option>
                                        {months.map((type) => (
                                            <option
                                                key={type.value}
                                                value={type.value}
                                            >
                                                {type.name}
                                            </option>
                                        ))}
                                    </FormikControl>
                                </Col>
                                <Col md='5' w='50'>
                                    <FormikControl
                                        control='select'
                                        name='Day'
                                        onChange={(e) => {
                                            setSelectedDay(e.target.value);
                                            setFieldValue(
                                                'Day',
                                                e.target.value
                                            );
                                            setFieldValue(
                                                'DateOfBirth',
                                                selectedMonth +
                                                    '-' +
                                                    e.target.value
                                            );
                                        }}
                                        value={selectedDay}
                                    >
                                        <option value=''>Select Day</option>
                                        {days.map((day) => (
                                            <option
                                                key={day.value}
                                                value={day.value}
                                            >
                                                {day.name}
                                            </option>
                                        ))}
                                    </FormikControl>
                                </Col>
                            </div>
                        </div>
                        <Col md='4'>
                            <FormikControl
                                control='input'
                                required
                                name='Alumni_Current_Location'
                            />
                        </Col>

                        <Col md='4'>
                            <FormikControl
                                control='select-advanced'
                                required
                                options={filteredBatches}
                                defaultValue={initialFormValues?.BATCH_CODE}
                                optionValue='BATCH_CODE'
                                optionLabel='BATCH_NAME'
                                // isMulti={Number(values.user_Type) === 1||2}
                                isMulti
                                getOptionLabel={(label) =>
                                    label.BATCH_NAME + ' - ' + label.COURSE_NAME
                                }
                                label='BATCH'
                                name='BATCH_CODE'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='input'
                                name='Designation'
                                required={Number(values.IsEmployed) === 1}
                            />
                        </Col>

                        <Col md='4'>
                            <FormikControl
                                control='check'
                                type='radio'
                                required
                                options={[
                                    { key: 'Yes', value: '1' },
                                    { key: 'No', value: '0' },
                                ]}
                                name='IsEmployed'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='creatable-select'
                                required={Number(values.IsEmployed) === 1}
                                options={industries}
                                defaultValue={preSelectedOption.industry}
                                optionValue='Industry'
                                optionLabel='Industry'
                                name='Industry'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='creatable-select'
                                required={Number(values.IsEmployed) === 1}
                                options={organizations}
                                defaultValue={preSelectedOption.organization}
                                optionValue='Organization_Name'
                                optionLabel='Organization_Name'
                                name='Organization_Name'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='check'
                                type='radio'
                                required
                                options={[
                                    { key: 'Yes', value: '1' },
                                    { key: 'No', value: '0' },
                                ]}
                                name='IsEntrepreneur'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='check'
                                type='radio'
                                required
                                options={[
                                    { key: 'Yes', value: '1' },
                                    { key: 'No', value: '0' },
                                ]}
                                name='IsLookingForAChange'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='check'
                                type='radio'
                                required
                                label='Is Blood Donor'
                                options={[
                                    { key: 'Yes', value: '1' },
                                    { key: 'No', value: '0' },
                                ]}
                                name='isBloodDonor'
                            />
                        </Col>
                        {Number(values.isBloodDonor) == 1 && (
                            <Col md='5' w='50'>
                                <FormikControl
                                    control='select'
                                    name='bloodGroup'
                                >
                                    <option value=''>Select Blood Group</option>
                                    {BLOOD_GROUPS.map((type) => (
                                        <option
                                            key={type.value}
                                            value={type.value}
                                        >
                                            {type.label}
                                        </option>
                                    ))}
                                </FormikControl>
                            </Col>
                        )}

                        <Col md='4'>
                            <FormikControl
                                control='input'
                                label='Linkedin'
                                name='LinkedInProfileLink'
                                required={
                                    Number(values.IsLookingForAChange) === 1
                                }
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='input'
                                type='email'
                                required
                                name='PERSONAL_EMAILID'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='phone-number'
                                type='number'
                                required
                                name='PHONE_NUMBER'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='input'
                                name='PreviousLatestQualification'
                            />
                        </Col>
                        {Number(values.user_Type) !== 3 && (
                            <Col md='4'>
                                <FormikControl
                                    control='input'
                                    type='number'
                                    required={Number(values.user_Type) === 1}
                                    name='YearOfGraduation'
                                />
                            </Col>
                        )}
                        <Col md='4'>
                            <FormikControl
                                control='input'
                                type='number'
                                // required={Number(values.IsEmployed) === 1}
                                name='YearsOfexperience'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='check'
                                type='radio'
                                required
                                options={[
                                    { key: 'Active', value: '1' },
                                    { key: 'Inactive', value: '2' },
                                ]}
                                disabled={formType !== 'add' ? false : true}
                                name='IsActive'
                                title={
                                    formType !== 'add'
                                        ? ''
                                        : 'The status will be set to "Inactive" when adding an Alumni'
                                }
                            />
                        </Col>
                        <Col md='8'>
                            <FormikControl
                                control='textarea'
                                name='ALUMNI_ABOUTME'
                            />
                        </Col>
                        {/* <Col md='4'>
                            <FormikControl
                                control='check'
                                type='radio'
                                required
                                options={[
                                    { key: 'Full Access', value: '1' },
                                    { key: 'Limited Access', value: '3' },
                                    { key: 'No Access', value: '2' },
                                ]}
                                name='PrivacySettings'
                            />
                        </Col> */}
                    </Row>
                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(AlumniForm);
