import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import endpoints from '../../endpoints';
import axios from 'axios';
import FormikControl from '../../components/formikControl/FormikControl';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';
import { Collapse } from 'react-bootstrap';

function ClassRepForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
    updateValues,
}) {
    const [batches, setBatches] = useState([]);
    const [student, setBatchStudent] = useState([]);
    const [loading, setLoading] = useState(!updateValues);
    const [loadingstudent, setLoadingstudent] = useState(false);

    const getBatchStudent = (batchCode) => {
        setLoadingstudent(true);
        return axios.get(endpoints.alumniDetail.getStudentOnly);
    };

    const filterStudentsByBatch = (batchCode) => {
        return student.filter((student) =>
            student.BATCH_CODE.some((batch) => batch.BATCH_CODE === batchCode)
        );
    };

    useEffect(() => {
        if (!updateValues) {
            setLoading(true);

            axios
                .get(endpoints.batch.get)
                .then((res) => {
                    setBatches(res.data.responseData);
                })
                .catch((err) =>
                    showAlert(
                        'error',
                        err.response.data?.statusText || 'Something went wrong'
                    )
                )
                .finally(() => setLoading(false));
        } else {
            getBatchStudent(updateValues.batch_code)
                .then((res) => {
                    setBatchStudent(res.data.responseData);
                })
                .catch((err) =>
                    showAlert(
                        'error',
                        err.response.data?.statusText || 'Something went wrong'
                    )
                )
                .finally(() => {
                    setLoadingstudent(false);
                });
        }
    }, []);

    const submitHandler = (values, { setSubmitting }) => {
        const body = { ...values };
        axios
            .post(endpoint, body)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            })
            .finally((res) => setSubmitting(false));
    };

    const initialFormValues = updateValues || {
        classRepUserId: '',
        batchCode: '',
    };

    if (loading) {
        return <Loader />;
    }

    const preSelectedOption = {};

    if (updateValues) {
        updateValues.oldClassRepUserId = updateValues.classRepUserId;

        preSelectedOption.student = student.find(
            (student) =>
                Number(student.ALUMNI_BATCH_ADM_CODE) ===
                Number(initialFormValues.oldClassRepUserId)
        );
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                batchCode: Yup.string().required(),
                classRepUserId: Yup.string().required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    {!updateValues && (
                        <FormikControl
                            control='select-advanced'
                            required
                            options={batches}
                            optionValue='BATCH_CODE'
                            getOptionLabel={(label) =>
                                label.BATCH_NAME + ' ' + label.COURSE_NAME
                            }
                            onChange={(selected) => {
                                setFieldValue('batchCode', selected.BATCH_CODE);
                                setFieldValue('classRepUserId', '');

                                setBatchStudent([]);

                                if (selected.BATCH_CODE) {
                                    getBatchStudent(selected.BATCH_CODE)
                                        .then((res) => {
                                            setBatchStudent(
                                                res.data.responseData
                                            );
                                        })
                                        .catch((err) => {
                                            showAlert(
                                                'error',
                                                err.response.data?.statusText ||
                                                    'Something went wrong'
                                            );
                                        })
                                        .finally(() => {
                                            setLoadingstudent(false);
                                        });
                                }
                            }}
                            optionLabel='BATCH_NAME'
                            label='BATCH'
                            name='batchCode'
                        />
                    )}
                    {values.batchCode && !loadingstudent && (
                        <FormikControl
                            control='select-advanced'
                            required
                            name='classRepUserId'
                            label='STUDENT'
                            // options={student}
                            options={filterStudentsByBatch(values.batchCode)}
                            optionValue='ALUMNI_BATCH_ADM_CODE'
                            defaultValue={preSelectedOption.student}
                            optionLabel='ALUMNI_Name'
                            disabled={loadingstudent}
                        />
                    )}
                    <Collapse in={loadingstudent}>
                        <div
                            className='text-danger bg-white p-1 mt-2 text-center rounded'
                            style={{ fontSize: '12px' }}
                        >
                            Loading batch student
                        </div>
                    </Collapse>
                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(ClassRepForm);
