import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../components/Loader';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';
import withAlert from '../../components/withAlert';
import { Col, Row } from 'react-bootstrap';
import { useAxiosGetMultiple } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import { checkIsoAndReturnYMDFormat } from '../../helpers/dateFunctions';
import { useLabelProvider } from '../../context/LabelProvider';

import {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
} from 'react-phone-number-input';
import { date } from 'yup/lib/locale';

function InstituteMemberForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const { setLabels } = useLabelProvider();

    useEffect(() => {
        setLabels('InstituteMemberDetails');
    }, []);

    const { response, loading } = useAxiosGetMultiple({
        batches: endpoints.batch.get,
        industries: endpoints.industry.get,
        organizations: endpoints.organization.get,
    });

    const formType = updateValues ? 'update' : 'add';
    const { batches, industries, organizations } = response;

    const submitHandler = (values, { setSubmitting: setLoading }) => {
        setLoading(true);

        const body = { ...values };

        if (formType === 'update') {
            [
                'ALUMNI_BATCH_ADM_CODE',
                'IsEntrepreneur',
                'IsEmployed',
                'IsLookingForAChange',
            ].forEach((prop) => {
                body[prop] = String(body[prop]);
            });
        }
        if (formType === 'add') {
            body.FullName = body.ALUMNI_Name;
            body.Email = body.PERSONAL_EMAILID;
        }
        // if (!body.YearsOfexperience) {
        //     body.YearsOfexperience = null;
        // }
        // if (!body.YearOfGraduation) {
        //     body.YearOfGraduation = null;
        // }

        axios
            .post(endpoint, body)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert('error', extractErrorFromRes(err));
            })
            .finally((res) => setLoading(false));
    };
    const status = {
        1: 'Alumni',
        2: 'Student',
        3: 'Faculty',
        4: 'Institute Member',
    };
    const initialFormValues = updateValues || {
        // ALUMNI_BATCH_ADM_CODE: '',
        FullName: '',
        Alumni_Current_Location: '',
        ALUMNI_ABOUTME: '',
        // BATCH_CODE: '',
        // // COURSE_ID: '',
        DateOfBirth: '',
        Type: '6',
        Role: '6',
        Designation: '',
        Organization_Name: '',
        // Industry: '',
        IsActive: '1',
        // IsEmployed: '',
        // IsEntrepreneur: '',
        // IsLookingForAChange: '',
        // LinkedInProfileLink: '',
        Email: '',
        PHONE_NUMBER: '',
        // PreviousLatestQualification: '',
        // PrivacySettings: '2',
        // YearOfGraduation: null,
        PasswordChangedDate: new Date(),
        ChangePasswordAtFirstLogin: 1,
        Status: '1',
    };
    const preSelectedOption = {};

    if (updateValues) {
        updateValues.DateOfBirth = checkIsoAndReturnYMDFormat(
            updateValues.DateOfBirth
        );

        preSelectedOption.batch = batches.find(
            (batch) =>
                Number(batch.BATCH_CODE) ===
                Number(initialFormValues.BATCH_CODE)
        );
        preSelectedOption.industry = industries.find(
            (industry) =>
                industry?.Industry?.trim()?.toLowerCase() ===
                initialFormValues?.Industry?.trim()?.toLowerCase()
        );
        preSelectedOption.organization = organizations.find(
            (organization) =>
                organization?.Organization_Name?.trim()?.toLowerCase() ===
                initialFormValues?.Organization_Name?.trim()?.toLowerCase()
        );
    }
    if (loading) {
        return <Loader />;
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                ALUMNI_Name: Yup.string().required(),
                // ALUMNI_Name: Yup.string().required(),
                Alumni_Current_Location: Yup.string().required(),
                ALUMNI_ABOUTME: Yup.string().nullable(),
                // BATCH_CODE: Yup.string().when('Type', {
                //     is: '4',
                //     then: () => Yup.number().nullable(),
                //     otherwise: () => Yup.number().required(),
                // }),
                Type: Yup.string().required(),
                DateOfBirth: Yup.date()
                    .max(
                        new Date(
                            new Date().setYear(new Date().getFullYear() - 18)
                        ),
                        'Must be 18 years old'
                    )
                    .required(),
                // Designation: Yup.string(),
                // IsEmployed: Yup.string().required(),
                // Organization_Name: Yup.string().when('IsEmployed', {
                //     is: '1',
                //     then: () => Yup.string().required('Required'),
                //     otherwise: () => Yup.string().nullable(),
                // }),
                // Industry: Yup.string().when('IsEmployed', {
                //     is: '1',
                //     then: () => Yup.string().required('Required'),
                //     otherwise: () => Yup.string().nullable(),
                // }),
                // IsEntrepreneur: Yup.string().required(),
                // IsLookingForAChange: Yup.string().required(),
                // LinkedInProfileLink: Yup.string().when('IsLookingForAChange', {
                //     is: '1',
                //     then: () =>
                //         Yup.string()
                //             .url()
                //             .required(
                //                 'Required because Alumni is looking for change'
                //             ),
                //     otherwise: () => Yup.string().url().nullable(),
                // }),
                // Email: Yup.string()
                //     .matches(
                //         /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                //         'Please enter a valid email address.'
                //     )
                //     .email()
                //     .required(),
                PERSONAL_EMAILID: Yup.string()
                    .matches(
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                        'Please enter a valid email address'
                    )
                    .email()
                    .required(),
                PHONE_NUMBER: Yup.string()
                    .required('Contact Number is required')
                    .nullable()
                    .test(
                        'PHONE_NUMBER',
                        'Enter a valid contact number',
                        (value) => {
                            return isValidPhoneNumber(value ?? '');
                        }
                    ),

                // PreviousLatestQualification: Yup.string().nullable(),
                // PrivacySettings: Yup.string().required(),
                // YearOfGraduation: Yup.number().when('Type', {
                //     is: '1',
                //     then: () =>
                //         Yup.number()
                //             .min(1900, 'Invalid value')
                //             .max(new Date().getFullYear() + 2, 'Invalid value')
                //             .required(),
                //     otherwise: () =>
                //         Yup.number()
                //             .min(1900, 'Invalid value')
                //             .max(new Date().getFullYear() + 2, 'Invalid value')
                //             .nullable(),
                // }),
                // YearsOfexperience: Yup.number().when('Type', {
                //     is: '1',
                //     then: () => Yup.number().min(0, 'Invalid value').required(),
                //     otherwise: () =>
                //         Yup.number().min(0, 'Invalid value').nullable(),
                // }),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => (
                <Form>
                    <Row>
                        <Col md='4'>
                            <FormikControl
                                control='input'
                                required
                                // name={
                                //     formType !== 'add'
                                //         ?'ALUMNI_Name'
                                //         : 'FullName'
                                // }
                                name='ALUMNI_Name'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='select'
                                required
                                name='Type'
                                disabled
                                title="User type can't be updated"
                            >
                                <option value=''>Select type</option>
                                <option value='1'>Alumni</option>
                                <option value='2'>Student</option>
                                <option value='3'>Faculty</option>
                                <option value='6'>Institute Member</option>
                            </FormikControl>
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='input'
                                type='date'
                                required
                                name='DateOfBirth'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='input'
                                required
                                name='Alumni_Current_Location'
                            />
                        </Col>
                        {/*{Number(values.Type) !== 4 && (
                            <Col md='4'>
                                <FormikControl
                                    control='select-advanced'
                                    required={Number(values.Type) !== 4}
                                    options={batches}
                                    defaultValue={preSelectedOption.batch}
                                    optionValue='BATCH_CODE'
                                    optionLabel='BATCH_NAME'
                                    getOptionLabel={(label) =>
                                        label.BATCH_NAME +
                                        ' ' +
                                        label.COURSE_NAME
                                    }
                                    label='BATCH'
                                    name='BATCH_CODE'
                                />
                            </Col>
                        )}
                        <Col md='4'>
                            <FormikControl control='input' name='Designation' />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='check'
                                type='radio'
                                required
                                options={[
                                    { key: 'Yes', value: '1' },
                                    { key: 'No', value: '0' },
                                ]}
                                name='IsEmployed'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='creatable-select'
                                required={Number(values.IsEmployed) === 1}
                                options={industries}
                                defaultValue={preSelectedOption.industry}
                                optionValue='Industry'
                                optionLabel='Industry'
                                name='Industry'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='creatable-select'
                                required={Number(values.IsEmployed) === 1}
                                options={organizations}
                                defaultValue={preSelectedOption.organization}
                                optionValue='Organization_Name'
                                optionLabel='Organization_Name'
                                name='Organization_Name'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='check'
                                type='radio'
                                required
                                options={[
                                    { key: 'Yes', value: '1' },
                                    { key: 'No', value: '0' },
                                ]}
                                name='IsEntrepreneur'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='check'
                                type='radio'
                                required
                                options={[
                                    { key: 'Yes', value: '1' },
                                    { key: 'No', value: '0' },
                                ]}
                                name='IsLookingForAChange'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='input'
                                label='Linkedin'
                                name='LinkedInProfileLink'
                                required={
                                    Number(values.IsLookingForAChange) === 1
                                }
                            />
                        </Col> */}
                        <Col md='4'>
                            <FormikControl
                                control='input'
                                type='email'
                                required
                                name='PERSONAL_EMAILID'
                            />
                        </Col>
                        <Col md='4'>
                            <FormikControl
                                control='phone-number'
                                type='number'
                                required
                                name='PHONE_NUMBER'
                            />
                        </Col>
                        {/* <Col md='4'>
                            <FormikControl
                                control='input'
                                name='PreviousLatestQualification'
                            />
                        </Col>
                        {Number(values.Type) !== 4 && (
                            <Col md='4'>
                                <FormikControl
                                    control='input'
                                    type='number'
                                    required={Number(values.Type) === 1}
                                    name='YearOfGraduation'
                                />
                            </Col>
                        )}
                        <Col md='4'>
                            <FormikControl
                                control='input'
                                type='number'
                                required={Number(values.Type) === 1}
                                name='YearsOfexperience'
                            />
                        </Col> */}
                        <Col md='4'>
                            <FormikControl
                                control='check'
                                type='radio'
                                required
                                options={[
                                    { key: 'Active', value: '1' },
                                    { key: 'Inactive', value: '2' },
                                ]}
                                disabled={formType !== 'add' ? false : true}
                                name='IsActive'
                                title={
                                    formType !== 'add'
                                        ? ''
                                        : 'The status will be set to "Active" when adding an Institute Member'
                                }
                            />
                        </Col>
                        <Col md='8'>
                            <FormikControl
                                control='textarea'
                                name='ALUMNI_ABOUTME'
                            />
                        </Col>
                        {/*<Col md='4'>
                            <FormikControl
                                control='check'
                                type='radio'
                                required
                                options={[
                                    { key: 'Full Access', value: '1' },
                                    { key: 'Limited Access', value: '3' },
                                    { key: 'No Access', value: '2' },
                                ]}
                                name='PrivacySettings'
                            />
                        </Col> */}
                    </Row>
                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(InstituteMemberForm);
