import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './asset/css/style.min.css';
import GenerateRoutes from './routing/GenerateRoutes';
import { LabelProvider } from './context/LabelProvider';
import { AuthenticationProvider } from './context/Auth.context';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Router>
            <AuthenticationProvider>
                <LabelProvider>
                    <GenerateRoutes />
                </LabelProvider>
            </AuthenticationProvider>
        </Router>
    </React.StrictMode>
);
