import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import BatchForm from './BatchForm';

function Batches() {
    const fetchUtils = useAxiosGet(endpoints.batch.get);

    const cellModifier = {
        BATCH_START_DATE: ({ value }) => new Date(value).toDateString(),
        BATCH_END_DATE: ({ value }) => new Date(value).toDateString(),
    };

    return (
        <>
            <CRUDTable
                endpoints={endpoints.batch}
                {...viewProps.Batch}
                cellModifier={cellModifier}
                Form={BatchForm}
                // irremovable
                {...fetchUtils}
            />
        </>
    );
}

export default Batches;
