import React, { useMemo, useRef } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import TableTemplate from './Table';
import Loader from '../Loader';
import withAlert from '../withAlert';
import './pagination.css';

const TableFormat = ({
    endpoints = {},
    loading = false,
    columnHeads = [],
    name = 'Table',
    relevants = [],
    irremovable = false,
    immutable = false,
    cellModifier = {},
    updateFormProps = {},
    formSize = 'sm',
    response = [],
    reFetch = () => {},
}) => {
    const columnData = useRef(
        relevants.map((element, i) => {
            const temp = {
                Header: columnHeads[i] ?? 'Missing Column Head',
                accessor: element,
            };

            if (cellModifier[element]) {
                temp.Cell = (value) => cellModifier[element](value) ?? '';
            }

            return temp;
        })
    );
    const columns = useMemo(() => columnData.current, []);

    const data = useMemo(() => response, [response]);

    const tableInstance = useTable(
        {
            columns,
            data,
            autoResetSortBy: false,
            autoResetPage: false,
        },
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        prepareRow,
        state: { pageIndex },
    } = tableInstance;

    let errorMessage = '';

    if (!loading && response.length === 0) {
        errorMessage = 'Table is Empty';
    }

    return (
        <div className='crudCard'>
            <div className='p-0'>
                {errorMessage ? (
                    <div className='text-center h1 py-4 text-muted'>
                        {errorMessage}
                    </div>
                ) : (
                    <>
                        <TableTemplate
                            name={name}
                            irremovable={irremovable}
                            immutable={immutable}
                            updateFormProps={updateFormProps}
                            getTableProps={getTableProps}
                            getTableBodyProps={getTableBodyProps}
                            headerGroups={headerGroups}
                            page={page}
                            prepareRow={prepareRow}
                            endpoints={endpoints}
                            reFetch={reFetch}
                        />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {pageOptions.length > 0 && (
                                <div className='paginationDiv'>
                                    <button
                                        disabled={!canPreviousPage}
                                        onClick={previousPage}
                                        style={{
                                            color: '#0096FF',
                                            margin: '0.5px',
                                            padding: '5px 10px',
                                            border: '1px solid #c6e7fa',
                                            background: ' #f8f8f8',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        &lt;
                                    </button>
                                    <div className='pagination'>
                                        {pageOptions.map((pageNum) => (
                                            <button
                                                key={pageNum}
                                                className={`page-number ${
                                                    pageIndex === pageNum
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    gotoPage(pageNum)
                                                }
                                            >
                                                {pageNum + 1}
                                            </button>
                                        ))}
                                    </div>
                                    <button
                                        disabled={!canNextPage}
                                        onClick={nextPage}
                                        style={{
                                            color: '#0096FF',
                                            margin: '0.5px',
                                            padding: '5px 10px',
                                            border: '1px solid #c6e7fa',
                                            background: ' #f8f8f8',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        &gt;
                                    </button>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
            {loading && <Loader />}
        </div>
    );
};

export default withAlert(TableFormat);
