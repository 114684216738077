import React from "react";
import CRUDTable from "../../components/table/CRUDTable";
import { useAxiosGet } from "../../hooks/axiosHooks";
import endpoints from "../../endpoints";
import viewProps from "../viewProps";
import ModalWrapper from "../../components/ModalWrapper";
import PostWithWarning from "../../components/PostWithWarning";
import { ReactComponent as Approve } from "../../asset/icons/Approve.svg";
import { ReactComponent as Reject } from "../../asset/icons/Block.svg";
import { useAuthenticationState } from "../../context/Auth.context";
import StatusIndicator from "../../components/StatusIndicator";
import withAlert from "../../components/withAlert";

function PostedJobs({ showAlert }) {
    const { user } = useAuthenticationState();

    let getApi = endpoints.postedJobs.get;

    if (["DELEGATE", "DELEGATE + CCH"].includes(user.Role)) {
        getApi = endpoints.postedJobs.delegate + user.DELEGATE_BATCH_CODE;
    } else if (['STUDENTCOUNCIL', 'CLASSREPRESENTATIVE', 'CLASSREP + STUDENTCOUNCIL'].includes(user.Role)) {
        getApi = endpoints.postedJobs.studentJobList;
    } else {
        getApi = endpoints.postedJobs.get;
    }

    const fetchUtils = useAxiosGet(getApi);

    const cellModifier = {
        Alumni_JobListing_STATUS: ({ value, row }) => {
            const status = {
                1: { tag: 'Active', variant: 'bg-info' },
                0: { tag: `Removed by ${row.original?.ALUMNI_Name}`, variant: 'bg-secondary' },
                2:{ tag: 'Inactive', variant: 'bg-danger' },
            };
            return (
                <StatusIndicator
                    variant={status[value]?.variant || 'bg-warning'}
                    status={status[value]?.tag || 'Not Responded'}
                />
            );
        },
        Approve: ({ row }) => (
            <ModalWrapper
                modalTitle={"Approve Job ?"}
                modalAttrs={{ size: "sm" }}
                disabled={row.original.Alumni_Joblisting_Approve_Status !== 3 || row.original?.Alumni_JobListing_STATUS === 0}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                "success",
                                "The job request has been Approved"
                            );
                        }}
                        onError={(err) => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                'error',
                                `${err.response.data.statusText} You can't approve this job.` || 'The job has been removed'
                            );
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.postedJobs.respond}
                        configBody={{
                            Alumni_Joblisting_Approve_Status: "1",
                            Alumni_JobListing_ID: String(
                                row.original.Alumni_JobListing_ID
                            ),
                            Alumni_JobListing_NAME:
                                row.original.Alumni_JobListing_NAME,
                            CREATEDBY: row.original.CREATEDBY,
                            NOTIFICATION_CONTENT: "Posted Job Approved",
                        }}
                        successVarient='success'
                        dangerVarient='danger'
                    />
                )}
            >
                <span type='button' className='ps-3'>
                    <Approve />
                </span>
            </ModalWrapper>
        ),
        Reject: ({ row }) => (
            <ModalWrapper
                modalTitle={"Block Job ?"}
                modalAttrs={{ size: "sm" }}
                disabled={row.original.Alumni_Joblisting_Approve_Status !== 3 || row.original?.Alumni_JobListing_STATUS === 0}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                "success",
                                "The job request has been Rejected"
                            );
                        }}
                        onError={(err) => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                'error',
                                `${err.response.data.statusText} You can't reject this job.` || 'The job has been removed'
                            );
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.postedJobs.respond}
                        configBody={{
                            Alumni_Joblisting_Approve_Status: "2",
                            Alumni_JobListing_ID:
                                row.original.Alumni_JobListing_ID,
                            Alumni_JobListing_NAME:
                                row.original.Alumni_JobListing_NAME,
                            CREATEDBY: row.original.CREATEDBY,
                            NOTIFICATION_CONTENT: "Posted Job Rejected",
                        }}
                    // successVarient='success'
                    // dangerVarient='danger'
                    />
                )}
            >
                <span type='button' className='ps-3'>
                    <Reject />
                </span>
            </ModalWrapper>
        ),
        // Alumni_JobListing_STATUS: ({ value }) => {
        //     if (Number(value) === 0) {
        //         return "Inactive";
        //     }
        //     if (Number(value) === 1) {
        //         return "Active";
        //     }
        //     return value;
        // },
        Alumni_JobListing_ExpiryDATE: ({ value }) => {
            return new Date(value).toLocaleDateString();
        },
        Alumni_JobListing_TYPE: ({ value }) => {
            const types = {
                1: "Intern",
                2: "Part-Time",
                3: "Full Time",
            };
            return types[value] ?? value;
        },
        Alumni_Joblisting_Approve_Status: ({ value }) => {
            const status = {
                1: { tag: "Approved", variant: "bg-success" },
                2: { tag: "Rejected", variant: "bg-danger" },
                3: { tag: "Not Responded", variant: "bg-warning" },
            };
            return (
                <StatusIndicator
                    variant={status[value]?.variant || "bg-warning"}
                    status={status[value]?.tag || "Not Responded"}
                />
            );
        },
    };
    return (
        <>
            <CRUDTable
                {...viewProps.PostedJobs}
                cellModifier={cellModifier}
                endpoints={endpoints.postedJobs}
                // irremovable
                insertable={false}
                immutable
                {...fetchUtils}
            />
        </>
    );
}

export default withAlert(PostedJobs);
