import { useEffect } from 'react';
import { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

function TermsAndCondtions() {
    const mainContent = useRef(null);
    const location = useLocation();

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    return (
        <>
            <div className='p-5'>
                <div ref={mainContent}>
                    <h1 style={{ fontSize: '2em' }}>
                        Terms and Conditions for Koble
                    </h1>
                    <p>Last updated: January 05, 2024</p>
                    <h3 style={{ marginTop: '25px' }}>
                        1. Acceptance of Terms:
                    </h3>
                    <p>
                        Welcome to Koble, an Alumni Community Networking
                        Application created by Gathergrid Tech Pvt Ltd ("we,"
                        "us," or "our"). These Terms and Conditions ("Terms")
                        govern your use of Koble on the mobile app and web app.
                        By accessing or using Koble, you agree to be bound by
                        these Terms. If you do not agree to these Terms, please
                        do not use Koble.
                    </p>
                    <h3 style={{ marginTop: '25px' }}>2. Use of Koble:</h3>
                    <ul style={{ marginTop: '18px' }}>
                        <li>
                            <h4>Eligibility:</h4>
                            <p>
                                You must be at least 18 years old to use Koble.
                                By using Koble, you affirm that you are at least
                                18 years old.
                            </p>
                        </li>
                        <li>
                            <h4>Registration:</h4>
                            <p>
                                To access certain features of Koble, you may
                                need to register and create an account. You are
                                responsible for maintaining the confidentiality
                                of your account credentials.
                            </p>
                        </li>
                        <li>
                            <h4>User Conduct:</h4>
                            <p>
                                You agree not to use Koble for any unlawful or
                                prohibited purpose. You must comply with all
                                applicable laws and regulations.
                            </p>
                        </li>
                    </ul>
                    <h3 style={{ marginTop: '25px' }}>3. User Content:</h3>
                    <ul style={{ marginTop: '18px' }}>
                        <li>
                            <h4>Ownership:</h4>
                            <p>
                                By submitting content to Koble, you grant us a
                                non-exclusive, worldwide, royalty-free license
                                to use, reproduce, modify, and distribute your
                                content.
                            </p>
                        </li>
                    </ul>
                    <h3 style={{ marginTop: '25px' }}>
                        4. Intellectual Property:
                    </h3>
                    <ul style={{ marginTop: '18px' }}>
                        <li>
                            <h4>Ownership:</h4>
                            <p>
                                All content, features, and functionality on
                                Koble are owned by Gathergrid Tech Pvt Ltd and
                                are protected by intellectual property laws.
                            </p>
                        </li>
                    </ul>
                    <h3 style={{ marginTop: '25px' }}>5. Termination:</h3>
                    <ul style={{ marginTop: '18px' }}>
                        <li>
                            <h4>Termination or Suspension:</h4>
                            <p>
                                We reserve the right to terminate or suspend
                                your account and access to Koble at our sole
                                discretion, without prior notice, for any
                                reason, including a breach of these Terms.
                            </p>
                        </li>
                    </ul>
                    <h3 style={{ marginTop: '25px' }}>
                        6. Limitation of Liability:
                    </h3>
                    <ul style={{ marginTop: '18px' }}>
                        <li>
                            <h4>Indirect Damages:</h4>
                            <p>
                                In no event shall Gathergrid Tech Pvt Ltd, its
                                officers, directors, employees, or agents be
                                liable for any indirect, incidental, special,
                                consequential, or punitive damages, including
                                without limitation, loss of profits, data, use,
                                goodwill, or other intangible losses.
                            </p>
                        </li>
                    </ul>
                    <h3 style={{ marginTop: '25px' }}>7. Privacy Policy:</h3>
                    <ul style={{ marginTop: '18px' }}>
                        <li>
                            Your use of Koble is also governed by our Privacy
                            Policy, which can be found{' '}
                            <Link to='/privacy-policy'>here</Link>
                        </li>
                    </ul>
                    <h3 style={{ marginTop: '25px' }}>8. Changes to Terms:</h3>
                    <ul style={{ marginTop: '18px' }}>
                        <li>
                            <h4>Updates:</h4>
                            <p>
                                We may update these Terms from time to time. Any
                                changes will be posted on this page. It is your
                                responsibility to review these Terms
                                periodically.
                            </p>
                        </li>
                    </ul>
                    <h3 style={{ marginTop: '25px' }}>9. Contact Us:</h3>
                    <ul style={{ marginTop: '18px' }}>
                        <li>
                            <h4>Questions or Concerns:</h4>
                            <p>
                                If you have any questions or concerns about
                                these Terms, please contact us at {''}
                                <a href='mailto:support@kobleapp.uk'>
                                    support@kobleapp.uk
                                </a>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default TermsAndCondtions;
