import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Avatar from '../../asset/img/Avatar.png';

function ECMembers({ memberList }) {
    return (
        <div>
            <div className='small h6 text-dark'>Governing Council Members</div>
            <div
                className='mt-3 p-4 small card-shadow'
                style={{
                    height: '340px',
                    overflow: 'auto',
                    background: 'white',
                    borderRadius: '20px',
                }}
            >
                {memberList.length === 0 && (
                    <div className='text-center fs-6 text-muted'>
                        Council not constituted
                    </div>
                )}
                {memberList.map((member, i) => (
                    <div className='pb-3 d-flex align-items-center' key={i}>
                        <span>
                            <img
                                src={member.ALUMNI_IMAGE_URL || Avatar}
                                width={30}
                                className='rounded-circle shadow-sm'
                            />
                        </span>
                        <div className='d-flex flex-column'>
                            <span className='ps-3'>
                                {member.EC_Member_Name}
                            </span>
                            <span className='ps-3 small'>
                                {member.EC_Member_Designation}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ECMembers;
